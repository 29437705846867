import _ from 'lodash'
import { Badge } from 'react-bootstrap'
import { CloseIcon, EditIcon } from '~/components/shared'

import clsx from 'clsx'

import './styles.scss'
import { useConfirmationProvider } from '~/contexts'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { useState } from 'react'

export interface ICompanyItemCardProps {
  content: string | null
  types?: any[]
  onClick?: () => void
  selected?: boolean
  canDelete?: boolean
  showConfirmationWhenDelete?: boolean
  onClickDelete?: () => void
  index?: number
  canEdit?: boolean
  object?: any
  FormComponent?: any
  formProps?: Record<string, any>
}

function CompanyItemCard(props: ICompanyItemCardProps) {
  const {
    content,
    types,
    selected,
    canDelete,
    showConfirmationWhenDelete,
    index,
    canEdit,
    object,
    formProps,
    FormComponent,
    onClick,
    onClickDelete,
  } = props

  const [showForm, setShowForm] = useState(false)

  const { confirmation } = useConfirmationProvider()

  const onCloseForm = () => {
    setShowForm(false)
  }

  return (
    <div
      onClick={onClick}
      className={clsx('CompanyItemCard__container', { selected })}
    >
      <div>
        <div
          style={{
            display: 'inline-block',
            marginRight: 4,
            verticalAlign: 'middle',
          }}
        >
          {content}
        </div>
        {(types || []).map(type => (
          <Badge key={type} style={{ marginRight: 4 }}>
            {_.startCase(type.toString())}
          </Badge>
        ))}

        {canEdit && (
          <span
            style={{ marginLeft: 4 }}
            className='clickable'
            onClick={event => {
              event.stopPropagation()
              setShowForm(true)
            }}
          >
            <EditIcon color='var(--bs-warning)' />
          </span>
        )}

        <Badge bg='secondary' className='CompanyItemCard__indexBadge'>
          {index}
        </Badge>

        {canDelete && (
          <span
            className='CompanyItemCard__closeIcon clickable'
            onClick={async () => {
              if (showConfirmationWhenDelete) {
                const result = await confirmation({
                  message: 'Are you sure you want to delete this item?',
                })
                if (result === EYesNo.Yes) {
                  onClickDelete && onClickDelete()
                }
              } else {
                onClickDelete && onClickDelete()
              }
            }}
          >
            <CloseIcon color='var(--bs-danger)' />
          </span>
        )}
      </div>

      {FormComponent && showForm ? (
        <div
          style={{
            borderTop: '1px dashed #e5e5e5',
            marginTop: 8,
          }}
        >
          <FormComponent
            formData={object}
            cancelText='Close'
            onCancel={onCloseForm}
            {...formProps}
            afterUpdate={(item: any) => {
              formProps?.afterUpdate && formProps?.afterUpdate(item)
              onCloseForm()
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default CompanyItemCard
