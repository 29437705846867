import { CommonDialogV2 } from '~/components/shared'
import DocumentForm from './DocumentForm'

import type { IDialogDocumentFormProps } from './type'

function DialogDocumentForm(props: IDialogDocumentFormProps) {
  const { formData, afterCreate, afterUpdate, hiddenFields, ...dialogProps } =
    props
  return (
    <CommonDialogV2
      {...dialogProps}
      title='Document'
      isHiddenOkButton
      size='lg'
    >
      <DocumentForm
        afterCreate={afterCreate}
        formData={formData}
        hiddenFields={hiddenFields}
      />
    </CommonDialogV2>
  )
}
export default DialogDocumentForm
