import useOrderCardOnMap from './useOrderCardOnMap'

import { useState } from 'react'
import { IonText } from '@ionic/react'
import { Badge, Button, Collapse, Modal } from 'react-bootstrap'
import OrderTrackingCard from '~/containers/OrderTracking/OrderTrackingCard'
import type { IOrderCardOnMapProps } from './type'
import {
  CloseIcon,
  TicketModal,
  ToolTipOverlay,
  ViewMapIcon,
} from '~/components/shared'
import buildObjectName from '~/utils/buildObjectName'

import moment from 'moment'

import './styles.scss'

const OrderCardOnMap: React.FC<IOrderCardOnMapProps> = props => {
  const [show, setShow] = useState(true)
  const [showLoad, setShowLoad] = useState(false)

  const { load, truck, truckLocationUpdatedAt } = props

  const {
    percentage,
    loadBuyerTerminal,
    loadBuyer,
    schedule,
    isShowingOrderCard,
    bsp,
    uom,
    onShowLoadCards,
    setIsShowingOrderCard,
  } = useOrderCardOnMap(props)

  const lastGpsUpdate = truckLocationUpdatedAt
    ? `Last GPS Update: ${moment(truckLocationUpdatedAt).format('MMM-D H:mm')}`
    : ''

  const qtyToolTip = () => {
    const qty = schedule?.qty ? parseFloat(schedule?.qty).toString() : ''

    const qtyCounter = schedule?.qtyCounter
      ? parseFloat(schedule?.qtyCounter).toString()
      : ''
    return `${parseFloat(qtyCounter).toString()} / ${qty} ${uom?.name || ''}`
  }

  return (
    <>
      <div className='OrderCardOnMap__container'>
        <Collapse in={show}>
          <div className='body'>
            <CloseIcon
              size={20}
              color='#6f767e'
              onClick={() => setShow(false)}
              className='position-absolute top-0 start-0 m-2 pointer'
            />

            <div
              className='progress-bar'
              style={{
                background: `
              radial-gradient(closest-side, white 79%, transparent 80% 100%),
              conic-gradient(var(--ion-color-concord) ${percentage}%, var(--ion-color-fleet) 0)`,
              }}
              role='progressbar'
            >
              <div className='truckName'>
                <IonText>{truck.name}</IonText>
              </div>
              <div className='percentage'>{percentage}%</div>
            </div>
            <div className='loadInfo'>
              <div className='loadBuyerTerminalName'>
                <span style={{ marginRight: 10 }}>
                  <ViewMapIcon />
                </span>
                <span>{loadBuyerTerminal?.name}</span>
              </div>
              <div className='loadBuyerName'>
                <IonText>Buyer: </IonText>
                <IonText color='buyer' style={{ fontWeight: 600 }}>
                  {loadBuyer?.name}
                </IonText>
              </div>
              {bsp && (
                <div className='loadBuyerName'>
                  <IonText>Product: </IonText>
                  <IonText color='fleet' style={{ fontWeight: 600 }}>
                    {buildObjectName({
                      name: bsp.sellerProductName,
                      code: bsp.sellerProductCode,
                    })}
                  </IonText>

                  <ToolTipOverlay content={qtyToolTip()}>
                    <Badge pill className='ms-1'>
                      {parseFloat(load.qty).toString()} {uom?.code}
                    </Badge>
                  </ToolTipOverlay>
                </div>
              )}
              <ToolTipOverlay content={lastGpsUpdate}>
                <div className=''>
                  Last status update:{' '}
                  {moment(load.statusTime).format('MMM-D H:mm')}
                </div>
              </ToolTipOverlay>
            </div>
          </div>
        </Collapse>
        <div className='footer'>
          <Button size='lg' onClick={() => setShow(!show)}>
            {load.status}
          </Button>
          <span className='order clickable mx-2' onClick={onShowLoadCards}>
            Order #{schedule?.scheduleNumber} - {schedule?.order?.num}
          </span>
          <Button size='lg' variant='dark' onClick={() => setShowLoad(true)}>
            Load #{load.num}
          </Button>
        </div>
      </div>
      <Modal
        centered
        show={isShowingOrderCard}
        onHide={() => {
          setIsShowingOrderCard(false)
        }}
      >
        <Modal.Body>
          <OrderTrackingCard data={schedule} />
        </Modal.Body>
      </Modal>

      <TicketModal
        isOpen={showLoad}
        onClose={() => setShowLoad(false)}
        load={load}
        // canEdit
        // afterUpdateload={load => {
        //   updateLoad(load.id, load)
        //   refetchSchedulesData()
        // }}
      />
    </>
  )
}

export default OrderCardOnMap
