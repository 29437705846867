import {
  useQueryBreadcrumbLoads,
  useQueryLoad,
  useQueryTerminals,
  useQueryTruckFleets,
} from '~/hooks/useQueryData'
import { useCallback, useMemo, useState } from 'react'

import { When } from 'react-if'
import {
  TerminalMarker1,
  MapDirection,
  BreadcrumbDot,
} from '~/components/shared'

import moment from 'moment'
import { arrowDownCircleOutline, arrowUpCircleOutline } from 'ionicons/icons'

import { IUseDeliveryRoutesProps } from './type'
import { IGetDirectionOnMapResponse } from '~/utils/getDirectionOnMap'
import { ITerminal } from '~/types/models/ITerminal'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'

const useDeliveryRoutes = (props: IUseDeliveryRoutesProps) => {
  const { loadId, isShow, truckLocation, truckFleetId, load } = props

  const [routeInfo, setRouteInfo] =
    useState<IGetDirectionOnMapResponse | null>(null)
  const [dropoffInfo, setDropoffInfo] =
    useState<IGetDirectionOnMapResponse | null>(null)

  const {
    loadData: loadDataFromBackend,
    loadBuyerTerminal,
    loadSellerTerminal,
    loadReturnTerminal,
  } = useQueryLoad(loadId as number, {
    enabled: Boolean(isShow && loadId && !load),
  })

  const { findTerminalById } = useQueryTerminals(
    {
      filters: {
        companyId: [load?.sellerId, load?.buyerId].filter(Boolean) as any,
      },
    },
    { enabled: Boolean(load) },
  )

  const breadcumbLoadsFilters = useMemo(() => {
    const filters: any = {}
    if (loadId) {
      filters.loadId = loadId
    } else {
      filters.truckFleetId = truckFleetId
    }
    return filters
  }, [loadId, truckFleetId])

  const canFetchBreadcrumbLoadsWithLoadId = Boolean(
    breadcumbLoadsFilters.loadId || breadcumbLoadsFilters.truckFleetId,
  )

  const { findTruckFleetById } = useQueryTruckFleets(
    {},
    { enabled: Boolean(truckFleetId) },
  )

  const truckFleet = findTruckFleetById(truckFleetId)

  const { breadcrumbLoadsData: breadcrumbLoadsDataLoad } =
    useQueryBreadcrumbLoads(
      {
        filters: breadcumbLoadsFilters,
      },
      {
        enabled: canFetchBreadcrumbLoadsWithLoadId,
        refetchInterval:
          canFetchBreadcrumbLoadsWithLoadId && truckFleet?.truck?.gpsInterval
            ? truckFleet?.truck?.gpsInterval * 1000
            : undefined,
      },
    )

  const { breadcrumbLoadsData: breadcrumbLoadsDataOfTruck } =
    useQueryBreadcrumbLoads(
      {
        id: truckFleet?.truck?.vehicleUid,
      },
      {
        enabled: Boolean(
          truckFleet?.truck?.vehicleUid && !canFetchBreadcrumbLoadsWithLoadId,
        ),
        refetchInterval:
          !canFetchBreadcrumbLoadsWithLoadId && truckFleet?.truck?.gpsInterval
            ? truckFleet?.truck?.gpsInterval * 1000
            : undefined,
      },
    )

  const breadcrumbLoadsData = canFetchBreadcrumbLoadsWithLoadId
    ? breadcrumbLoadsDataLoad
    : breadcrumbLoadsDataOfTruck

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const breadcrumbLoadsData: IBreadcrumbLoad[] = [
  //   {
  //     createdAt: '1',
  //     id: 1,
  //     loadId: 94602,
  //     eventType: 'At Plant',
  //     loadTimeId: 10514,
  //     driverFleetId: 15,
  //     truckFleetId: 26,
  //     eta: null,
  //     airtableSource: null,
  //     lat: '10.84504213508256',
  //     lng: '106.15071376772256',
  //   },
  //   {
  //     createdAt: '1',
  //     id: 2,
  //     loadId: 94602,
  //     eventType: 'At Plant',
  //     loadTimeId: 10514,
  //     driverFleetId: 15,
  //     truckFleetId: 26,
  //     eta: null,
  //     airtableSource: null,
  //     lat: '10.801878592153164',
  //     lng: '106.32924160633546',
  //   },
  //   {
  //     createdAt: '1',
  //     id: 3,
  //     loadId: 94602,
  //     eventType: 'At Plant',
  //     loadTimeId: 10514,
  //     driverFleetId: 15,
  //     truckFleetId: 26,
  //     eta: null,
  //     airtableSource: null,
  //     lat: '10.99876147386854',
  //     lng: '106.48030362362327',
  //   },
  //   {
  //     createdAt: '1',
  //     id: 4,
  //     loadId: 94602,
  //     eventType: 'At Plant',
  //     loadTimeId: 10514,
  //     driverFleetId: 15,
  //     truckFleetId: 26,
  //     eta: null,
  //     airtableSource: null,
  //     lat: '11.079634277603622',
  //     lng: '106.45558438443072',
  //   },
  //   {
  //     createdAt: '1',
  //     id: 5,
  //     loadId: 94602,
  //     eventType: 'At Plant',
  //     loadTimeId: 10514,
  //     driverFleetId: 15,
  //     truckFleetId: 26,
  //     eta: null,
  //     airtableSource: null,
  //     lat: '11.165873966041218',
  //     lng: '106.49128995215328',
  //   },
  // ]

  const { isStatusesInSameSection, getSectionByStatus } = useQueryLoadStatuses()

  const loadData = load || loadDataFromBackend

  const buyerTerminal = load
    ? findTerminalById(load.buyerTerminalId)
    : loadBuyerTerminal

  const sellerTerminal = load
    ? findTerminalById(load.sellerTerminalId)
    : loadSellerTerminal

  const returnTerminal = load
    ? findTerminalById(load.returnTerminalId)
    : loadReturnTerminal

  const buyerTerminalLocation = useMemo(() => {
    if (buyerTerminal?.location) {
      return new window.google.maps.LatLng(
        Number(buyerTerminal?.location.lat),
        Number(buyerTerminal?.location.lng),
      )
    }
    return null
  }, [buyerTerminal?.location])

  const sellerTerminalLocation = useMemo(() => {
    if (sellerTerminal?.location) {
      return new window.google.maps.LatLng(
        Number(sellerTerminal.location.lat),
        Number(sellerTerminal.location.lng),
      )
    }
    return null
  }, [sellerTerminal])

  const isShowDiffReturnTerminal = Boolean(
    returnTerminal &&
      returnTerminal?.id !== sellerTerminal?.id &&
      returnTerminal?.location,
  )

  const returnTerminalLocation = useMemo(() => {
    if (isShowDiffReturnTerminal) {
      return new window.google.maps.LatLng(
        Number((returnTerminal as any)?.location?.lat),
        Number((returnTerminal as any)?.location?.lng),
      )
    }
    if (!returnTerminal?.id || returnTerminal?.id === loadSellerTerminal?.id) {
      return sellerTerminalLocation
    }
    return null
  }, [
    isShowDiffReturnTerminal,
    loadSellerTerminal?.id,
    returnTerminal,
    sellerTerminalLocation,
  ])

  const currentLoadSection = getSectionByStatus(loadData?.status || '')

  const calculateTravelTime = useCallback((travelTime: number) => {
    if (travelTime) {
      const result = Math.ceil(travelTime / 60)
      return result
    }
    return null
  }, [])

  const calculateDistance = useCallback((distance: number) => {
    if (distance) {
      const result = distance / 1609.34
      return Number(result.toFixed(2))
    }
    return null
  }, [])

  const getExtraInfos = useCallback(
    (routeInfo: IGetDirectionOnMapResponse | undefined | null) => {
      if (routeInfo) {
        const travelTime = calculateTravelTime(routeInfo.travelTime)
        const distance = calculateDistance(routeInfo.distance as number)
        const eta = travelTime
          ? moment().add({ minute: travelTime }).format('HH:mm')
          : null

        const list = [
          {
            label: 'Travel time',
            value: `${travelTime} mins`,
            isHidden: !travelTime,
          },
          {
            label: 'ETA',
            value: eta,
            isHidden: !eta,
          },
          {
            label: 'Distance',
            value: `${distance} mi`,
            isHidden: !distance,
          },
        ]

        return list
          .filter(({ isHidden }) => !isHidden)
          .map(({ label, value }) => (
            <div key={label} style={{ marginTop: 4 }}>
              <span>{label}: </span>
              <span>{value}</span>
            </div>
          ))
      }

      return null
    },
    [calculateDistance, calculateTravelTime],
  )

  const dropoffInfoBox = useMemo(() => {
    if (currentLoadSection === 'to_seller') {
      return getExtraInfos(dropoffInfo)
    }
    if (['to_buyer', 'at_seller'].includes(currentLoadSection as string)) {
      return getExtraInfos(routeInfo)
    }
  }, [currentLoadSection, dropoffInfo, getExtraInfos, routeInfo])

  const returnTerminalInfoBox = useMemo(() => {
    // const travelTime = calculateTravelTime(
    //   returnDirection?.travelTime as number,
    // )
    // const distance = calculateDistance(returnDirection?.distance as number)
    // const eta = travelTime
    //   ? moment()
    //       .add({
    //         minute:
    //           travelTime +
    //           (pickupInfobox.travelTime as number) +
    //           (dropoffInfoBox.travelTime as number),
    //       })
    //       .format('HH:mm')
    //   : null
    // return {
    //   travelTime,
    //   distance,
    //   eta,
    // }
  }, [])

  const truckGeofence = useMemo(() => {
    if (truckLocation) {
      return new window.google.maps.LatLng(
        Number(truckLocation.lat),
        Number(truckLocation.lng),
      )
    }
    return null
  }, [truckLocation])

  const findValidLatLng = (arr: any, position: 'first' | 'last') => {
    if (position === 'first') {
      return (
        arr.find((item: any) => item.lat !== null && item.lng !== null) || null
      )
    } else {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].lat !== null && arr[i].lng !== null) {
          return arr[i]
        }
      }
      return null
    }
  }

  const breadcrumbWaypoints = useMemo(() => {
    if (breadcrumbLoadsData.length <= 1) {
      return null
    }
    const firstData = findValidLatLng(breadcrumbLoadsData, 'first')
    const lastData = findValidLatLng(breadcrumbLoadsData, 'last')

    const waypoints = breadcrumbLoadsData
      .filter(
        ({ id, lat, lng }) =>
          ![firstData?.id, lastData?.id].includes(id) && lat && lng,
      )
      .map(({ lat, lng }) => ({
        location: {
          lat: Number(lat || 0),
          lng: Number(lng || 0),
        },
      }))
    const startingPoint = new google.maps.LatLng(
      Number(firstData?.lat || 0),
      Number(firstData?.lng || 0),
    )
    const endingPoint = new google.maps.LatLng(
      Number(lastData?.lat || 0),
      Number(lastData?.lng || 0),
    )

    return {
      from: startingPoint,
      to: endingPoint,
      waypoints,
    }
  }, [breadcrumbLoadsData])

  const renderReturnTerminalMarker = useMemo(() => {
    if (isShowDiffReturnTerminal) {
      return (
        <TerminalMarker1
          terminal={returnTerminal as any}
          color='green'
          icon={arrowDownCircleOutline}
          extraInfo={getExtraInfos(routeInfo)}
          isShowInfoBox
          title='Returning'
        />
      )
    }
    return null
  }, [getExtraInfos, isShowDiffReturnTerminal, returnTerminal, routeInfo])

  const endLocation = useMemo(() => {
    if (!loadData?.status) {
      return null
    }
    switch (currentLoadSection) {
      case 'to_seller':
        return sellerTerminalLocation
      case 'to_buyer':
      case 'at_seller':
        return buyerTerminalLocation
      case 'returning':
      case 'at_buyer':
        return returnTerminalLocation
    }
  }, [
    buyerTerminalLocation,
    currentLoadSection,
    loadData?.status,
    returnTerminalLocation,
    sellerTerminalLocation,
  ])

  const renderTruckRoute = useMemo(() => {
    const loadStatus = loadData?.status
    const start = truckGeofence as google.maps.LatLng

    if (loadStatus) {
      if (start && endLocation) {
        return (
          <MapDirection
            from={start}
            to={endLocation}
            color='black'
            onChange={direction => {
              setRouteInfo(direction)
            }}
          />
        )
      }
    }

    return null
  }, [loadData?.status, truckGeofence, endLocation])

  const renderDeliveryRoutes = useMemo(() => {
    if (isShow) {
      return (
        <>
          <When
            condition={Boolean(
              truckGeofence && sellerTerminalLocation && buyerTerminalLocation,
            )}
          >
            {!isShowDiffReturnTerminal && (
              <TerminalMarker1
                terminal={sellerTerminal as ITerminal}
                icon={arrowUpCircleOutline}
                color={
                  ['returning', 'at_buyer'].includes(
                    currentLoadSection as string,
                  )
                    ? 'green'
                    : 'black'
                }
                extraInfo={
                  ['returning', 'at_buyer', 'to_seller'].includes(
                    currentLoadSection as string,
                  )
                    ? getExtraInfos(routeInfo)
                    : undefined
                }
                isShowInfoBox
                title={
                  ['returning', 'at_buyer'].includes(
                    currentLoadSection as string,
                  )
                    ? 'Returning'
                    : 'Pickup'
                }
              />
            )}

            {renderTruckRoute}

            {breadcrumbLoadsData.map(data => (
              <BreadcrumbDot key={data.id} breadcrumbLoad={data} />
            ))}

            <MapDirection
              from={sellerTerminalLocation as google.maps.LatLng}
              to={buyerTerminalLocation as google.maps.LatLng}
              isHidden={
                !isStatusesInSameSection('Ticketed', loadData?.status || '')
              }
              color='#1090ff'
              onChange={direction => {
                setDropoffInfo(direction)
              }}
            />

            <TerminalMarker1
              terminal={buyerTerminal as ITerminal}
              icon={arrowDownCircleOutline}
              color='blue'
              extraInfo={dropoffInfoBox}
              isShowInfoBox
              title='Dropoff'
            />

            {renderReturnTerminalMarker}
          </When>

          <When condition={Boolean(breadcrumbWaypoints)}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <MapDirection {...(breadcrumbWaypoints as any)} color='#787878' />
          </When>
        </>
      )
    }

    return null
  }, [
    breadcrumbWaypoints,
    buyerTerminalLocation,
    currentLoadSection,
    dropoffInfoBox,
    getExtraInfos,
    isShow,
    isStatusesInSameSection,
    buyerTerminal,
    loadData?.status,
    sellerTerminal,
    renderReturnTerminalMarker,
    renderTruckRoute,
    routeInfo,
    sellerTerminalLocation,
    truckGeofence,
    isShowDiffReturnTerminal,
    breadcrumbLoadsData,
  ])

  const boundingDeliveryRoute = useMemo(() => {
    const bounding: google.maps.LatLng[] = []
    if (truckGeofence) {
      bounding.push(truckGeofence)
    }
    if (sellerTerminalLocation) {
      bounding.push(sellerTerminalLocation)
    }
    if (buyerTerminalLocation) {
      bounding.push(buyerTerminalLocation)
    }
    if (returnTerminalLocation) {
      bounding.push(returnTerminalLocation)
    }

    return bounding
  }, [
    buyerTerminalLocation,
    returnTerminalLocation,
    sellerTerminalLocation,
    truckGeofence,
  ])

  return {
    loadData,
    buyerTerminal,
    sellerTerminal,
    returnTerminal,
    buyerTerminalLocation,
    sellerTerminalLocation,
    returnTerminalLocation,
    truckGeofence,
    returnTerminalInfoBox,
    dropoffInfoBox,
    renderDeliveryRoutes,
    boundingDeliveryRoute,
    breadcrumbLoadsData,
    getExtraInfos,
    endLocation,
  }
}

export default useDeliveryRoutes
