import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  CommonDialogV2,
  CompanyAvatar,
  DropdownWithCustomChildren,
  PlusIcon,
  TruckNumberSection,
  UserPlusIcon,
} from '~/components/shared'
import LoadFormContext from './LoadFormContext'
import LoadForm from '~/components/order/LoadForm'
import { ILoadFormProviderSettingProps, ILoadFormProviderValues } from './type'
import {
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { IonChip } from '@ionic/react'
import { colord } from 'colord'
import buildFullName from '~/utils/buildFullName'
import { useConfirmationProvider } from '../ConfirmationProvider'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'
import { Badge } from 'react-bootstrap'
import { ICommonOption } from '~/types/models/ICommonModel'

function LoadFormProvider(props: PropsWithChildren) {
  const { children } = props

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [header, setHeader] = useState('New Load')
  const [truckFleetId, setTruckFleetId] =
    useState<number | null | undefined>(null)
  const [driverFleetId, setDriverFleetId] =
    useState<number | null | undefined>(null)
  const [showTicketModal, setShowTicketModal] = useState(false)

  const loadFormRef = useRef<any>()

  const { confirmation } = useConfirmationProvider()

  const [settingProps, setSettingProps] =
    useState<ILoadFormProviderSettingProps>({
      driverFleetId: null,
      defaultSchedule: null,
    })

  const {
    findUserByDriverFleetId,
    driverFleetOptions,
    assignTruckFleetToDriverFleet,
    usersData,
  } = useQueryUsers()
  const { findTruckFleetById, truckFleetOptions, currentTruckFleetOptions } =
    useQueryTruckFleets(
      {},
      {
        enabled: isOpenForm,
      },
    )
  const { findTerminalById } = useQueryTerminals(
    {},
    {
      enabled: isOpenForm,
    },
  )

  const truckFleet = findTruckFleetById(truckFleetId)
  const truckFleetTerminal = findTerminalById(truckFleet?.terminalId)

  const driverFleet = useMemo(() => {
    if (driverFleetId) {
      return findUserByDriverFleetId(driverFleetId)?.driverFleet
    }

    return null
  }, [findUserByDriverFleetId, driverFleetId])

  const onAssignTruckToDriver = useCallback(
    async (truckFleetId: number | null) => {
      setTruckFleetId(truckFleetId)
      const result = await confirmation({
        message: (
          <span>
            Do you want to assign this truck to driver{' '}
            <strong>{buildFullName(driverFleet?.person)}</strong>
          </span>
        ),
      })
      if (result === EYesNo.Yes) {
        await apiClient.driverFleets.update(driverFleet?.id as number, {
          currentTruckFleetId: truckFleetId,
        })
        assignTruckFleetToDriverFleet(
          truckFleetId as number,
          driverFleet?.id as number,
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [driverFleet],
  )

  const onAssignDriverToTruckFleet = useCallback(
    async (driverFleetId: number | null, option: ICommonOption) => {
      setDriverFleetId(driverFleetId)
      const result = await confirmation({
        message: (
          <span>
            Do you want to assign driver&nbsp;
            <strong>{option.label}</strong> to truck&nbsp;
            <strong>#{truckFleet?.truck?.name}</strong>
          </span>
        ),
      })
      if (result === EYesNo.Yes) {
        await apiClient.driverFleets.update(driverFleetId as number, {
          currentTruckFleetId: truckFleet?.id,
        })
        assignTruckFleetToDriverFleet(
          truckFleet?.id as number,
          driverFleetId as number,
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [driverFleet, truckFleet],
  )

  const onChangeTruckFleetId = useCallback(
    (truckFleetId: number | null) => {
      setTruckFleetId(truckFleetId)
      const user = usersData.find(
        ({ driverFleet }) => driverFleet?.currentTruckFleetId === truckFleetId,
      )
      setDriverFleetId(user?.driverFleet?.id)
    },
    [usersData],
  )

  const onChangeDriverFleetId = useCallback(
    (driverFleetId: number | null) => {
      setDriverFleetId(driverFleetId)
      const user = findUserByDriverFleetId(driverFleetId)
      setTruckFleetId(user?.driverFleet?.currentTruckFleetId)
    },
    [findUserByDriverFleetId],
  )

  const onOpenLoadFormDialog = useCallback(
    (settingProps: ILoadFormProviderSettingProps) => {
      setSettingProps(settingProps)
      setIsOpenForm(true)
    },
    [],
  )

  const onCloseLoadFormDialog = useCallback(() => {
    setSettingProps({
      driverFleetId: null,
      defaultSchedule: null,
    })
    setIsOpenForm(false)
  }, [])

  const providerValue = useMemo<ILoadFormProviderValues>(
    () => ({
      onOpenLoadFormDialog,
      onCloseLoadFormDialog,
    }),
    [onCloseLoadFormDialog, onOpenLoadFormDialog],
  )

  useEffect(() => {
    setDriverFleetId(settingProps.driverFleetId)
    const user = findUserByDriverFleetId(settingProps.driverFleetId)
    setTruckFleetId(user?.driverFleet?.currentTruckFleetId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingProps.driverFleetId])

  return (
    <>
      <LoadFormContext.Provider value={providerValue}>
        {children}
      </LoadFormContext.Provider>
      <CommonDialogV2
        isOpen={isOpenForm}
        onClose={onCloseLoadFormDialog}
        isHiddenOkButton
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 4 }}>{header}</div>

            {!showTicketModal && (
              <>
                <DropdownWithCustomChildren
                  className='no-hover make-custom-dropdown-inline'
                  options={driverFleetOptions}
                  value={driverFleet?.id}
                  onChange={(event, { value, selectedOption }) => {
                    driverFleet
                      ? onChangeDriverFleetId(value)
                      : onAssignDriverToTruckFleet(value, selectedOption)
                  }}
                >
                  <Badge>
                    {driverFleet ? (
                      buildFullName(driverFleet?.person)
                    ) : (
                      <UserPlusIcon color='white' />
                    )}
                  </Badge>
                </DropdownWithCustomChildren>

                {truckFleet ? (
                  <DropdownWithCustomChildren
                    className='no-hover make-custom-dropdown-inline'
                    options={truckFleetOptions}
                    value={truckFleet?.id}
                    onChange={(event, { value }) => {
                      onChangeTruckFleetId(value)
                    }}
                  >
                    <IonChip
                      style={{
                        background:
                          truckFleet?.color || 'var(--ion-color-fleet)',
                      }}
                    >
                      {truckFleetTerminal && (
                        <div style={{ marginRight: 4 }}>
                          <CompanyAvatar
                            company={{
                              value: truckFleetTerminal.id as number,
                              label: truckFleetTerminal.name || '-',
                            }}
                            color={truckFleetTerminal.color || 'black'}
                            height={18}
                            width={18}
                            tooltipMessage={
                              'Next pickup terminal: ' +
                              truckFleetTerminal?.name
                            }
                          />
                        </div>
                      )}
                      <span
                        style={{
                          color: truckFleet?.color
                            ? colord(truckFleet.color).isDark()
                              ? 'white'
                              : 'black'
                            : '#e5e5e5',
                        }}
                      >
                        <TruckNumberSection truckId={truckFleet?.truckId} />
                      </span>
                    </IonChip>
                  </DropdownWithCustomChildren>
                ) : (
                  <DropdownWithCustomChildren
                    className='no-hover make-custom-dropdown-inline'
                    options={currentTruckFleetOptions}
                    // value={truckFleet?.id}
                    onChange={(event, { value }) => {
                      // loadFormRef.current?.setValue('truckFleetId', value)

                      // setTruckFleetId(value)
                      onAssignTruckToDriver(value)
                    }}
                  >
                    <IonChip
                      style={{
                        background: 'var(--ion-color-fleet)',
                      }}
                    >
                      <PlusIcon color='white' />
                    </IonChip>
                  </DropdownWithCustomChildren>
                )}
              </>
            )}
          </div>
        }
        size='lg'
      >
        <LoadForm
          defaultSchedule={settingProps.defaultSchedule}
          driverFleetId={driverFleetId}
          truckFleetId={truckFleetId}
          setShow={() => {
            onCloseLoadFormDialog()
          }}
          onTogglePrintLoad={setHeader}
          isHiddenHeader
          onChangeTruckFleetId={onChangeTruckFleetId}
          onChangeDriverFleetId={onChangeDriverFleetId}
          canEditLoad={settingProps.canEditLoad}
          afterUpdateLoad={settingProps.afterUpdateLoad}
          setShowTicketModal={setShowTicketModal}
          ref={loadFormRef}
        />
      </CommonDialogV2>
    </>
  )
}

export default LoadFormProvider
