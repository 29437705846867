import { useCallback, useMemo, useState } from 'react'
import {
  useQueryBuyerSellerProducts,
  useQueryBuyerSellers,
  useQueryCompanies,
  useQuerySellerProducts,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import {
  DropdownWithCustomChildren,
  IConcordFormOnChangeParams,
  ToolTipOverlay,
  CompanyAvatar,
  IRTColumnDef,
  RTRow,
  RTCell,
  GraphIcon,
  CartIcon,
  BeakerIcon,
  DialogSellerProductForm,
  InfoIcon,
  CustomScheduleStatusOption,
} from '~/components/shared'

import { ISchedule, IScheduleFormData } from '~/types/models/ISchedule'
import { EFieldType, EScope, EStatus } from '~/types/enums/ECommonEnum'
import { ITerminal } from '~/types/models/ITerminal'
import { IonIcon, IonText } from '@ionic/react'
import { ActionMeta } from 'react-select'
import { apiClient } from '~/api/ApiClient'
import { ENoteableType } from '~/types/enums/ENote'
import { ISchedulesTableProps } from './type'
import { IScheduleLoad } from '~/types/models/IScheduleLoad'
import { INote } from '~/types/models/INote'
import { produce } from 'immer'
import { ILoad } from '~/types/models/ILoad'
import {
  CustomLdsQtyField,
  ICustomLdsQtyFieldOnChangeParams,
} from './CustomLdsQtyField'
import { countMinutesFromTimeToNow, getMinDate } from '~/utils/getDay'
import { useDeepCompareEffect } from 'react-use'
import { IOrderFormData } from '~/types/models/IOrder'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { IBuyerSeller } from '~/types/models/IBuyerSeller'
import { Badge, Popover } from 'react-bootstrap'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { defaultStatuses } from '~/components/fleet/constants'
import { DateTime } from 'luxon'
import useQueryOrderExtras from '~/hooks/useQueryData/useQueryOrderExtras/useQueryOrderExtras'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import Tooltip from 'rc-tooltip'
import { EBuyerSellerProductMaterialType } from '~/types/enums/EBuyerSellerProduct'
import { BundleItemsOverlay } from './BundleItemsOverlay'
import { EOrderType } from '~/types/enums/ESellerProduct'
import { arrowDownCircleOutline, arrowUpCircleOutline } from 'ionicons/icons'
import buildFullAddress from '~/utils/buildFullAddress'
import { ICompany } from '~/types/models/ICompany'

import './styles.scss'

const useSchedulesTable = (props: ISchedulesTableProps) => {
  const {
    schedules,
    afterUpdateOrder,
    afterUpdateSchedule,
    tableHeight,
    filterOptions,
    state,
    enableTopToolbar,
    manualFiltering,
    toolbarProps,
    enableCompanyView,
    companyViewProps,
    onColumnFiltersChange,
    renderDetailPanel,
    onSortingChange,
    afterUpdateScheduleLoad,
  } = props

  const [lineGraphState, setLineGraphState] = useState({
    isOpen: false,
    orderIds: [] as number[],
    scheduleIds: undefined as number[] | undefined,
  })
  const [notesData, setNotesData] = useState<INote[]>([])
  const [loadsData, setLoadsData] = useState<ILoad[]>([])
  const [selectedSchedule, setSelectedSchedule] =
    useState<ISchedule | null>(null)
  const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
  const [defaultTab, setDefaultTab] =
    useState<string | number | undefined>(undefined)
  const [orderExtrasDialog, setOrderExtrasDialog] = useState({
    isOpen: false,
    orderId: undefined as number | undefined,
  })
  const [termialDetailsModal, setTermialDetailsModal] = useState({
    isOpen: false,
    id: null as number | null,
  })
  const [terminalForm, setTerminalForm] = useState({
    isOpen: false,
    formData: undefined as ITerminal | undefined,
  })
  const [companyForm, setCompanyForm] = useState({
    isOpen: false,
    formData: undefined as ICompany | undefined,
  })

  const {
    findCompanyById,
    isLoadingCompaniesData,
    updateCompany,
    fleetCompanyOptions,
  } = useQueryCompanies({})

  const {
    findTerminalById,
    isLoadingTerminals,
    updateTerminal,
    addTerminal,
    terminalOptions,
  } = useQueryTerminals()

  const { buyerSellersData } = useQueryBuyerSellers()

  const { getTimeFormat } = useDateTimeFormat()

  const currentBuyerIds = useMemo(() => {
    const ids = schedules.map(({ order }) => order.buyerId)
    return _.uniq(ids)
  }, [schedules])

  const currentSellerIds = useMemo(() => {
    const ids = schedules.map(({ order }) => order.sellerId)
    return _.uniq(ids)
  }, [schedules])

  const orderIds = useMemo(
    () => schedules.map(({ order }) => order.id),
    [schedules],
  )

  const {
    findBuyerSellerProductById,
    findSellerProductById,
    createProductOptionsByBuyerSeller,
    isLoadingBuyerSellerProducts,
    refetchBuyerSellerProduct,
    refetchSellerProductsData,
    buyerSellerProducts,
  } = useQueryBuyerSellerProducts(
    {
      filters: {
        joinsSellerProduct: true,
      },
    },
    {
      enabled: Boolean(currentBuyerIds.length && currentSellerIds.length),
    },
  )

  const { orderExtras } = useQueryOrderExtras(
    {
      filters: {
        orderId: orderIds,
      },
    },
    { enabled: Boolean(orderIds.length > 0) },
  )

  const spIds = useMemo(
    () => _.uniq(orderExtras.map(({ sellerProductId }) => sellerProductId)),
    [orderExtras],
  )

  const { sellerProductsObj } = useQuerySellerProducts(
    { filters: { id: spIds } },
    { enabled: Boolean(spIds) },
  )

  const { confirmation } = useConfirmationProvider()

  const isDifferentTerminal = useCallback((schedule: ISchedule) => {
    const { nextLoads } = schedule
    if (nextLoads && nextLoads?.length > 0) {
      const { beginSellerTerminalId } = nextLoads[0]
      return schedule.sellerTerminalId !== beginSellerTerminalId
    }

    return false
  }, [])

  const onChangeDropdownFields = useCallback(
    (scheduleId: number, field: keyof ISchedule) =>
      async (event: ActionMeta<any>, { value }: IConcordFormOnChangeParams) => {
        const response = await apiClient.schedules.update(scheduleId, {
          schedule: {
            [field]: value,
          },
        })
        afterUpdateSchedule && afterUpdateSchedule(response)
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const cellTimeClassName = useCallback((minutes: number) => {
    // negative number
    if (Math.sign(minutes) === -1) {
      const positiveNum = Math.abs(minutes)
      if (positiveNum >= 60) {
        return 'moreThanOneHourLate'
      }
      if (positiveNum >= 30) {
        return 'moreThan30MinutesLate'
      }
      if (positiveNum >= 15) {
        return 'moreThan15MinutesLate'
      }
      if (positiveNum >= 5) {
        return 'moreThan5MinutesLate'
      }
    }

    if (minutes > 10) {
      return 'earlier10Minutes'
    }

    return 'between10MinsEarlyTo5MinutesLate'
  }, [])

  const getBackgroundBasedOnTime = useCallback(
    (field: string, { nextLoads }: ISchedule) => {
      if (nextLoads) {
        const basedOnField =
          field === 'nextArrivalTime' ? 'arriveBuyerTime' : 'ticketTime'
        const nextLoad: IScheduleLoad = getMinDate(nextLoads || [])
        if (nextLoad) {
          const value = nextLoad[basedOnField]
          const minutesFromNow = countMinutesFromTimeToNow(value)
          return cellTimeClassName(minutesFromNow)
        }
        return ''
      }
      return ''
    },
    [cellTimeClassName],
  )

  const getCellClassName = useCallback(
    ({ cell, row }: { cell: RTCell<ISchedule>; row: RTRow<ISchedule> }) => {
      const field = cell.column.id
      const rowData = row.original
      if (field === 'sellerTerminalId' && isDifferentTerminal(rowData)) {
        return 'bg-light-red'
      }

      if (field && ['nextArrivalTime', 'nextTicketTime'].includes(field)) {
        return getBackgroundBasedOnTime(field, rowData)
      }
    },
    [isDifferentTerminal, getBackgroundBasedOnTime],
  )

  const onCellEditEnd = useCallback(
    async (value: any, cell: RTCell<ISchedule>) => {
      const { column, row } = cell
      const rowId = row.original.id
      let columnField = column.id

      if (columnField === 'date') {
        columnField = 'startTime'
      }

      try {
        const response = await apiClient.schedules.update(Number(rowId), {
          schedule: {
            [columnField]: value,
          },
        })
        afterUpdateSchedule && afterUpdateSchedule(response)
      } catch (error) {
        console.log('error', error)
      }
    },
    [afterUpdateSchedule],
  )

  const afterCreateNote = useCallback((note: INote) => {
    setNotesData(prev => [...prev, note])
  }, [])

  const afterUpdateNote = useCallback((note: INote) => {
    setNotesData(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(item => note.id === item.id)
        if (index !== -1) {
          draft[index] = {
            ...draft[index],
            ...note,
          }
        }
      }),
    )
  }, [])

  const afterDeleteNote = useCallback((note: INote) => {
    setNotesData(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(item => note.id === item.id)
        if (index !== -1) {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const onOpenScheduleLineGraph = useCallback(
    (rowData: ISchedule, scheduleIds?: number[]) => () => {
      setLineGraphState({
        isOpen: true,
        orderIds: [rowData.orderId],
        scheduleIds,
      })
    },
    [],
  )

  const onCloseScheduleLineGraph = useCallback(() => {
    setLineGraphState({
      isOpen: false,
      orderIds: [],
      scheduleIds: undefined,
    })
  }, [])

  const onChangeLdsQtyField = useCallback(
    (schedule: ISchedule) =>
      async (params: ICustomLdsQtyFieldOnChangeParams) => {
        const response = await apiClient.schedules.update(schedule.id, {
          schedule: {
            lds: params.lds || 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            qty: (params.qty || 0) as any,
          },
        })
        afterUpdateSchedule && afterUpdateSchedule(response)
      },
    [afterUpdateSchedule],
  )

  const onChangePlusLoad = useCallback(
    (schedule: ISchedule) =>
      async ({ plus }: { plus: boolean }) => {
        const response = await apiClient.schedules.update(schedule.id, {
          schedule: {
            plus,
          },
        })
        afterUpdateSchedule && afterUpdateSchedule(response)
      },
    [afterUpdateSchedule],
  )

  const onOpenOrderForm = useCallback(
    (schedule: ISchedule, defaultTab?: number | string) => () => {
      setIsOpenOrderForm(true)
      setSelectedSchedule(schedule)
      if (defaultTab !== undefined) {
        setDefaultTab(defaultTab)
      }
    },
    [],
  )

  const onCloseOrderForm = useCallback(() => {
    setIsOpenOrderForm(false)
    setSelectedSchedule(null)
    setDefaultTab(undefined)
  }, [])

  const onCloseOrderExtrasDialog = useCallback(() => {
    setOrderExtrasDialog({
      isOpen: false,
      orderId: undefined,
    })
  }, [])

  const updateOrder = useCallback(
    async (orderId: number, payload: Partial<IOrderFormData>) => {
      const { order, errors } = await apiClient.orders.update(orderId, {
        order: payload,
      })
      if ((errors as string[]).length > 0) {
        toast.error(toastMessages.createError)
      } else {
        return order
      }
    },
    [],
  )

  const updateSchedule = useCallback(
    async (scheduleId: number, payload: Partial<IScheduleFormData>) => {
      const { errors, ...schedule } = await apiClient.schedules.update(
        scheduleId,
        {
          schedule: payload,
        },
      )
      if ((errors as string[]).length > 0) {
        toast.error(toastMessages.createError)
      } else {
        return schedule
      }
    },
    [],
  )

  const onChangeBuyerSellerProduct = useCallback(
    (schedule: ISchedule, buyerSellerData: IBuyerSeller) =>
      async (event: any, { selectedOption }: IConcordFormOnChangeParams) => {
        let buyerSellerProductId = selectedOption.value

        if (selectedOption.isSellerProduct) {
          const buyerSellerProdRes = await apiClient.buyerSellerProducts.create(
            {
              buyerSellerProduct: {
                buyerSellerId: buyerSellerData.id,
                sellerProductId: selectedOption.value,
              },
            },
          )
          buyerSellerProductId = buyerSellerProdRes.id
          refetchBuyerSellerProduct()
        }

        if (
          !schedule.buyerSellerProductId &&
          schedule.order.buyerSellerProductId
        ) {
          // show confirm
          const result = await confirmation({
            message: 'Should it be set on this schedule only or the order?',
            className: 'SchedulesTable__confirmation',
            buttons: [
              {
                action: 'schedule',
                text: 'Update Schedule only',
                color: 'primary',
              },
              {
                action: 'order',
                text: 'Update Order',
                color: 'warning',
              },
            ],
          })

          if (result === 'order') {
            const newOrder = await updateOrder(schedule.order.id, {
              buyerSellerProductId,
            })
            if (newOrder) {
              const updatedSchedule = { ...schedule, order: newOrder }
              afterUpdateSchedule && afterUpdateSchedule(updatedSchedule)
            }
          } else {
            const newSchedule = await updateSchedule(schedule.id, {
              buyerSellerProductId,
            })
            afterUpdateSchedule &&
              afterUpdateSchedule({ ...schedule, ...newSchedule })
          }
        } else {
          const newSchedule = await updateSchedule(schedule.id, {
            buyerSellerProductId,
          })
          afterUpdateSchedule &&
            afterUpdateSchedule({ ...schedule, ...newSchedule })
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterUpdateSchedule, confirmation, updateOrder, updateSchedule],
  )

  const getBuyerSellerProductTextColor = useCallback((schedule: ISchedule) => {
    if (schedule.buyerSellerProductId) {
      return 'dark'
    }
    if (schedule.order.buyerSellerProductId) {
      return 'medium'
    }

    return 'concord'
  }, [])

  const renderBuyerSellerProductCell = useCallback(
    (schedule: ISchedule) => {
      const buyerSellerProductId =
        schedule.buyerSellerProductId || schedule.order.buyerSellerProductId

      const color = getBuyerSellerProductTextColor(schedule)

      const buyerSellerProduct = findBuyerSellerProductById(
        buyerSellerProductId as number,
      )

      let text = 'Select product'
      let tooltip = ''

      if (buyerSellerProduct) {
        text = buyerSellerProduct.sellerProductCode || 'Unknown'
      }

      if (
        !schedule.buyerSellerProductId &&
        schedule.order.buyerSellerProductId
      ) {
        tooltip = 'Product is set on order'
      }

      return (
        <ToolTipOverlay
          content={
            <div>
              {tooltip && (
                <div>
                  <InfoIcon
                    color='var(--bs-warning)'
                    style={{ verticalAlign: 'middle' }}
                  />
                  <span style={{ marginLeft: 4 }}>{tooltip}</span>
                </div>
              )}
              <div>
                <strong>Product Name</strong>:{' '}
                {buyerSellerProduct?.sellerProductName}
              </div>
            </div>
          }
          placement='top'
        >
          <IonText color={color}>{text}</IonText>
        </ToolTipOverlay>
      )
    },
    [findBuyerSellerProductById, getBuyerSellerProductTextColor],
  )

  const getTime = useCallback((time: string) => {
    return Number(time)
  }, [])

  const onChangeScheduleStatus = useCallback(
    (cell: RTCell<ISchedule>) =>
      (event: ActionMeta<any>, { value }: IConcordFormOnChangeParams) => {
        onCellEditEnd(value, cell)
      },
    [onCellEditEnd],
  )

  const getTerminalIcon = (type: 'pickup' | 'dropoff') => {
    if (type === 'pickup') {
      return arrowUpCircleOutline
    }
    return arrowDownCircleOutline
  }

  const getTerminalColor = (type: 'pickup' | 'dropoff') => {
    if (type === 'pickup') {
      return 'var(--bs-dark)'
    }
    return 'var(--bs-blue)'
  }

  const renderTerminalMarkerIcon = (
    terminal: ITerminal,
    type: 'pickup' | 'dropoff',
  ) => {
    const icon = getTerminalIcon(type)
    const color = getTerminalColor(type)

    return (
      <ToolTipOverlay
        content={buildFullAddress(terminal?.address)}
        placement='top'
      >
        <span
          style={{ marginLeft: 4, cursor: 'pointer' }}
          onClick={event => {
            event.stopPropagation()
            setTermialDetailsModal({
              isOpen: true,
              id: terminal.id,
            })
          }}
          onContextMenu={event => {
            event.preventDefault()
            setTerminalForm({
              isOpen: true,
              formData: terminal,
            })
          }}
        >
          <span
            style={{
              background: color,
              fontWeight: 'bold',
              color: 'white',
              opacity: 1,
              padding: '2px 2px',
              borderRadius: '15px',
              width: '20px',
              height: '20px',
              fontSize: '20px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              verticalAlign: 'middle',
              marginRight: 4,
            }}
          >
            <IonIcon icon={icon} color={color} />
          </span>
        </span>
      </ToolTipOverlay>
    )
  }

  const renderExtrasCol = useCallback(
    (id: number) => {
      const items = orderExtras.filter(({ orderId }) => orderId === id)

      const scheduleExtras = items.filter(({ scheduleId }) => scheduleId)
      const orderExtrasFound = items.filter(({ scheduleId }) => !scheduleId)

      return (
        <div>
          <div
            style={{
              padding: 8,
              border: '1px solid var(--bs-dark)',
              borderRadius: 8,
              marginBottom: 8,
            }}
          >
            <p className='mb-0' style={{ fontWeight: 600 }}>
              Order Extras: <Badge>{orderExtrasFound.length}</Badge>
            </p>
            <ul>
              {orderExtrasFound.map(({ sellerProductId }) => (
                <li key={`schEx${sellerProductId}`}>
                  {sellerProductsObj[sellerProductId]?.code} -{' '}
                  {sellerProductsObj[sellerProductId]?.name}
                </li>
              ))}
            </ul>
          </div>
          <div
            style={{
              padding: 8,
              border: '1px solid var(--bs-dark)',
              borderRadius: 8,
            }}
          >
            <p className='mb-0' style={{ fontWeight: 600 }}>
              Schedule Extras: <Badge>{scheduleExtras.length}</Badge>
            </p>
            <ul>
              {scheduleExtras.map(({ sellerProductId }) => (
                <li key={`schEx${sellerProductId}`}>
                  {sellerProductsObj[sellerProductId]?.code} -{' '}
                  {sellerProductsObj[sellerProductId]?.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )
    },
    [orderExtras, sellerProductsObj],
  )

  const onRightClickSellerTerminal = (rowData: ITerminal) => (event: any) => {
    event.preventDefault()
    setTerminalForm({
      isOpen: true,
      formData: rowData,
    })
  }

  const columns: IRTColumnDef<ISchedule>[] = [
    {
      header: '#',
      accessorKey: 'order.num',
      maxSize: 70,
      // hide: isHiddenOrderNumColumn,
      Cell({ cell, row }) {
        const cellData = cell.getValue<string>()
        const rowData = row.original
        const str = renderExtrasCol(rowData.orderId)

        return (
          <div>
            <div>
              <ToolTipOverlay content='Order #' placement='top'>
                <span className='hyperLink' onClick={onOpenOrderForm(rowData)}>
                  {cellData}
                </span>
              </ToolTipOverlay>
              &nbsp;-&nbsp;
              <ToolTipOverlay content='Schedule #' placement='top'>
                <span
                  className='hyperLink'
                  onClick={onOpenOrderForm(
                    rowData,
                    rowData.scheduleNumber as string,
                  )}
                >
                  {rowData.scheduleNumber || '-'}
                </span>
              </ToolTipOverlay>
            </div>

            <span
              className='icon'
              onClick={onOpenScheduleLineGraph(rowData, [rowData.id])}
            >
              <GraphIcon />
            </span>

            <Tooltip
              placement='bottom'
              overlay={
                <Popover className='SchedulesTable__popoverExtras'>
                  <Popover.Body>{str}</Popover.Body>
                </Popover>
              }
              destroyTooltipOnHide
              overlayClassName='SchedulesTable__overlayExtras'
            >
              <span
                className='icon'
                onClick={() => {
                  setOrderExtrasDialog({
                    isOpen: true,
                    orderId: row.original.orderId,
                  })
                }}
              >
                <CartIcon color='var(--bs-dark)' />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      minSize: 125,
      maxSize: 125,
      className: 'SchedulesTable__cell',
      Cell({ cell }) {
        const cellData = cell.getValue<string>()
        return (
          <DropdownWithCustomChildren
            options={defaultStatuses}
            value={cellData}
            onChange={onChangeScheduleStatus(cell)}
            components={{
              Option: CustomScheduleStatusOption,
            }}
            showSearchField={false}
            menuPortalTarget={document.body}
          >
            <Badge
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              bg={(ORDER_STATUSES as any)[cellData]?.color}
              style={{ fontSize: 12, verticalAlign: 'middle' }}
            >
              <span
                style={{
                  verticalAlign: 'middle',
                  marginRight: 4,
                  fontSize: 18,
                  display: 'inline-block',
                  marginTop: 3,
                }}
              >
                <IonIcon
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  icon={(ORDER_STATUSES as any)[cellData]?.icon}
                />
              </span>
              <span style={{ verticalAlign: 'middle' }}>{cellData}</span>
            </Badge>
          </DropdownWithCustomChildren>
        )
      },
    },
    {
      header: 'Buyer / Terminal',
      accessorKey: 'buyerId',
      minSize: 200,
      Cell({ row }) {
        const rowData = row.original

        const buyer = findCompanyById(rowData.order.buyerId)
        const terminal = findTerminalById(rowData.order.buyerTerminalId)

        return (
          <div
            className='d-flex flex-row align-items-center'
            style={{ width: '100%', justifyContent: 'space-between' }}
          >
            <div className='d-flex align-items-center'>
              <CompanyAvatar
                company={{
                  value: buyer?.id,
                  label: buyer?.name || '',
                  image: buyer?.logo,
                  code: buyer?.code,
                }}
                companyType={EScope.buyer}
                onContextMenu={event => {
                  event.preventDefault()
                  setCompanyForm({
                    isOpen: true,
                    formData: buyer,
                  })
                }}
                tooltipMessage={buyer?.name}
                isChip
              />
              &nbsp;
              <ToolTipOverlay placement='top' content={terminal?.code}>
                <span
                  onContextMenu={onRightClickSellerTerminal(
                    terminal as ITerminal,
                  )}
                >
                  {terminal?.name}
                </span>
              </ToolTipOverlay>
            </div>
            {renderTerminalMarkerIcon(terminal as ITerminal, 'dropoff')}
          </div>
        )
      },
    },
    {
      header: 'Seller / Terminal',
      accessorKey: 'sellerId',
      minSize: 200,
      className: 'SchedulesTable__cell',
      Cell({ row }) {
        const rowData = row.original

        const seller = findCompanyById(rowData.order.sellerId)

        const terminal = findTerminalById(rowData.sellerTerminalId)
        const filteredSellerTerminal = terminalOptions.filter(
          ({ companyId }) => companyId === rowData.order.sellerId,
        )
        if (isDifferentTerminal(rowData) && rowData.nextLoads) {
          const { nextLoads } = rowData
          const { beginSellerTerminalId } = nextLoads[0]
          const loadTerminal = findTerminalById(beginSellerTerminalId)

          return (
            <>
              <CompanyAvatar
                company={{
                  value: seller?.id,
                  label: seller?.name || '',
                  name: seller?.name,
                  image: seller?.logo,
                  code: seller?.code,
                }}
                companyType={EScope.seller}
                onContextMenu={event => {
                  event.preventDefault()
                  setCompanyForm({
                    isOpen: true,
                    formData: seller,
                  })
                }}
                isChip
                bg='dark'
              />
              <DropdownWithCustomChildren
                options={filteredSellerTerminal}
                onChange={onChangeDropdownFields(
                  rowData.id,
                  'sellerTerminalId',
                )}
                value={rowData.sellerTerminalId}
                menuPortalTarget={document.body}
              >
                <div
                  className='sellerTerminalCell'
                  onContextMenu={onRightClickSellerTerminal(
                    terminal as ITerminal,
                  )}
                >
                  <div className='item'>
                    <span className='text'>Default:</span>
                    <ToolTipOverlay content={terminal?.name} placement='top'>
                      <span style={{ marginLeft: 4 }}>{terminal?.code}</span>
                    </ToolTipOverlay>
                  </div>

                  <div className='item'>
                    <span className='text'>Next LD:</span>
                    <ToolTipOverlay
                      content={loadTerminal?.name}
                      placement='top'
                    >
                      <span style={{ marginLeft: 4 }}>
                        {loadTerminal?.code}
                      </span>
                    </ToolTipOverlay>
                  </div>
                </div>
              </DropdownWithCustomChildren>
              {renderTerminalMarkerIcon(terminal as ITerminal, 'pickup')}
            </>
          )
        }

        return (
          <div
            className='d-flex flex-row align-items-center'
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '100%' }}>
              <CompanyAvatar
                company={{
                  value: seller?.id,
                  label: seller?.name || '',
                  image: seller?.logo,
                  code: seller?.code,
                }}
                tooltipMessage={seller?.name}
                onContextMenu={event => {
                  event.preventDefault()
                  setCompanyForm({
                    isOpen: true,
                    formData: seller,
                  })
                }}
                isChip
                bg='dark'
                className='seller-terminal-chip-bg-color'
              />

              <DropdownWithCustomChildren
                options={filteredSellerTerminal}
                onChange={onChangeDropdownFields(
                  rowData.id,
                  'sellerTerminalId',
                )}
                value={rowData.sellerTerminalId}
                menuPortalTarget={document.body}
                className='SchedulesTable_sellerCells make-custom-dropdown-inline'
              >
                <ToolTipOverlay placement='top' content={terminal?.code}>
                  <span
                    onContextMenu={onRightClickSellerTerminal(
                      terminal as ITerminal,
                    )}
                    style={{ marginLeft: 4 }}
                  >
                    {terminal?.name}
                  </span>
                </ToolTipOverlay>
              </DropdownWithCustomChildren>
            </div>

            {renderTerminalMarkerIcon(terminal as ITerminal, 'pickup')}
          </div>
        )
      },
    },
    {
      header: 'Fleet',
      accessorKey: 'fleetId',
      maxSize: 150,
      className: 'SchedulesTable__cell',
      Cell({ row }) {
        const rowData = row.original

        const company = findCompanyById(rowData.fleetId)
        return (
          <DropdownWithCustomChildren
            options={fleetCompanyOptions}
            onChange={onChangeDropdownFields(rowData.id, 'fleetId')}
            menuIsOpen
            menuPortalTarget={document.body}
          >
            <CompanyAvatar
              company={{
                value: company?.id,
                label: company?.name || '',
                name: company?.name,
                image: company?.logo,
                code: company?.code,
              }}
              companyType={EScope.fleet}
              tooltipMessage={company?.name}
              onContextMenu={event => {
                event.preventDefault()
                setCompanyForm({
                  isOpen: true,
                  formData: company,
                })
              }}
              isChip
            />
            &nbsp;{company?.code}
          </DropdownWithCustomChildren>
        )
      },
    },
    {
      header: 'Product',
      accessorKey: 'buyerSellerProductId',
      className: 'SchedulesTable__cell',
      Cell({ cell, row }) {
        const cellData = cell.getValue<number>()
        const rowData = row.original
        const { order } = rowData

        const value = cellData || order.buyerSellerProductId
        const buyerSellerData = buyerSellersData.find(
          ({ buyerId, sellerId }) =>
            order.sellerId === sellerId && order.buyerId === buyerId,
        )
        const options = createProductOptionsByBuyerSeller(
          buyerSellerData?.id as number,
          order.sellerId,
        )
        const buyerSellerProduct = findBuyerSellerProductById(value as number)

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <DropdownWithCustomChildren
              options={options}
              onChange={onChangeBuyerSellerProduct(
                rowData,
                buyerSellerData as IBuyerSeller,
              )}
              value={value}
              showCreateButton
              showDeleteButtons
              showUpdateButtons
              menuPortalTarget={document.body}
              onDeleteOption={async (opt: any) => {
                if (opt.isSellerProduct) {
                  const buyerSellerProd = buyerSellerProducts.find(
                    ({ sellerProductId }) => sellerProductId === opt.value,
                  )
                  if (buyerSellerProd) {
                    await apiClient.buyerSellerProducts.delete(
                      buyerSellerProd.id,
                    )
                  }
                  await apiClient.sellerProducts.delete(opt.value)
                } else {
                  await apiClient.buyerSellerProducts.delete(opt.value)
                }
                refetchBuyerSellerProduct()
              }}
              renderForm={({ isOpen, optionSelected, setFormState }) => {
                let formData: any = undefined
                if (optionSelected?.isSellerProduct) {
                  formData = findSellerProductById(optionSelected?.value)
                } else {
                  const buyerSellerProd = findBuyerSellerProductById(
                    optionSelected?.value,
                  )
                  if (buyerSellerProd) {
                    formData = findSellerProductById(
                      buyerSellerProd?.sellerProductId as number,
                    )
                  }
                }

                return (
                  <DialogSellerProductForm
                    isOpen={isOpen}
                    formData={
                      formData ||
                      ({
                        orderType: EOrderType.primary,
                        sellerId: buyerSellerData?.seller?.id as number,
                        status: EStatus.Active.toLowerCase() as any,
                      } as any)
                    }
                    onClose={() => {
                      setFormState({
                        isOpen: false,
                        optionSelected: null,
                      })
                    }}
                    afterCreate={async newProduct => {
                      setFormState({
                        isOpen: false,
                        optionSelected: null,
                      })
                      const newBuyerSellerProd =
                        await apiClient.buyerSellerProducts.create({
                          buyerSellerProduct: {
                            sellerProductId: newProduct.id,
                            buyerSellerId: buyerSellerData?.id,
                          },
                        })
                      onChangeBuyerSellerProduct(
                        rowData,
                        buyerSellerData as IBuyerSeller,
                      )(null, {
                        selectedOption: {
                          value: newBuyerSellerProd.id,
                        },
                        value: newBuyerSellerProd.id,
                      })
                      refetchBuyerSellerProduct()
                    }}
                    afterUpdate={() => {
                      setFormState({
                        isOpen: false,
                        optionSelected: null,
                      })
                      refetchBuyerSellerProduct()
                      refetchSellerProductsData()
                    }}
                  />
                )
              }}
            >
              {renderBuyerSellerProductCell(rowData)}
            </DropdownWithCustomChildren>
            {buyerSellerProduct?.materialType ===
              EBuyerSellerProductMaterialType.bundledMaterial && (
              <BundleItemsOverlay
                sellerProductId={buyerSellerProduct.sellerProductId as number}
              >
                <span style={{ marginLeft: 6, cursor: 'pointer' }}>
                  <BeakerIcon />
                </span>
              </BundleItemsOverlay>
            )}
          </div>
        )
      },
    },
    {
      header: 'Date',
      id: 'date',
      accessorFn: rowData => rowData.startTime,
      size: 80,
      editVariant: EFieldType.date,
      enableEditing: ({ original: { ldsCounter } }) => ldsCounter === 0,
      Cell: ({ cell }) => {
        const dateTime = cell.getValue<string>()
        const date = DateTime.fromISO(dateTime).toFormat('MMM-d')
        return <span>{date}</span>
      },
    },
    {
      header: 'Start time',
      id: 'startTime',
      accessorFn: rowData => rowData.startTime,
      size: 120,
      editVariant: EFieldType.time,
      enableEditing: ({ original: { ldsCounter } }) => ldsCounter === 0,
      Cell: ({ cell }) => {
        return <span>{getTimeFormat(cell.getValue<string>())}</span>
      },
    },
    {
      header: 'Next Arrival',
      id: 'nextArrivalTime',
      accessorFn: rowData =>
        getMinDate(rowData.nextLoads || [], 'arriveBuyerTime')?.arriveBuyerTime,
      maxSize: 120,
      Cell({ cell }) {
        const cellValue = cell.getValue<string>()

        if (cellValue) {
          const minutesFromNow = countMinutesFromTimeToNow(cellValue)

          return (
            <ToolTipOverlay content={`${minutesFromNow} min`} placement='top'>
              <span>{getTimeFormat(cellValue)}</span>
            </ToolTipOverlay>
          )
        }
        return ''
      },
    },
    {
      header: 'Next Ticket',
      id: 'nextTicketTime',
      accessorFn: rowData =>
        getMinDate(rowData.nextLoads || [], 'ticketTime')?.ticketTime,
      maxSize: 120,
      Cell({ row: { original } }) {
        const cellValue = getMinDate(
          original.nextLoads || [],
          'ticketTime',
        )?.ticketTime
        if (cellValue) {
          const minutesFromNow = countMinutesFromTimeToNow(cellValue)

          return (
            <ToolTipOverlay
              content={`${getTimeFormat(cellValue)}`}
              placement='top'
            >
              <span>{`${minutesFromNow} min`}</span>
            </ToolTipOverlay>
          )
        }
        return ''
      },
    },
    {
      header: 'Travel Time',
      accessorKey: 'routeTravelTime',
      maxSize: 120,
      Cell: ({ row }) => {
        const rowData = row.original

        const { nextLoads = [] } = rowData
        if (nextLoads.length === 0) {
          return null
        }

        const {
          loadTime,
          ticketToLoadTime,
          travelTimeToBuyer,
          travelTimeToSeller,
        } = nextLoads[0]

        return (
          <ToolTipOverlay
            className='SchedulesTable__tooltip'
            content={
              <ul>
                <li>
                  <span>Ticket to Load: </span>
                  <span>{getTime(ticketToLoadTime)} min</span>
                </li>
                <li>
                  <span>Loading: </span>
                  <span>{getTime(loadTime)} min</span>
                </li>
                <li>
                  <span>Travel to Seller: </span>
                  <span>{getTime(travelTimeToSeller)} min</span>
                </li>
              </ul>
            }
            placement='top'
          >
            <span>{getTime(travelTimeToBuyer)} min</span>
          </ToolTipOverlay>
        )
      },
    },
    {
      header: 'Qty / LDs',
      id: 'ldsOrQty',
      accessorFn: rowData => rowData.qty,
      className: 'SchedulesTable__cell',
      Cell({ row }) {
        const rowData = row.original

        const { plus, sellerTerminalId } = rowData
        return (
          <CustomLdsQtyField
            sellerTerminalId={sellerTerminalId}
            plus={plus}
            qty={rowData.qty}
            qtyCounter={rowData.qtyCounter}
            lds={rowData.lds}
            ldsCounter={rowData.ldsCounter}
            onChange={onChangeLdsQtyField(rowData)}
            onChangePlusLoad={onChangePlusLoad(rowData)}
          />
        )
      },
    },
    {
      header: 'Spacing',
      accessorKey: 'spacing',
      enableEditing: true,
      maxSize: 100,
      editVariant: EFieldType.number,
    },
    {
      header: 'LD Size',
      accessorKey: 'loadSize',
      enableEditing: true,
      size: 100,
      editVariant: EFieldType.number,
    },
  ]

  const fetchScheduleNotes = useCallback(async () => {
    if (schedules.length > 0) {
      const scheduleIds = schedules.map(({ id }) => id)
      const response = await apiClient.notes.get({
        filters: {
          noteableId: scheduleIds,
          noteableType: ENoteableType.Schedule,
        },
      })
      setNotesData(response.notes)
    }
  }, [schedules])

  const fetchLoads = useCallback(async () => {
    if (schedules.length > 0) {
      const scheduleIds = schedules.map(({ id }) => id)
      const response = await apiClient.loads.get({
        filters: {
          scheduleId: scheduleIds,
        },
      })
      setLoadsData(response.loads)
    }
  }, [schedules])

  useDeepCompareEffect(() => {
    fetchLoads()
    fetchScheduleNotes()
  }, [schedules])

  return {
    columns,
    lineGraphState,
    notesData,
    loadsData,
    selectedSchedule,
    isOpenOrderForm,
    defaultTab,
    tableHeight,
    filterOptions,
    state,
    schedules,
    afterCreateNote,
    afterUpdateNote,
    afterDeleteNote,
    getCellClassName,
    onCellEditEnd,
    onCloseScheduleLineGraph,
    onCloseOrderForm,
    onCloseOrderExtrasDialog,
    afterUpdateOrder,
    onOpenScheduleLineGraph,
    enableTopToolbar,
    manualFiltering,
    toolbarProps,
    enableCompanyView,
    companyViewProps,
    onColumnFiltersChange,
    renderDetailPanel,
    onSortingChange,
    orderExtrasDialog,
    isLoadingBuyerSellerProducts,
    termialDetailsModal,
    setTermialDetailsModal,
    terminalForm,
    setTerminalForm,
    isLoadingTerminals,
    updateTerminal,
    addTerminal,
    isLoadingCompaniesData,
    companyForm,
    setCompanyForm,
    updateCompany,
    afterUpdateScheduleLoad,
  }
}

export default useSchedulesTable
