import { useCallback, useMemo, useRef, useState } from 'react'
import { ConcordFormStructure, IConcordFormField } from '../../../FormStructure'
import * as Yup from 'yup'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'
export interface IBundleBadgeFormValues {
  id?: number
  num: number | null
  name: string
  sellerTerminalIds: number[]
}
export interface IBundleBadgeFormProps {
  afterCreate?: (bundle: any) => void
  afterUpdate?: (bundle: any) => void
  onCancel?: () => void
  formData?: any
  sellerProductId: number
  sellerTerminalOptions: any[]
}

export const BundleBadgeForm = (props: IBundleBadgeFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    sellerProductId,
    sellerTerminalOptions,
    onCancel,
    // ...formProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const formRef = useRef<any>()
  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])
  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Name',
        name: 'name',
        size: 6,
      },
      {
        label: 'Num',
        name: 'num',
        size: 6,
        type: EFieldType.number,
        isHidden: !isUpdating,
      },

      {
        label: 'Seller Terminal',
        name: 'sellerTerminalIds',
        size: 6,
        type: EFieldType.multipleSelect,
        options: sellerTerminalOptions,
        isHidden: isUpdating,
      },
    ],
    [isUpdating, sellerTerminalOptions],
  )

  const schema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().nullable(),
      num: Yup.number().nullable(),
      sellerTerminalIds: Yup.array().of(Yup.number()),
    })
  }, [])

  const createBundle = useCallback(
    async (formValues: IBundleBadgeFormValues) => {
      const sellerTerminalProductsAttributes = formValues.sellerTerminalIds.map(
        terminalId => ({
          terminalId,
          sellerProductId,
        }),
      )

      try {
        const { errors, ...response } = await apiClient.bundles.create({
          bundle: {
            sellerProductId,
            name: formValues.name,
            sellerTerminalProductsAttributes,
          },
        })
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterCreate && afterCreate(response)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [afterCreate, sellerProductId],
  )

  const updateBundle = useCallback(async () => {
    setIsLoading(true)
    try {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.bundles.update(
          formData.id,
          {
            bundle: {
              name: formRef.current.getValues().name,
              num: formRef.current.getValues().num,
            },
          },
        )
        if (errors.length) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }, [afterUpdate, formData])

  const handleSubmit = useCallback(
    (formValues: IBundleBadgeFormValues) => {
      if (formData?.id) {
        updateBundle()
      } else {
        createBundle(formValues)
      }
    },
    [createBundle, formData?.id, updateBundle],
  )
  return (
    <ConcordFormStructure
      isLoading={isLoading}
      ref={formRef}
      formData={formData}
      fields={fields}
      isHiddenSearch
      onSubmit={handleSubmit}
      schema={schema}
      submitText={isUpdating ? 'Update' : 'Create'}
      onCancel={onCancel}
      error={error}
      // defaultValues={defaultValues}
      // {...formProps}
    />
  )
}
