export enum EYesNo {
  Yes = 'Yes',
  No = 'No',
}

export enum EPayFrequency {
  weekly = 'weekly',
  biweekly = 'biweekly',
  semimonthly = 'semimonthly',
  monthly = 'monthly',
}

export enum EStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
}

export enum EScope {
  driver = 'driver',
  seller = 'seller',
  buyer = 'buyer',
  fleet = 'fleet',
  driverFleet = 'driver_fleet',
  company = 'company',
}

export enum EFieldType {
  number = 'number',
  text = 'text',
  date = 'date',
  radio = 'radio',
  checkbox = 'checkbox',
  color = 'color',
  custom = 'custom',
  multipleSelect = 'multipleSelect',
  singleSelect = 'singleSelect',
  password = 'password',
  tags = 'tags',
  email = 'email',
  time = 'time',
  dateRange = 'dateRange',
  range = 'rangeNumber',
}

export enum EDaysOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum EPerPeriod {
  day = 'day',
  week = 'week',
  biWeek = 'bi_week',
  month = 'month',
}

export enum ESortDirection {
  desc = 'desc',
  asc = 'asc',
}

export enum ERoundingOption {
  exact = 'exact',
  nearest = 'nearest',
  forceUp = 'forceUp',
  forceDown = 'forceDown',
}

export enum EViewOption {
  list = 'list',
  table = 'table',
}

export enum ERTDisplayColumnId {
  actions = 'mrt-row-actions',
  drag = 'mrt-row-drag',
  expand = 'mrt-row-expand',
  numbers = 'mrt-row-numbers',
  pin = 'mrt-row-pin',
  select = 'mrt-row-select',
  spacer = 'mrt-row-spacer',
}

export enum ESetupUserStep {
  createUser,
  companySignup,
  createYourTerminals,
  addBuyers,
  addBuyerTerminals,
  addSellers,
  addSellerTerminals,
  complete,
  fillDriverDetails,
  driverUserAccessCreate,
}

export enum EUserProvider {
  email = 'email',
  phoneNumber = 'phone',
}

export enum EColor {
  red = '#dc3545',
  blue = '#0d6efd',
  green = '#198754',
  yellow = '#ffc107',
  purple = '#6f42c1',
  orange = '#fd7e14',
  black = '#212529',
  gray = '#adb5bd',
  cyan = '#0dcaf0',
  teal = '#20c997',
  pink = '#d63384',
  magenta = '#FF00FF',
  brown = '#A52A2A',
  lime = '#00FF00',
  indigo = '#4B0082',
  navy = '#000080',
  gold = '#FFD700',
}
