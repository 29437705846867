import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import {
  ISellerToBuyerTerminal,
  ISellerToBuyerTerminalGetListParams,
} from '~/types/models/ISellerToBuyerTerminal'
import useModifySellerToBuyerTerminals from './useModifySellerToBuyerTerminals'
import { getTitle } from '~/utils'
import { useQueryBuyerSellers } from '../useQueryBuyerSellers'
import { useQueryTerminals } from '../useQueryTerminals'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { useCallback, useMemo } from 'react'

const useQuerySellerToBuyerTerminals = (
  params: ISellerToBuyerTerminalGetListParams & { id?: number },
  options?: Partial<UseQueryOptions<ISellerToBuyerTerminal[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const {
    addSellerToBuyerTerminals,
    updateSellerToBuyerTerminals,
    removeSellerToBuyerTerminals,
  } = useModifySellerToBuyerTerminals(params)

  const { allCompaniesWithCurrentCompany, isLoadingBuyerSellers } =
    useQueryBuyerSellers()
  const { terminalsData, isLoadingTerminals } = useQueryTerminals()

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'sellerToBuyerTerminals',
      sessionUser?.id,
      buildGetUrl(apiClient.sellerToBuyerTerminals.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    async queryFn() {
      if (params.id) {
        const response = await apiClient.sellerToBuyerTerminals.getById(
          params.id,
        )
        return [response]
      }
      const response = await apiClient.sellerToBuyerTerminals.get(params)
      return response.sellerToBuyerTerminals
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const sellerToBuyerTerminals = useMemo(() => data || [], [data])

  const sellerToBuyerTerminalOptions = sellerToBuyerTerminals.map(
    ({
      id,
      startDate,
      endDate,
      qty,
      sellerId,
      buyerTerminalId,
      status,
      sellerTerminalId,
    }) => {
      const seller = allCompaniesWithCurrentCompany.find(
        ({ id }) => id === sellerId,
      )
      const buyerTerminal = terminalsData.find(
        ({ id }) => id === buyerTerminalId,
      )
      const sellerTerminal = terminalsData.find(
        ({ id }) => id === sellerTerminalId,
      )
      const buyer = allCompaniesWithCurrentCompany.find(
        ({ id }) => id === buyerTerminal?.companyId,
      )

      const label = [
        seller?.name,
        buyer?.name,
        sellerTerminal?.name,
        buyerTerminal?.name,
        status,
        qty,
        getTitle({ startDate, endDate }),
      ]
        .filter(text => text)
        .join(' - ')

      return {
        value: id,
        label: label,
        seller,
        buyer,
        buyerTerminal,
        sellerTerminal,
      }
    },
  )

  const findSellerToBuyerTerminalById = useCallback(
    (id: number) => {
      if (!isFetched || sellerToBuyerTerminals.length === 0) {
        return undefined
      }
      return sellerToBuyerTerminals.find(i => i.id === id)
    },
    [isFetched, sellerToBuyerTerminals],
  )

  return {
    sellerToBuyerTerminals,
    sellerToBuyerTerminalOptions,
    isSellerToBuyerTerminalsLoading:
      isLoading || isLoadingBuyerSellers || isLoadingTerminals,
    isSellerToBuyerTerminalsFetched: isFetched,
    refetchSellerToBuyerTerminals: refetch,
    addSellerToBuyerTerminals,
    updateSellerToBuyerTerminals,
    removeSellerToBuyerTerminals,
    findSellerToBuyerTerminalById,
  }
}

export default useQuerySellerToBuyerTerminals
