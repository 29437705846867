import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { ISalesProposalFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ISalesProposalFormData } from '~/types/models/ISalesProposal'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'
import { ESalesProposalStatus } from '~/types/enums/ESalesProposal'
import { useQuerySellerToBuyerTerminals } from '~/hooks/useQueryData/useQuerySellerToBuyerTerminals'

const SalesProposalForm = (props: ISalesProposalFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const { buyerCompanyOptions, sellerCompanyOptions, isLoadingCompaniesData } =
    useQueryCompanies({})
  const { sellerTerminalOptions, buyerTerminalOptions, findTerminalById } =
    useQueryTerminals()

  const {
    isSellerToBuyerTerminalsLoading,
    sellerToBuyerTerminalOptions,
    sellerToBuyerTerminals,
  } = useQuerySellerToBuyerTerminals({
    filters: {
      status: [
        'future',
        'imminent',
        'bid',
        'won',
        'active',
        'complete',
        'lost',
        'null',
      ] as any,
    },
    perPage: 1000,
  } as any)

  const fields: IConcordFormField[] = [
    {
      name: 'sellerToBuyerTerminalId',
      label: 'Sales Backlog',
      size: 12,
      isRequired: true,
      type: EFieldType.singleSelect,
      options: sellerToBuyerTerminalOptions,
      isLoading: isSellerToBuyerTerminalsLoading,
      onChange({ selectedOption }) {
        formRef.current?.setValue('companyId', selectedOption.seller?.id)
        formRef.current?.setValue(
          'sellerTerminalId',
          selectedOption.sellerTerminal?.id,
        )
        formRef.current?.setValue('buyerId', selectedOption.buyer?.id)
        formRef.current?.setValue(
          'buyerTerminalId',
          selectedOption.buyerTerminal?.id,
        )
      },
    },
    {
      name: 'companyId',
      label: 'Seller',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: sellerCompanyOptions,
      isLoading: isLoadingCompaniesData,
    },
    {
      name: 'sellerTerminalId',
      label: 'Seller Terminal',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: sellerTerminalOptions,
    },
    {
      name: 'buyerId',
      label: 'Buyer',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: buyerCompanyOptions,
      isLoading: isLoadingCompaniesData,
    },
    {
      name: 'buyerTerminalId',
      label: 'Buyer Terminal',
      size: 6,
      isReadOnly: true,
      type: EFieldType.singleSelect,
      options: buyerTerminalOptions,
    },
    {
      name: 'status',
      label: 'Status',
      type: EFieldType.singleSelect,
      options: [
        {
          label: 'In Progress',
          value: 0,
        },
        {
          label: 'Sent',
          value: 1,
        },
        {
          label: 'Approved',
          value: 2,
        },
        {
          label: 'Rejected',
          value: 3,
        },
      ],
    },
  ]

  const schema = useMemo(
    () =>
      Yup.object({
        sellerToBuyerTerminalId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
      }),
    [],
  )

  const defaultValues = useMemo<ISalesProposalFormData>(
    () => ({
      companyId: null,
      sellerTerminalId: null,
      buyerTerminalId: null,
      buyerId: null,
      status: ESalesProposalStatus.inProgress,
      date: null,
      sellerToBuyerTerminalId: null,
    }),
    [],
  )

  const onCreate = useCallback(
    async (payload: ISalesProposalFormData) => {
      const { errors, ...res } = await apiClient.salesProposals.create(payload)
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: ISalesProposalFormData) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.salesProposals.update(
          formData.id,
          {
            status: payload.status,
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: ISalesProposalFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (formData?.sellerToBuyerTerminalId) {
      const opportunity = sellerToBuyerTerminals.find(
        ({ id }) => formData?.sellerToBuyerTerminalId === id,
      )
      if (opportunity) {
        const buyerTerminal = findTerminalById(opportunity.buyerTerminalId)
        formRef.current?.setValue('companyId', opportunity.sellerId)
        formRef.current?.setValue(
          'sellerTerminalId',
          opportunity.sellerTerminalId,
        )
        formRef.current?.setValue('buyerId', buyerTerminal?.companyId)
        formRef.current?.setValue(
          'buyerTerminalId',
          opportunity.buyerTerminalId,
        )
      }
    }
  }, [
    findTerminalById,
    formData?.sellerToBuyerTerminalId,
    isUpdating,
    sellerToBuyerTerminals,
  ])

  useEffect(() => {
    if (typeof formData?.status === 'string') {
      formRef.current?.setValue('status', ESalesProposalStatus[formData.status])
    }
  }, [formData?.status])

  return (
    <div>
      <ConcordFormStructure
        {...formProps}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default SalesProposalForm
