import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IBreadcrumbLoad,
  IBreadcrumbLoadGetListParams,
} from '~/types/models/IBreadcrumbLoad'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'

const useModifyBreadcrumbLoads = (params: IBreadcrumbLoadGetListParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'breadcrumbLoads',
      sessionUser.id,
      buildGetUrl(apiClient.breadcrumbLoads.endpoint, params),
    ],
    [params, sessionUser.id],
  )

  const addBreadcrumbLoad = useCallback(
    (data: IBreadcrumbLoad) => {
      queryClient.setQueryData<IBreadcrumbLoad[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [data, ...oldData]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  // const updateProductGroup = useCallback(
  //   (data: IProductGroup) => {
  //     queryClient.setQueryData<IGetProductGroupsResponse | undefined>(
  //       queryKey,
  //       oldData =>
  //         produce(oldData, draft => {
  //           if (draft) {
  //             const index = draft.findIndex(item => item.id === data.id)
  //             if (draft[index]) {
  //               draft[index] = {
  //                 ...draft[index],
  //                 ...data,
  //               }
  //             }
  //           }
  //         }),
  //     )
  //   },
  //   [queryClient, queryKey],
  // )

  // const deleteProductGroup = useCallback(
  //   (data: IProductGroup) => {
  //     queryClient.setQueryData<IGetProductGroupsResponse | undefined>(
  //       queryKey,
  //       oldData =>
  //         produce(oldData, draft => {
  //           if (draft) {
  //             const index = draft.findIndex(item => item.id === data.id)
  //             if (draft[index]) {
  //               draft.splice(index, 1)
  //             }
  //           }
  //         }),
  //     )
  //   },
  //   [queryClient, queryKey],
  // )

  return { addBreadcrumbLoad }
}

export default useModifyBreadcrumbLoads
