import { useEffect, useRef } from 'react'
import './styles.scss'

const SearchFieldWithCursor = props => {
  const { value, onChange, onMouseDown, onTouchEnd, onFocus, ...inputProps } =
    props

  const inputRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 300)
  }, [])

  return (
    <div className='SearchFieldWithCursor__container'>
      <input
        type='text'
        onChange={onChange}
        className='SearchFieldWithCursor__box'
        onMouseDown={onMouseDown}
        onTouchEnd={onTouchEnd}
        onFocus={onFocus}
        value={value}
        ref={inputRef}
        {...inputProps}
      />
    </div>
  )
}

export default SearchFieldWithCursor
