import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  AddressForm,
  Button,
  CloseIcon,
  ConcordFormLayout,
  ConcordFormStructure,
  EmailForm,
  FileField,
  IConcordFormField,
  IConcordFormStructureProps,
  PhoneNumberForm,
  ReusableButton,
} from '~/components/shared'

import * as Yup from 'yup'
import tzLookup from 'tz-lookup'

import { EFieldType, EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import {
  ICompany,
  ICompanyFormValues,
  ICreateCompanyPayload,
} from '~/types/models/ICompany'
import { toBase64 } from '~/utils'
import { Alert, ListGroup } from 'react-bootstrap'

import './styles.scss'
import {
  COMPANY_PAY_FREQUENCY_OPTIONS,
  CORP_TYPE_OPTIONS,
} from '~/utils/constants'
import { EEmailableType, EEmailTypes } from '~/types/enums/EEmail'
import {
  EAddressableType,
  EAddressType,
  EAddressTypeStr,
} from '~/types/enums/EAddress'
import { EPhoneableType, EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import buildFullAddress from '~/utils/buildFullAddress'
import { addCircle, closeCircle } from 'ionicons/icons'
import { IAddress, IAddressFormData } from '~/types/models/IAddress'
import _ from 'lodash'
import { IBuyerSeller } from '~/types/models/IBuyerSeller'
import {
  useQueryAddresses,
  useQueryCompanies,
  useQueryEmails,
  useQueryInitialCompanyData,
  useQueryPhoneNumbers,
  useQueryUoms,
  useQueryWorkPayTypes,
} from '~/hooks/useQueryData'
import { ERewardSettingProvider } from '~/types/enums/ERewardSetting'
import isBase64 from 'is-base64'
import { CompanyCard } from './CompanyCard'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { EBuyerSellerRelationship } from '~/types/enums/EBuyerSeller'
import { produce } from 'immer'
import { IUser } from '~/types/models/IUser'
import { IEmail, IEmailFormData } from '~/types/models/IEmail'
import { IPhoneNumber, IPhoneNumberFormData } from '~/types/models/IPhoneNumber'
import { formatPhoneNumber } from '~/utils/utils'
import isNumber from '~/utils/isNumber'
import { CompanyItemCard } from './CompanyItemCard'

export interface ICompanyFormProps
  extends Omit<
    IConcordFormStructureProps<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      ICompanyFormValues
    >,
    'fields'
  > {
  afterCreate?: (
    newCompany: ICompany,
    formData: ICompanyFormValues & {
      addressSelected?: IAddressFormData
      buyerSeller: IBuyerSeller
    },
  ) => void
  afterUpdate?: (formData: ICompany) => void
  payloadWhenCreate?: Partial<ICreateCompanyPayload>
  hiddenFields?: string[]
  canSkipAddressSetup?: boolean
  canSkipPhoneNumberSetup?: boolean
  canSkipEmailSetup?: boolean
  isSetup?: boolean
  afterModifyEmails?: () => void
}
const companyDefaultValues: ICompanyFormValues = {
  code: '',
  name: '',
  seller: false,
  buyer: false,
  fleet: false,
  email: '',
  address: undefined,
  phoneNumber: '',
  emailTypes: [EEmailTypes[EEmailTypes.general] as any],
  addressTypes: [
    EAddressType[EAddressType.mailing] as any,
    EAddressType[EAddressType.office],
  ],
  phoneTypes: [EPhoneNumberTypes[EPhoneNumberTypes.general] as any],
  addressCheck: false,
}

const CompanyForm = React.forwardRef<any, ICompanyFormProps>((props, ref) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    payloadWhenCreate,
    hiddenFields = [],
    isLoading: isLoadingProp,
    canSkipAddressSetup = true,
    // canSkipEmailSetup = true,
    // canSkipPhoneNumberSetup = true,
    isSetup,
    afterModifyEmails,
    ...formProps
  } = props

  const formRef = useRef<any>()
  const emailFormRef = useRef<any>()
  const phoneNumberFormRef = useRef<any>()
  const addressFormRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentUser: IUser = useSelector(selectSessionUser)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [companySelected, setCompanySelected] = useState<ICompany | null>(null)
  const [addressSelected, setAddressSelected] =
    useState<IAddressFormData | null | undefined>(null)
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [isCreatingPhone, setIsCreatingPhone] = useState(false)
  const [phoneFormError, setPhoneFormError] = useState('')
  const [phoneForm, setPhoneForm] = useState({
    show: false,
    formData: undefined as IPhoneNumber | undefined,
  })
  const [isCreatingEmail, setIsCreatingEmail] = useState(false)
  const [emailFormError, setEmailFormError] = useState('')
  const [emailForm, setEmailForm] = useState({
    show: false,
    formData: undefined as IEmail | undefined,
  })
  const [isCreatingAddress, setIsCreatingAddress] = useState(false)
  const [addressFormError, setAddressFormError] = useState('')
  const [addressForm, setAddressForm] = useState({
    show: false,
    formData: undefined as IAddress | undefined,
  })

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const { companyOptions, isLoadingCompaniesData } = useQueryCompanies(
    {
      all: true,
    },
    {
      enabled: !hiddenFields.includes('parentCompanyId'),
    },
  )

  const { companiesDataWithoutBuyerSeller } = useQueryCompanies(
    {
      all: true,
      enableFetchingBuyerSeller: true,
      filters: {
        searchKey: searchKey,
      },
    },
    {
      enabled: searchKey.length > 0 && !hiddenFields.includes('suggestions'),
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const companiesSearchedList =
    searchKey.length <= 0 ? [] : companiesDataWithoutBuyerSeller

  const {
    addressesData,
    isFetchedAddressData,
    refetchQueryAddresses,
    removeAddress,
    updateAddress,
    addAddress,
  } = useQueryAddresses(
    {
      filters: {
        addressableId: isUpdating ? formData?.id : companySelected?.id,
        addressableType: EAddressableType.company,
      },
    },
    { enabled: Boolean(companySelected || isUpdating) },
  )

  const {
    isEmailsFetched,
    refetchEmailsData,
    emailsData,
    removeEmail,
    addEmail,
    updateEmail,
  } = useQueryEmails(
    {
      filters: {
        emailableId: isUpdating ? formData?.id : companySelected?.id,
        emailableType: EEmailableType.company,
      },
    },
    { enabled: Boolean(companySelected || isUpdating) },
  )

  const {
    isPhoneNumbersFetched,
    phoneNumbersData,
    refetchPhoneNumbersData,
    removePhoneNumber,
    addPhoneNumber,
    updatePhoneNumber,
  } = useQueryPhoneNumbers(
    {
      filters: {
        phoneableId: isUpdating ? formData?.id : companySelected?.id,
        phoneableType: EPhoneableType.company,
      },
    },
    { enabled: Boolean(companySelected || isUpdating) },
  )

  const { timeZoneOptions, isLoadingInitialCompanyData } =
    useQueryInitialCompanyData(
      {},
      {
        enable: !hiddenFields.includes('timeZone'),
      },
    )

  const { isLoadingWorkPayTypes, workPayTypeOptions } = useQueryWorkPayTypes(
    {},
    { enabled: !hiddenFields.includes('workPayTypeId') },
  )

  const { isLoadingUoms, uomOptions } = useQueryUoms(
    {},
    { enabled: !hiddenFields.includes('uomId') },
  )

  const onCloseAddressForm = () => {
    setAddressForm({
      show: false,
      formData: undefined,
    })
  }

  const onUpdateAddress = async (formData: IAddressFormData) => {
    setIsCreatingAddress(true)
    setAddressFormError('')
    try {
      const payload: any = _.pick(formData, [
        'addressTypes',
        'address_2',
        'city',
        'country',
        'state',
        'street',
        'zip',
      ])
      const res = await apiClient.addresses.update(
        formData.id as number,
        payload,
      )
      if (res.errors?.length > 0) {
        setAddressFormError(res.errors[0])
      } else {
        updateAddress(res.id, res)
        onCloseAddressForm()
      }
    } catch (error) {
      console.log('error', error)
      setAddressFormError(toastMessages.serverError)
    } finally {
      setIsCreatingAddress(false)
    }
  }

  const onCreateAddress = async (formData: IAddressFormData) => {
    setIsCreatingAddress(true)
    setAddressFormError('')
    try {
      const payload: any = _.pick(formData, [
        'addressTypes',
        'address_2',
        'addressableId',
        'addressableType',
        'city',
        'country',
        'state',
        'street',
        'zip',
      ])
      payload.locationAttributes = formData.location
      const res = await apiClient.addresses.create(payload)
      if (res.errors?.length > 0) {
        setAddressFormError(res.errors[0])
      } else {
        addAddress(res)
        onCloseAddressForm()
      }
    } catch (error) {
      console.log('error', error)
      setAddressFormError(toastMessages.serverError)
    } finally {
      setIsCreatingAddress(false)
    }
  }

  const onCloseEmailForm = () => {
    setEmailForm({
      show: false,
      formData: undefined,
    })
  }

  const onUpdateEmail = useCallback(
    async (formData: IEmailFormData) => {
      setIsCreatingEmail(true)
      setPhoneFormError('')
      try {
        const { email, emailTypes, id } = formData
        const res = await apiClient.emails.update(id as number, {
          email: {
            email,
            emailTypes,
          },
        })
        if (res.errors?.length > 0) {
          setEmailFormError(res.errors[0])
        } else {
          updateEmail(res.id, res)
          onCloseEmailForm()
          afterModifyEmails && afterModifyEmails()
        }
      } catch (error) {
        console.log('error', error)
        setEmailFormError(toastMessages.serverError)
      } finally {
        setIsCreatingEmail(false)
      }
    },
    [afterModifyEmails, updateEmail],
  )

  const onCreateEmail = useCallback(
    async (formData: IEmailFormData) => {
      setIsCreatingEmail(true)
      setPhoneFormError('')
      try {
        const res = await apiClient.emails.create(formData)
        if (res.errors?.length > 0) {
          setEmailFormError(res.errors[0])
        } else {
          addEmail(res)
          onCloseEmailForm()
          afterModifyEmails && afterModifyEmails()
        }
      } catch (error) {
        console.log('error', error)
        setEmailFormError(toastMessages.serverError)
      } finally {
        setIsCreatingEmail(false)
      }
    },
    [addEmail, afterModifyEmails],
  )

  const onClosePhoneForm = () => {
    setPhoneForm({
      show: false,
      formData: undefined,
    })
  }

  const updatePhoneNumbers = async (values: IPhoneNumberFormData) => {
    setIsCreatingPhone(true)
    setPhoneFormError('')
    try {
      const { number, phoneTypes, id, phoneableId, phoneableType } = values
      const res = await apiClient.phoneNumbers.update(id as number, {
        number,
        phoneTypes,
        phoneableId,
        phoneableType,
      })

      if (res.errors?.length > 0) {
        setPhoneFormError(res.errors[0])
      } else {
        updatePhoneNumber(res.id, res)
        onClosePhoneForm()
      }
    } catch (error) {
      console.log('error', error)
      setPhoneFormError(toastMessages.serverError)
    } finally {
      setIsCreatingPhone(false)
    }
  }

  const createPhoneNumbers = async (values: IPhoneNumberFormData) => {
    setIsCreatingPhone(true)
    setPhoneFormError('')
    try {
      const res = await apiClient.phoneNumbers.create(values)
      if (res.errors?.length > 0) {
        setPhoneFormError(res.errors[0])
      } else {
        addPhoneNumber(res)
        onClosePhoneForm()
      }
    } catch (error) {
      console.log('error', error)
      setPhoneFormError(toastMessages.serverError)
    } finally {
      setIsCreatingPhone(false)
    }
  }

  const onClickAddress = useCallback(
    (address: any) => () => {
      setAddressSelected((prev: any) => {
        if (prev?.id === address.id) {
          return null
        }
        return address
      })
    },
    [],
  )

  const onDeletePhoneNumber = useCallback(
    (item: IPhoneNumber) => () => {
      if (typeof item.id === 'number') {
        apiClient.phoneNumbers.delete(item.id)
        removePhoneNumber(item.id)
      } else {
        const { phoneNumbersAttributes = [] } =
          formRef.current?.getValues() || {}
        const index = phoneNumbersAttributes.findIndex(
          ({ id }: any) => id === item.id,
        )
        formRef.current?.setValue(
          'phoneNumbersAttributes',
          produce(phoneNumbersAttributes, (draft: any) => {
            draft.splice(index, 1)
          }),
        )
      }
      toast.success(toastMessages.deleteSuccess)
    },
    [removePhoneNumber],
  )

  const onDeleteEmail = useCallback(
    (item: IEmail) => () => {
      if (typeof item.id === 'number') {
        apiClient.emails.delete(item.id)
        removeEmail(item.id)
      } else {
        const { emailsAttributes = [] } = formRef.current?.getValues() || {}
        const index = emailsAttributes.findIndex(
          ({ id }: any) => id === item.id,
        )
        formRef.current?.setValue(
          'emailsAttributes',
          produce(emailsAttributes, (draft: any) => {
            draft.splice(index, 1)
          }),
        )
      }
      toast.success(toastMessages.deleteSuccess)
    },
    [removeEmail],
  )

  const onDeleteAddress = useCallback(
    (item: IAddress) => () => {
      if (typeof item.id === 'number') {
        apiClient.addresses.delete(item.id)
        removeAddress(item.id)
      } else {
        const { addressesAttributes = [] } = formRef.current?.getValues() || {}
        const index = addressesAttributes.findIndex(
          ({ id }: any) => id === item.id,
        )
        formRef.current?.setValue(
          'addressesAttributes',
          produce(addressesAttributes, (draft: any) => {
            draft.splice(index, 1)
          }),
        )
      }
      toast.success(toastMessages.deleteSuccess)
    },
    [removeAddress],
  )

  const fields: IConcordFormField[] = [
    {
      name: 'general',
      label: 'General',
      fields: [
        {
          label: 'Logo',
          name: 'image',
          isDisabled: Boolean(companySelected),
          render({ label, setValue, watch, name }) {
            const logo = watch(name, null)
            return (
              <FileField
                isLoading={isImageLoading}
                label={label}
                value={[logo]}
                placeholder='Accept: image, pdf'
                onChange={([file]: File[]) => {
                  setValue(name, file)
                }}
              />
            )
          },
        },
        {
          name: 'name',
          label: 'Name',
          size: 4,
          isRequired: true,
          isDisabled: Boolean(companySelected),
          placeholder: 'Blue Concrete, Inc',
          onChange: _.debounce(({ value }) => {
            setSearchKey(value)
            formRef.current?.setValue('legalName', value)
          }, 1000),
        },
        {
          name: 'code',
          label: 'Code',
          isRequired: true,
          isDisabled: Boolean(companySelected),

          size: 4,
          onChange: _.debounce(({ value }) => {
            setSearchKey(value)
          }, 1000),
        },
        {
          label: 'Legal Name',
          name: 'legalName',
          placeholder: 'Blue Concrete, Inc',
          isRequired: true,
          size: 4,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'suggestions',
          isHidden:
            companiesSearchedList.length === 0 ||
            hiddenFields.includes('suggestions') ||
            isUpdating,
          render() {
            return (
              <ListGroup className='BuyerSellerForm__companyList'>
                <div style={{ marginBottom: 4, fontSize: 14, fontWeight: 600 }}>
                  Suggested Companies
                </div>
                {companiesSearchedList.map(company => (
                  <ListGroup.Item
                    key={company.id}
                    className='d-flex  align-items-center'
                  >
                    <CompanyCard
                      company={company}
                      isChecked={company.id === companySelected?.id}
                      onSelect={() => {
                        setCompanySelected(prev => {
                          if (prev?.id === company.id) {
                            return null
                          }
                          formRef.current?.reset({ ...company })
                          return company
                        })
                      }}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )
          },
        },
        {
          name: 'setup.owner',
          label: 'Are you an owner?',
          type: EFieldType.checkbox,
          size: 12,
          isHidden: !isSetup,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'dba',
          label: 'Dba',
          size: 4,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'ein',
          label: 'Ein',
          size: 4,
          type: EFieldType.number,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'corpType',
          label: 'Corp Type',
          size: 4,
          type: EFieldType.singleSelect,
          options: CORP_TYPE_OPTIONS,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'parentCompanyId',
          label: 'Parent Company',
          type: EFieldType.singleSelect,
          options: companyOptions,
          isLoading: isLoadingCompaniesData,
          size: 12,
          isDisabled: Boolean(companySelected),
        },
      ],
    },
    {
      name: 'contactInfo',
      label: 'Contact Info',
      fields: [
        {
          name: 'phoneNumbersAttributes',
          label: 'Phone Numbers',
          isDisabled: Boolean(companySelected),
          render({ label, watch, name, setValue }) {
            const phoneNumbersData: any[] = watch(name, [])
            const id = watch('id', null)

            return (
              <ConcordFormLayout
                label={label}
                extraIcons={[
                  {
                    icon: phoneForm.show ? closeCircle : addCircle,
                    onClick() {
                      setPhoneForm(prev => ({
                        show: !prev.show,
                        formData: undefined,
                      }))
                    },
                    color: phoneForm.show ? 'danger' : 'concord',
                  },
                ]}
              >
                {phoneForm.show && (
                  <div className='CompanyCompanyForm__container'>
                    <PhoneNumberForm
                      isHiddenSubmitButton
                      isHiddenCancelButton
                      ref={phoneNumberFormRef}
                      formData={
                        phoneForm.formData || {
                          phoneableId: id,
                        }
                      }
                      hiddenFields={['phoneableId']}
                      phoneNumberData={phoneNumbersData}
                      error={phoneFormError}
                    />
                    <div style={{ margin: '0 10px 12px' }}>
                      <ReusableButton
                        isLoading={isCreatingPhone}
                        style={{ width: '100%', fontSize: 14 }}
                        onClick={() => {
                          phoneNumberFormRef.current?.handleSubmit(
                            (values: IPhoneNumberFormData) => {
                              if (isUpdating) {
                                if (values.id) {
                                  updatePhoneNumbers(values)
                                } else {
                                  createPhoneNumbers(values)
                                }
                              } else {
                                if (values?.id) {
                                  const newData = produce(
                                    phoneNumbersData,
                                    draft => {
                                      const index = draft.findIndex(
                                        ({ id }) => id === values?.id,
                                      )
                                      if (index !== -1) {
                                        draft[index] = values
                                      }
                                    },
                                  )
                                  setValue(name, newData)
                                } else {
                                  const newItem = {
                                    ...values,
                                    id: _.uniqueId(),
                                  }
                                  setValue(name, [newItem, ...phoneNumbersData])
                                }
                                onClosePhoneForm()
                              }
                            },
                          )()
                        }}
                      >
                        {phoneForm.formData ? 'Update' : 'Create'}
                      </ReusableButton>
                    </div>
                  </div>
                )}

                <div>
                  {phoneNumbersData.map((item, index) => (
                    <CompanyItemCard
                      key={item.id}
                      index={index + 1}
                      content={`Tel: ${formatPhoneNumber(item.number)}`}
                      types={item.phoneTypes}
                      canDelete
                      canEdit
                      onClickDelete={onDeletePhoneNumber(item)}
                      showConfirmationWhenDelete={typeof item.id !== 'string'}
                      selected={item.id === phoneForm.formData?.id}
                      object={item}
                      FormComponent={PhoneNumberForm}
                      formProps={{
                        hiddenFields: ['phoneableId'],
                        afterUpdate(item: IPhoneNumber) {
                          updatePhoneNumber(item.id, item)
                        },
                      }}
                    />
                  ))}
                </div>
              </ConcordFormLayout>
            )
          },
        },
        {
          name: 'emailsAttributes',
          label: 'Emails',
          isDisabled: Boolean(companySelected),
          render({ label, watch, name, setValue }) {
            const emailsData: any[] = watch(name, [])

            return (
              <ConcordFormLayout
                label={label}
                extraIcons={[
                  {
                    icon: emailForm.show ? closeCircle : addCircle,
                    onClick() {
                      setEmailForm(prev => ({
                        show: !prev.show,
                        formData: undefined,
                      }))
                    },
                    color: emailForm.show ? 'danger' : 'concord',
                  },
                ]}
              >
                {emailForm.show && (
                  <div className='CompanyCompanyForm__container'>
                    <EmailForm
                      isHiddenSubmitButton
                      isHiddenCancelButton
                      error={emailFormError}
                      ref={emailFormRef}
                      companyEmailsData={emailsData}
                      formData={
                        emailForm.formData || {
                          emailableId: formData?.id,
                          emailableType: EEmailableType.company,
                        }
                      }
                    />
                    <div style={{ margin: '0 10px 12px' }}>
                      <ReusableButton
                        style={{ width: '100%', fontSize: 14 }}
                        isLoading={isCreatingEmail}
                        onClick={() => {
                          emailFormRef.current?.handleSubmit(
                            (values: IEmailFormData) => {
                              if (isUpdating) {
                                if (values.id) {
                                  onUpdateEmail(values)
                                } else {
                                  onCreateEmail(values)
                                }
                              } else {
                                if (values?.id) {
                                  const newData = produce(emailsData, draft => {
                                    const index = draft.findIndex(
                                      ({ id }) => id === values?.id,
                                    )
                                    if (index !== -1) {
                                      draft[index] = values
                                    }
                                  })
                                  setValue(name, newData)
                                } else {
                                  const newItem = {
                                    ...values,
                                    id: _.uniqueId(),
                                  }
                                  setValue(name, [newItem, ...emailsData])
                                }
                                onCloseEmailForm()
                              }
                            },
                          )()
                        }}
                      >
                        {emailForm.formData ? 'Update' : 'Create'}
                      </ReusableButton>
                    </div>
                  </div>
                )}

                <div>
                  {emailsData.map((item, index) => (
                    <CompanyItemCard
                      key={item.id}
                      index={index + 1}
                      content={`Email: ${item.email}`}
                      types={item.emailTypes}
                      canDelete
                      canEdit
                      onClickDelete={onDeleteEmail(item)}
                      showConfirmationWhenDelete={typeof item.id !== 'string'}
                      selected={item.id === emailForm.formData?.id}
                      FormComponent={EmailForm}
                      object={item}
                      formProps={{
                        afterUpdate(email: IEmail) {
                          updateEmail(email.id, email)
                        },
                      }}
                    />
                  ))}
                </div>
              </ConcordFormLayout>
            )
          },
        },
        {
          name: 'addressesAttributes',
          label: 'Addresses',
          isDisabled: Boolean(companySelected),
          render({ label, error, setValue, watch, name }) {
            const addressesData: any[] = watch(name, [])
            return (
              <ConcordFormLayout
                label={label}
                isRequired={!canSkipAddressSetup && !companySelected}
                extraIcons={[
                  {
                    icon: addressForm.show ? closeCircle : addCircle,
                    onClick() {
                      setAddressForm(prev => ({
                        show: !prev.show,
                        formData: undefined,
                      }))
                    },
                    color: addressForm.show ? 'danger' : 'concord',
                  },
                ]}
              >
                {error && (
                  <div className='error' style={{ fontSize: 13 }}>
                    {error}
                  </div>
                )}
                {addressForm.show && (
                  <div className='CompanyCompanyForm__container'>
                    <AddressForm
                      addressableType={EAddressableType.company}
                      isHiddenCancelButton
                      isHiddenSubmitButton
                      error={addressFormError}
                      ref={addressFormRef}
                      addressData={addressesData}
                      formData={
                        addressForm.formData || {
                          addressTypes: [EAddressTypeStr.office],
                          addressableId: formData?.id,
                          addressableType: EAddressableType.company,
                        }
                      }
                    />
                    <div style={{ margin: '0 10px 12px' }}>
                      <ReusableButton
                        style={{ width: '100%', fontSize: 14 }}
                        isLoading={isCreatingAddress}
                        onClick={() => {
                          addressFormRef.current?.handleSubmit(
                            (values: IAddressFormData) => {
                              if (isUpdating) {
                                if (values.id) {
                                  onUpdateAddress(values)
                                } else {
                                  onCreateAddress(values)
                                }
                              } else {
                                if (
                                  addressesData.length === 0 &&
                                  values.location
                                ) {
                                  const tz = tzLookup(
                                    values.location?.lat as any,
                                    values.location?.lng as any,
                                  )
                                  setValue('timeZone', tz)
                                }
                                if (values?.id) {
                                  const newData = produce(
                                    addressesData,
                                    draft => {
                                      const index = draft.findIndex(
                                        ({ id }) => id === values?.id,
                                      )
                                      if (index !== -1) {
                                        draft[index] = values
                                      }
                                    },
                                  )
                                  setValue(name, newData)
                                } else {
                                  const newItem = {
                                    ...values,
                                    id: _.uniqueId(),
                                  }
                                  setValue(name, [newItem, ...addressesData])
                                }
                                onCloseAddressForm()
                              }
                            },
                          )()
                        }}
                      >
                        {addressForm.formData ? 'Update' : 'Create'}
                      </ReusableButton>
                    </div>
                  </div>
                )}
                {addressSelected && !canSkipAddressSetup ? (
                  <Alert>
                    This address will be set as the default location for the
                    plant or terminal in the next step{' '}
                    <span
                      className='error clickable'
                      onClick={() => {
                        setAddressSelected(null)
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </Alert>
                ) : null}

                <div>
                  {addressesData.map((address, index) => (
                    <CompanyItemCard
                      object={address}
                      key={address.id}
                      index={index + 1}
                      content={buildFullAddress(address)}
                      types={address.addressTypes}
                      onClick={onClickAddress(address)}
                      canDelete
                      onClickDelete={onDeleteAddress(address as any)}
                      showConfirmationWhenDelete={
                        typeof address.id !== 'string'
                      }
                      canEdit
                      FormComponent={AddressForm}
                      formProps={{
                        addressableType: EAddressableType.company,
                        afterUpdate(address: IAddress) {
                          updateAddress(address.id, address)
                        },
                      }}
                    />
                  ))}
                </div>
              </ConcordFormLayout>
            )
          },
        },

        {
          name: 'timeZone',
          label: 'Timezone',
          type: EFieldType.singleSelect,
          options: timeZoneOptions,
          isLoading: isLoadingInitialCompanyData,
          size: 12,
          isDisabled: Boolean(companySelected),
        },
      ],
    },
    {
      name: 'payrollAndRewards',
      label: 'Payroll & Rewards',
      isHidden: formData?.id !== currentCompany?.id,
      fields: [
        {
          name: 'payFrequency',
          label: 'Pay Frequency',
          type: EFieldType.singleSelect,
          options: COMPANY_PAY_FREQUENCY_OPTIONS,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'rewardProvider',
          label: 'Reward Provider',
          isDisabled: Boolean(companySelected),

          type: EFieldType.singleSelect,
          options: Object.keys(ERewardSettingProvider).map(field => ({
            value: field,
            label: _.startCase(field),
          })),
        },

        {
          name: 'workPayTypeId',
          label: 'Work Pay Type',
          type: EFieldType.singleSelect,
          options: workPayTypeOptions,
          isLoading: isLoadingWorkPayTypes,
          isDisabled: Boolean(companySelected),
        },
        {
          name: 'uomId',
          label: 'Uom',
          type: EFieldType.singleSelect,
          options: uomOptions,
          isLoading: isLoadingUoms,
          isDisabled: Boolean(companySelected),
        },
      ],
    },
    {
      name: 'documentsAndTemplates',
      label: 'Documents & Templates',
      fields: [
        {
          label: 'Ticket Example',
          name: 'ticketExample',
          isDisabled: Boolean(companySelected),

          render({ label, setValue, watch, name }) {
            const logo = watch(name, null)
            return (
              <FileField
                label={label}
                value={[logo]}
                placeholder='Accept: image, pdf'
                onChange={([file]: File[]) => {
                  setValue(name, file)
                }}
              />
            )
          },
        },
        {
          label: 'Invoice Example',
          name: 'invoiceExample',
          isDisabled: Boolean(companySelected),

          render({ label, setValue, watch, name }) {
            const logo = watch(name, null)
            return (
              <FileField
                label={label}
                value={[logo]}
                placeholder='Accept: image, pdf'
                onChange={([file]: File[]) => {
                  setValue(name, file)
                }}
              />
            )
          },
        },
        {
          label: 'Ticket Template',
          name: 'ticketTemplate',
          isDisabled: Boolean(companySelected),
          render({ label, setValue, watch, name }) {
            const logo = watch(name, null)
            return (
              <FileField
                label={label}
                value={[logo]}
                placeholder='Accept: image, pdf'
                onChange={([file]: File[]) => {
                  setValue(name, file)
                }}
              />
            )
          },
        },
        {
          label: 'Invoice Template',
          name: 'invoiceTemplate',
          isDisabled: Boolean(companySelected),

          render({ label, setValue, watch, name }) {
            const logo = watch(name, null)
            return (
              <FileField
                label={label}
                value={[logo]}
                placeholder='Accept: image, pdf'
                onChange={([file]: File[]) => {
                  setValue(name, file)
                }}
              />
            )
          },
        },
      ],
    },
    {
      name: 'seller',
      label: 'Seller',
      type: EFieldType.checkbox,
      size: 4,
      isDisabled: Boolean(companySelected),
    },
    {
      name: 'buyer',
      label: 'Buyer',
      isDisabled: Boolean(companySelected),
      type: EFieldType.checkbox,
      size: 4,
    },
    {
      name: 'fleet',
      label: 'Fleet',
      type: EFieldType.checkbox,
      size: 4,
      isDisabled: Boolean(companySelected),
    },
  ]

  const schema = useMemo(
    () =>
      Yup.object({
        code: Yup.string().required('Code is required'),
        name: Yup.string().required('Name is required!'),
        legalName: Yup.string().required('Name is required!'),
        addressesAttributes: Yup.lazy(() => {
          if (canSkipAddressSetup || companySelected) {
            return Yup.mixed()
          }
          return Yup.array().test(
            'address-required',
            'Address is required',
            value => {
              return (value || []).length > 0
            },
          )
        }),
      }),
    [canSkipAddressSetup, companySelected],
  )

  const createCompany = useCallback(
    async (formData: ICompanyFormValues) => {
      const payload: ICreateCompanyPayload = {
        company: {
          name: formData.name,
          code: formData.code,
          legalName: formData.legalName,
          seller: formData.seller ? EYesNo.Yes : EYesNo.No,
          buyer: formData.buyer ? EYesNo.Yes : EYesNo.No,
          fleet: formData.fleet ? EYesNo.Yes : EYesNo.No,
          timeZone: formData.timeZone,
          parentCompanyId: formData.parentCompanyId,
          payFrequency: formData.payFrequency as any,
          dba: formData.dba,
          ein: formData.ein,
          corpType: formData.corpType,
          workPayTypeId: formData.workPayTypeId,
          uomId: formData.uomId,
          rewardProvider: formData.rewardProvider,
          image:
            formData.image instanceof File
              ? await toBase64(formData.image)
              : isBase64(formData.image as string)
              ? formData.image
              : undefined,
          ticketExample: formData.ticketExample
            ? await toBase64(formData.ticketExample)
            : undefined,
          invoiceExample: formData.invoiceExample
            ? await toBase64(formData.invoiceExample)
            : undefined,
          ticketTemplate: formData.ticketTemplate
            ? await toBase64(formData.ticketTemplate)
            : undefined,
          invoiceTemplate: formData.invoiceTemplate
            ? await toBase64(formData.invoiceTemplate)
            : undefined,

          setup: isSetup ? formData.setup || { owner: false } : undefined,
          addressesAttributes: formData.addressesAttributes,
          phoneNumbersAttributes: formData.phoneNumbersAttributes,
          emailsAttributes: formData.emailsAttributes,
        },
        ...payloadWhenCreate,
      }

      const { company, buyerSeller } = await apiClient.companies.create(payload)

      if (company.errors.length > 0) {
        toast.error(company.errors[0])
        setError(company.errors[0])
      } else {
        afterCreate &&
          afterCreate(company, {
            ...formData,
            buyerSeller,
            addressesAttributes: addressesData,
            addressSelected: addressSelected
              ? _.omit(addressSelected, ['id'])
              : undefined,
          })
      }
    },
    [addressSelected, addressesData, afterCreate, isSetup, payloadWhenCreate],
  )

  const updateCompany = useCallback(
    async (formValues: ICompanyFormValues) => {
      const payload: ICreateCompanyPayload = {
        company: {
          name: formValues.name,
          code: formValues.code,
          legalName: formValues.legalName,
          seller: formValues.seller ? EYesNo.Yes : EYesNo.No,
          buyer: formValues.buyer ? EYesNo.Yes : EYesNo.No,
          fleet: formValues.fleet ? EYesNo.Yes : EYesNo.No,
          timeZone: formValues.timeZone,
          parentCompanyId: formValues.parentCompanyId,
          payFrequency: formValues.payFrequency as any,
          dba: formValues.dba,
          ein: formValues.ein,
          corpType: formValues.corpType,
          workPayTypeId: formValues.workPayTypeId,
          uomId: formValues.uomId,
          rewardProvider: formValues.rewardProvider,
          image:
            formValues.image instanceof File
              ? await toBase64(formValues.image)
              : isBase64(formValues.image as string)
              ? formValues.image
              : undefined,
          ticketExample: formValues.ticketExample
            ? await toBase64(formValues.ticketExample)
            : undefined,
          invoiceExample: formValues.invoiceExample
            ? await toBase64(formValues.invoiceExample)
            : undefined,
          ticketTemplate: formValues.ticketTemplate
            ? await toBase64(formValues.ticketTemplate)
            : undefined,
          invoiceTemplate: formValues.invoiceTemplate
            ? await toBase64(formValues.invoiceTemplate)
            : undefined,
        },
      }

      const { company } = await apiClient.companies.update(
        formData?.id as number,
        payload,
      )
      if (company.errors.length > 0) {
        toast.error(company.errors[0])
        setError(company.errors[0])
      } else {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(company)
      }
    },
    [afterUpdate, formData?.id],
  )

  const createBuyerSeller = useCallback(async () => {
    setIsLoading(true)
    try {
      const { errors, ...response } = await apiClient.buyerSellers.create({
        sellerId:
          currentScope === EScope.buyer
            ? (companySelected?.id as number)
            : currentCompany.id,
        buyerId:
          currentScope === EScope.buyer
            ? currentCompany.id
            : (companySelected?.id as number),
        relationship: EBuyerSellerRelationship.Seller,
      })
      if (errors.length > 0) {
        const formEl = document.getElementById('CompanyForm__container')
        if (formEl) {
          formEl.scrollIntoView()
          setError(errors[0])
        }
        // document.getElementById('BuyerSellerForm__container')?.scrollIntoView({
        //   behavior: 'smooth',
        // })
      } else {
        toast.success(toastMessages.createSuccess)
        const company =
          currentScope === EScope.buyer ? response.seller : response.buyer
        afterCreate && afterCreate(company, { buyerSeller: response } as any)
      }
    } catch (error) {
      setError('Error happens') //
    } finally {
      setIsLoading(false)
    }
  }, [afterCreate, companySelected?.id, currentCompany.id, currentScope])

  const onCreateBuyerSeller = useCallback(() => {
    createBuyerSeller()
  }, [createBuyerSeller])

  const handleSubmit = useCallback(
    async (formData: ICompanyFormValues) => {
      setError('')
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateCompany(formData)
        } else {
          const payload = {
            ...formData,
            addressesAttributes: (formData.addressesAttributes || [])
              .filter(({ id }) => typeof id === 'string')
              .map(({ id, location, ...address }) => ({
                ...address,
                locationAttributes: location,
              })),
            phoneNumbersAttributes: (formData.phoneNumbersAttributes || [])
              .filter(({ id }) => typeof id === 'string')
              .map(({ id, ...phone }) => phone),
            emailsAttributes: (formData.emailsAttributes || [])
              .filter(({ id }) => typeof id === 'string')
              .map(({ id, ...email }) => email),
          }
          await createCompany(payload)
        }
        refetchQueryAddresses()
        refetchEmailsData()
        refetchPhoneNumbersData()
      } catch (error) {
        console.log('error', error)
        setError('Error happens') //
      } finally {
        setIsLoading(false)
      }
    },
    [
      createCompany,
      isUpdating,
      refetchEmailsData,
      refetchPhoneNumbersData,
      refetchQueryAddresses,
      updateCompany,
    ],
  )

  const fetchCompanyImage = async () => {
    setIsImageLoading(true)
    try {
      const response = await apiClient.url.getById(formData?.id as number, {
        model: 'Company',
        urlName: 'image',
        userAccessId: currentUser.userAccess?.id,
        convertToBase64: true,
      })
      if (response.base64Image) {
        formRef.current?.setValue(
          'image',
          `data:image/jpeg;base64,${response.base64Image}`,
        )
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsImageLoading(false)
    }
  }

  useEffect(() => {
    if (isFetchedAddressData && isUpdating) {
      formRef.current?.setValue(
        'addressesAttributes',
        addressesData.map(address => ({
          ...address,
          addressTypes: address.addressTypes.map(type => {
            if (typeof type === 'number') {
              return EAddressType[type]
            }
            return type
          }),
        })),
      )
    }
  }, [isFetchedAddressData, addressesData, isUpdating])

  useEffect(() => {
    if (isEmailsFetched && isUpdating) {
      formRef.current?.setValue(
        'emailsAttributes',
        emailsData.map(email => {
          return {
            ...email,
            emailTypes: email.emailTypes.map(type => {
              if (isNumber(type)) {
                return EEmailTypes[type]
              }
              return type
            }),
          }
        }),
      )
    }
  }, [isEmailsFetched, emailsData, isUpdating])

  useEffect(() => {
    if (isPhoneNumbersFetched && isUpdating) {
      formRef.current?.setValue(
        'phoneNumbersAttributes',
        phoneNumbersData.map(phone => ({
          ...phone,
          phoneTypes: phone.phoneTypes.map(type => {
            if (isNumber(type)) {
              return EPhoneNumberTypes[type]
            }
            return type
          }),
        })),
      )
    }
  }, [isPhoneNumbersFetched, isUpdating, phoneNumbersData])

  useEffect(() => {
    if (isUpdating) {
      fetchCompanyImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating])

  return (
    <div className='CompanyForm__container' id='CompanyForm__container'>
      {' '}
      {companySelected && (
        <div>
          <Alert
            variant='info'
            style={{ fontSize: 14, marginTop: 8, marginBottom: 0 }}
          >
            <span>
              You are selecting company:&nbsp;
              <span style={{ fontWeight: 600 }}>
                {`${companySelected.code} - ${companySelected.name}`}
              </span>
              <span
                style={{ marginLeft: 4 }}
                onClick={() => {
                  setCompanySelected(null)
                }}
              >
                <CloseIcon color='var(--ion-color-danger)' />
              </span>
            </span>
          </Alert>
          <div
            style={{
              padding: 8,
              border: '1px solid #e5e5e5',
              marginTop: 8,
              borderRadius: 8,
            }}
          >
            <CompanyCard
              onSelect={() => {
                setCompanySelected(null)
              }}
              company={companySelected}
              isChecked
              showExpandedDataByDefault
            />
          </div>

          <Button
            expand='full'
            style={{ marginTop: 6 }}
            onClick={onCreateBuyerSeller}
            loading={isLoading}
          >
            {`Create Relationship with ${companySelected?.name}`}
          </Button>
        </div>
      )}
      {error && (
        <Alert variant='danger' style={{ fontSize: 14, marginTop: 8 }}>
          {error}
        </Alert>
      )}
      {!companySelected && (
        <ConcordFormStructure
          {...formProps}
          ref={node => {
            if (ref) {
              formRef.current! = node
            }
            formRef.current = node
          }}
          fields={fields}
          defaultValues={companyDefaultValues}
          formData={
            formData
              ? {
                  ...formData,
                  seller:
                    typeof formData.seller === 'boolean'
                      ? formData.seller
                      : formData.seller === EYesNo.Yes,
                  buyer:
                    typeof formData.buyer === 'boolean'
                      ? formData.buyer
                      : formData.buyer === EYesNo.Yes,
                  fleet:
                    typeof formData.fleet === 'boolean'
                      ? formData.fleet
                      : formData.fleet === EYesNo.Yes,
                }
              : undefined
          }
          isHiddenCancelButton
          submitText={isUpdating ? 'Update' : 'Create'}
          onSubmit={handleSubmit}
          schema={schema}
          isLoading={isLoading || isLoadingProp}
          hiddenFields={hiddenFields}
          groupsShownByDefault={formProps.groupsShownByDefault || ['general']}
        />
      )}
    </div>
  )
})

export default CompanyForm
