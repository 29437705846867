import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import PriceEscalationForm from './PriceEscalationForm'
import { IDialogPriceEscalationFormProps } from './type'

function DialogPriceEscalationForm(props: IDialogPriceEscalationFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    disabledFields,
    readOnlyFields,
    hierarchyItem,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Price Escalation'
      isHiddenOkButton
      onClose={onClose}
    >
      <PriceEscalationForm
        hierarchyItem={hierarchyItem}
        formData={formData}
        disabledFields={disabledFields}
        readOnlyFields={readOnlyFields}
        afterCreate={(formData, keepOpenForm) => {
          afterCreate && afterCreate(formData, keepOpenForm)
          if (!keepOpenForm) {
            onClose && onClose()
          }
        }}
        afterUpdate={(formData, keepOpenForm) => {
          afterUpdate && afterUpdate(formData, keepOpenForm)
          if (!keepOpenForm) {
            onClose && onClose()
          }
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogPriceEscalationForm
