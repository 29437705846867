import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBreadcrumbLoads from './useModifyBreadcrumbLoads'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import {
  IBreadcrumbLoad,
  IBreadcrumbLoadGetListParams,
} from '~/types/models/IBreadcrumbLoad'
import { buildGetUrl } from '~/utils/buildUrl'

const useQueryBreadcrumbLoads = (
  params: IBreadcrumbLoadGetListParams & { id?: number | string },
  options?: Partial<UseQueryOptions<IBreadcrumbLoad[]>>,
) => {
  const sessionUser: IUser = useSelector(selectSessionUser)

  const {
    data = [],
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: [
      'breadcrumbLoads',
      sessionUser.id,
      buildGetUrl(apiClient.breadcrumbLoads.endpoint, params),
    ],
    async queryFn() {
      if (options?.enabled === false) {
        return []
      }
      if (params.id) {
        const response = await apiClient.breadcrumbLoads.getById(params.id)
        return response
      }
      const response = await apiClient.breadcrumbLoads.get(params)
      return response.breadcrumbLoads
    },
    ...options,
    ...DEFAULT_QUERY_OPTIONS,
  })

  const { addBreadcrumbLoad } = useModifyBreadcrumbLoads(params)

  const breadcrumbLoadsData = useMemo(() => data || [], [data])

  return {
    breadcrumbLoadsData,
    isLoadingBreadcrumbLoadsData: isLoading,
    addBreadcrumbLoad,
    isBreadcrumbLoadsFetched: isFetched,
  }
}

export default useQueryBreadcrumbLoads
