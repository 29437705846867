import { EColor } from '~/types/enums/ECommonEnum'

export const COLOR_OPTIONS = [
  { label: 'Blue', value: 'blue', color: EColor.blue },
  { label: 'Red', value: 'red', color: EColor.red },
  { label: 'Green', value: 'green', color: EColor.green },
  { label: 'Yellow', value: 'yellow', color: EColor.yellow },
  { label: 'Purple', value: 'purple', color: EColor.purple },
  { label: 'Orange', value: 'orange', color: EColor.orange },
  { label: 'Black', value: 'black', color: EColor.black },
  { label: 'Gray', value: 'gray', color: EColor.gray },
  { label: 'Cyan', value: 'cyan', color: EColor.cyan },
  { label: 'Teal', value: 'teal', color: EColor.teal },
  { label: 'Pink', value: 'pink', color: EColor.pink },
  { label: 'Magenta', value: 'magenta', color: EColor.magenta },
  { label: 'Brown', value: 'brown', color: EColor.brown },
  { label: 'Lime', value: 'lime', color: EColor.lime },
  { label: 'Indigo', value: 'indigo', color: EColor.indigo },
  { label: 'Navy', value: 'navy', color: EColor.navy },
  { label: 'Gold', value: 'gold', color: EColor.gold },
  { label: 'Custom', value: 'custom' },
]
