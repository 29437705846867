import { useState } from 'react'

import Tooltip from 'rc-tooltip'
import { Popover } from 'react-bootstrap'
import { BundleFormWithBadges } from '~/components/shared'

import './styles.scss'

export interface IBundleItemsOverlayProps {
  children: any
  sellerProductId: number
}

function BundleItemsOverlay(props: IBundleItemsOverlayProps) {
  const { children, sellerProductId } = props

  const [visible, setVisible] = useState(false)

  return (
    <Tooltip
      placement='top'
      overlay={
        <Popover className='BundleItemsOverlay__popover'>
          <Popover.Body>
            <BundleFormWithBadges sellerProductId={sellerProductId} />
          </Popover.Body>
        </Popover>
      }
      destroyTooltipOnHide
      visible={visible}
      overlayClassName='BundleItemsOverlay__overlay'
      onVisibleChange={setVisible}
      trigger={['click']}
    >
      {children}
    </Tooltip>
  )
}

export default BundleItemsOverlay
