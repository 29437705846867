import {
  useQueryCompanies,
  useQueryDocuments,
  useQueryTerminals,
} from '~/hooks/useQueryData'

import {
  CompanyInfo,
  DialogDocumentForm,
  DocumentView,
  IRTColumnDef,
  PlusIcon,
  ReusableButton,
  ReusableTable,
} from '~/components/shared'

import { EDocumentableType } from '~/types/enums/EDocument'
import { IDocument } from '~/types/models/IDocument'
import formatDateBasedOnYear from '~/utils/formatDateBasedOnYear'
import { ICompany } from '~/types/models/ICompany'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { useCallback, useState } from 'react'
import { format } from 'date-fns'
import { EScope } from '~/types/enums/ECommonEnum'
import StatusCol from '~/components/company/DocumentList/StatusCol'
import { ButtonGroup } from 'react-bootstrap'

export interface IDocumentsMiniTableProps {
  docType: EDocumentableType
  docTypeId: number | number
}

function DocumentsMiniTable(props: IDocumentsMiniTableProps) {
  const { docType, docTypeId } = props

  const [showDocumentForm, setShowDocumentForm] = useState(false)

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { findCompanyById } = useQueryCompanies({})
  const { findTerminalById } = useQueryTerminals()

  const {
    documentsData,
    isDocumentsLoading,
    isDocumentsFetching,
    refetchDocumentsData,
  } = useQueryDocuments(
    {
      filters: {
        docType,
        docTypeId,
      },
    },
    { enabled: Boolean(docTypeId) },
  )

  const formatDate = useCallback(
    (date: string) => {
      const formatDate = formatDateBasedOnYear(
        currentCompany.dateFormat || 'MMM-d yyyy (eee)',
        date,
      )
      return format(new Date(date), formatDate)
    },
    [currentCompany.dateFormat],
  )

  const onOpenDocumentForm = () => {
    setShowDocumentForm(true)
  }

  const onCloseDocumentForm = () => {
    setShowDocumentForm(false)
  }

  const afterCreateDocument = () => {
    refetchDocumentsData()
    onCloseDocumentForm()
  }

  const columns: IRTColumnDef<IDocument>[] = [
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      minSize: 130,
      maxSize: 130,
      Cell({ cell }) {
        const cellValue = cell.getValue<string>()
        return formatDate(cellValue)
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      minSize: 180,
      maxSize: 180,
    },
    {
      accessorKey: 'sellerId',
      header: 'Seller',
      minSize: 150,
      maxSize: 150,
      Cell({ row: { original } }) {
        const company = findCompanyById(original.sellerId)
        const terminal = findTerminalById(original.sellerTerminalId)

        return (
          <CompanyInfo
            company={{
              name: terminal?.name,
              code: terminal?.code,
              value: company?.id,
              label: '',
            }}
            textInChip={company?.code}
            isChip
            companyType={EScope.seller}
            hideAnchor
            searchableGoogle={false}
            hideSubtitle
            unknownText=''
          />
        )
      },
    },
    {
      accessorKey: 'buyerId',
      header: 'Buyer',
      minSize: 150,
      maxSize: 150,
      Cell({ row: { original } }) {
        const company = findCompanyById(original.buyerId)
        const terminal = findTerminalById(original.buyerTerminalId)

        return (
          <CompanyInfo
            company={{
              name: terminal?.name,
              code: terminal?.code,
              value: company?.id,
              label: '',
            }}
            textInChip={company?.code}
            isChip
            companyType={EScope.buyer}
            hideAnchor
            searchableGoogle={false}
            hideSubtitle
            unknownText=''
          />
        )
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: false,
      Cell: ({ row }) => {
        const rowData = row.original
        const { status, issueDescription, usedAt } = rowData

        return (
          <StatusCol
            status={status}
            description={issueDescription}
            usedAt={usedAt}
          />
        )
      },
      size: 135,
    },
  ]

  return (
    <div>
      <div>
        <ButtonGroup style={{ marginBottom: 8 }}>
          <ReusableButton
            tooltipContent='Create a Document'
            onClick={onOpenDocumentForm}
          >
            <PlusIcon color='white' />
          </ReusableButton>
        </ButtonGroup>
      </div>

      <ReusableTable
        tableHeight={450}
        data={documentsData}
        columns={columns}
        state={{
          isLoading: isDocumentsLoading,
          showProgressBars: isDocumentsFetching,
        }}
        renderDetailPanel={({ row }) => {
          const rowData = row.original
          return (
            <div className='DocumentsTableView__expandRow'>
              <DocumentView
                contentHeight={410}
                document={rowData.url}
                // onReloadData={() => handleReloadUrlDoc(rowData)}
                ratio={1.5}
                noHover
                extraButtons
              />
            </div>
          )
        }}
      />

      <DialogDocumentForm
        isOpen={showDocumentForm}
        onClose={onCloseDocumentForm}
        afterCreate={afterCreateDocument}
        formData={{
          docType,
          docTypeId,
        }}
        hiddenFields={[
          'docType',
          'buyerId',
          'buyerTerminalId',
          'sellerId',
          'sellerTerminalId',
        ]}
      />
    </div>
  )
}

export default DocumentsMiniTable
