import { useCallback, useMemo } from 'react'
import { Badge, Col, Container, Row } from 'react-bootstrap'
import {
  DeleteIcon,
  DropdownWithCustomChildren,
  ToggleSection,
  ToolTipOverlay,
} from '~/components/shared'
import {
  useQueryAllowedHierarchies,
  useQueryCompanies,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import {
  EAllowedHierarchyBuyerSideType,
  EAllowedHierarchySellerSideType,
} from '~/types/enums/EAllowedHierarchy'
import { IAllowedHierarchy } from '~/types/models/IAllowedHierarchy'
import { IHierarchyFormProps } from './HierarchyForm'
import { AllowedHierarchySelector } from './AllowedHierarchySelector'
import buildObjectName from '~/utils/buildObjectName'
import CustomSideTypeOption from './CustomSideTypeOption'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import _ from 'lodash'

export interface IHierarchyFieldsProps {
  id?: number
  allowedHierarchyId: number | null
  sellerSideType: EAllowedHierarchySellerSideType | null
  buyerSideType: EAllowedHierarchyBuyerSideType | null
  buyerSideId: number | null
  sellerSideId: number | null
  error?: Record<string, { message: string }>
  label?: string
  index: number
  hierarchyItem?: IHierarchyFormProps['item']
  canDelete?: boolean
  onChange?: (
    values: Partial<
      Pick<
        IHierarchyFieldsProps,
        | 'allowedHierarchyId'
        | 'sellerSideId'
        | 'buyerSideId'
        | 'buyerSideType'
        | 'sellerSideType'
      >
    >,
  ) => void
  onDelete?: (
    values: Partial<
      Pick<
        IHierarchyFieldsProps,
        | 'allowedHierarchyId'
        | 'sellerSideId'
        | 'buyerSideId'
        | 'buyerSideType'
        | 'sellerSideType'
        | 'index'
      >
    >,
  ) => void
}

function HierarchyFields(props: IHierarchyFieldsProps) {
  const {
    allowedHierarchyId,
    sellerSideId,
    buyerSideId,
    sellerSideType,
    buyerSideType,
    error,
    label,
    index,
    hierarchyItem,
    onChange,
    onDelete,
    canDelete,
  } = props

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { findAllowedHierarchyById } = useQueryAllowedHierarchies()

  const { sellerCompanyOptions, buyerCompanyOptions, findCompanyById } =
    useQueryCompanies({})
  const { sellerTerminalOptions, buyerTerminalOptions, findTerminalById } =
    useQueryTerminals()

  const allowedHierarchy = findAllowedHierarchyById(allowedHierarchyId)

  const formatTerminalOptions = useCallback(
    (terminals: any[]) => {
      const opts = terminals.map(opt => {
        const company = findCompanyById(opt.companyId)
        return {
          ...opt,
          company,
          label: company ? `${company.code} - ${opt.label}` : opt.label,
        }
      })
      return _.orderBy(opts, ['company.code', 'name'], ['asc', 'desc'])
    },
    [findCompanyById],
  )

  const sellerSideOptions = useMemo(() => {
    switch (sellerSideType) {
      case 'Seller':
        return sellerCompanyOptions
      case 'SellerTerminal':
        return formatTerminalOptions(sellerTerminalOptions)
    }
  }, [
    formatTerminalOptions,
    sellerCompanyOptions,
    sellerSideType,
    sellerTerminalOptions,
  ])

  const buyerSideOptions = useMemo(() => {
    switch (buyerSideType) {
      case 'Buyer':
        return buyerCompanyOptions
      case 'BuyerTerminal':
        return formatTerminalOptions(buyerTerminalOptions)
    }
  }, [
    buyerCompanyOptions,
    buyerSideType,
    buyerTerminalOptions,
    formatTerminalOptions,
  ])

  const getSellerSideLabel = useMemo(() => {
    if (allowedHierarchy && sellerSideId) {
      const { sellerSideType } = allowedHierarchy

      if (sellerSideType === 'Seller') {
        const obj = findCompanyById(sellerSideId)
        if (obj) {
          return buildObjectName(obj)
        }
        return '-'
      }

      if (sellerSideType === 'SellerTerminal') {
        const obj = findTerminalById(sellerSideId)
        if (obj) {
          const company = findCompanyById(obj.companyId)

          return (
            <span>
              <ToolTipOverlay content={company?.name} placement='top'>
                <Badge
                  style={{ marginRight: 4, verticalAlign: 'middle' }}
                  bg='dark'
                >
                  {company?.code}
                </Badge>
              </ToolTipOverlay>
              {buildObjectName(obj)}
            </span>
          )
        }

        return '-'
      }
    }

    if (allowedHierarchy && !sellerSideId) {
      const { sellerSideType } = allowedHierarchy
      return `Select ${_.startCase(sellerSideType)}`
    }

    return 'Select Seller Side'
  }, [allowedHierarchy, findCompanyById, findTerminalById, sellerSideId])

  const getBuyerSideLabel = useMemo(() => {
    if (allowedHierarchy && buyerSideId) {
      const { buyerSideType } = allowedHierarchy

      if (buyerSideType === 'Buyer') {
        const obj = findCompanyById(buyerSideId)
        if (obj) {
          return buildObjectName(obj)
        }
        return '-'
      }

      if (buyerSideType === 'BuyerTerminal') {
        const obj = findTerminalById(buyerSideId)
        if (obj) {
          const company = findCompanyById(obj.companyId)

          return (
            <span>
              <ToolTipOverlay content={company?.name} placement='top'>
                <Badge style={{ marginRight: 4, verticalAlign: 'middle' }}>
                  {company?.code}
                </Badge>
              </ToolTipOverlay>
              {buildObjectName(obj)}
            </span>
          )
        }

        return '-'
      }
    }

    if (allowedHierarchy && !buyerSideId) {
      const { buyerSideType } = allowedHierarchy
      return `Select ${_.startCase(buyerSideType)}`
    }

    return 'Select Buyer Side'
  }, [allowedHierarchy, buyerSideId, findCompanyById, findTerminalById])

  const getIsFieldRequired = useCallback(
    (
      allowedHierarchy: IAllowedHierarchy | undefined,
      field: keyof IAllowedHierarchy,
    ) => {
      if (allowedHierarchy) {
        const sideType = allowedHierarchy[field]
        // if (item) {
        //   const value = (item as any)[getField(sideType) as any]
        //   return Boolean(value)
        // }
        return Boolean(sideType)
      }
      return false
    },
    [],
  )

  const getSellerSideId = (data: IAllowedHierarchy) => {
    switch (data.sellerSideType) {
      case 'Seller': {
        if (hierarchyItem?.sellerId) {
          return hierarchyItem?.sellerId
        }

        if (currentScope !== EScope.buyer) {
          return currentCompany?.id
        }
        return null
      }

      case 'SellerTerminal':
        return hierarchyItem?.sellerTerminalId

      default:
        return null
    }
  }

  const getBuyerSideId = (data: IAllowedHierarchy) => {
    switch (data.buyerSideType) {
      case 'Buyer': {
        if (hierarchyItem?.buyerId) {
          return hierarchyItem?.buyerId
        }
        if (currentScope === EScope.buyer) {
          return currentCompany?.id
        }
        return null
      }

      case 'BuyerTerminal':
        return hierarchyItem?.buyerTerminalId

      default:
        return null
    }
  }

  return (
    <ToggleSection
      label={label || 'Details'}
      badges={[
        {
          label: index + 1,
        },
        {
          label: <DeleteIcon color='var(--bs-danger)' />,
          color: 'none',
          className: 'clickable',
          isHidden: !canDelete,
          onClick() {
            onDelete &&
              onDelete({
                allowedHierarchyId,
                sellerSideId,
                buyerSideId,
                sellerSideType,
                buyerSideType,
                index,
              })
          },
        },
      ]}
      isOpenByDefault
      style={{
        borderTop: '1px dashed rgb(168 168 168)',
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <Container style={{ padding: 0 }}>
        <Row>
          <Col>
            <AllowedHierarchySelector
              hierarchyItem={hierarchyItem}
              value={allowedHierarchyId}
              onChange={(event, { value, selectedOption }) => {
                onChange &&
                  onChange({
                    allowedHierarchyId: value,
                    sellerSideType: selectedOption.sellerSideType,
                    buyerSideType: selectedOption.buyerSideType,
                    buyerSideId: getBuyerSideId(selectedOption),
                    sellerSideId: getSellerSideId(selectedOption),
                  })
              }}
              error={error?.allowedHierarchyId?.message}
            />
          </Col>
        </Row>

        {allowedHierarchy && (
          <Row>
            <Col>
              <div className='AllowedHierarchySelector__labelGrid'>
                <DropdownWithCustomChildren
                  options={sellerSideOptions as any}
                  onChange={(event, { value }) => {
                    onChange && onChange({ sellerSideId: value })
                  }}
                  value={sellerSideId}
                  className='make-custom-dropdown-inline no-hover'
                  isRequired={getIsFieldRequired(
                    allowedHierarchy,
                    'sellerSideType',
                  )}
                  error={error?.sellerSideId?.message}
                  isReadOnly={Boolean(hierarchyItem)}
                  components={{
                    Option: CustomSideTypeOption,
                  }}
                >
                  <div style={{ width: 210 }}>{getSellerSideLabel}</div>
                </DropdownWithCustomChildren>

                {allowedHierarchy.buyerSideType && (
                  <DropdownWithCustomChildren
                    isRequired={getIsFieldRequired(
                      allowedHierarchy,
                      'buyerSideType',
                    )}
                    options={buyerSideOptions as any}
                    value={buyerSideId}
                    onChange={(event, { value }) => {
                      onChange && onChange({ buyerSideId: value })
                    }}
                    error={error?.buyerSideId?.message}
                    isReadOnly={Boolean(hierarchyItem)}
                    className='make-custom-dropdown-inline no-hover'
                    components={{
                      Option: CustomSideTypeOption,
                    }}
                  >
                    <div style={{ width: 210 }}>| {getBuyerSideLabel}</div>
                  </DropdownWithCustomChildren>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </ToggleSection>
  )
}

export default HierarchyFields
