import { useEffect, useCallback, useMemo, useState } from 'react'
import { Controller, useController, useWatch } from 'react-hook-form'
import clsx from 'clsx'
import { When } from 'react-if'
import { IonText } from '@ionic/react'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { Badge, Button, Form, Stack, Collapse } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import { useDebounceCallback } from '@react-pdf-viewer/core'

import { ScheduleLoadsTable } from './ScheduleLoadsTable'
import { GraphChartOrderTrackingDetailDialog } from '~/containers/OrderTracking/OrderTrackingDetailColumn/GraphChartOrderTrackingDetailDialog'
import { apiClient } from '~/api/ApiClient'
import { FloatingInput } from '../FloatingForm/Input'
import QtyCombinedInput from './QtyCombinedInput'
import { RouteCard, useRouteTerminal } from '../RouteCard.js/RouteCard'
import { ToolTipOverlay } from '../ToolTipOverlay'
import { HookFormTextToggleInput } from '../TextToggleInput/HookFormTextToggleInput'
import { DialogCompanyForm, DialogTerminalForm } from '../ConcordForm'
import { errorStyle } from '~/components/shared/ReactSelect/components/selectUtils'
import { ExtrasForm } from '../OrderForm/Extras/ExtrasForm'
import {
  GraphIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
  InfoIcon,
  PlusIcon,
  BucketIcon,
  ReusableDatePicker,
  ReusableTimeField,
  ArrowBackIcon,
  ArrowForwardIcon,
} from '~/components/shared'
import { ScheduleLoadsOrderOverrides } from './Override/OrderOverrides'
import {
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQueryTerminals,
  useQueryUoms,
} from '~/hooks/useQueryData'
import { useWatchRow } from './useWatchRow'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'
import { useSelectOptionsCombinedBuyerAndSellerProducts } from '~/hooks/useSelectOptionsCombinedBuyerAndSellerProducts'

import './styles.scss'

const SCHEDULE = 'schedule'

export const ScheduleLoadsRow = ({
  index,
  control,
  errors,
  setValue,
  isReadOnly,
  onChange,
  isShowScheduleLoadsByDefault,
  appendSchedule,
  deleteSchedule,
  hiddenButtons,
  setIsFetchingScheduleLoads,
  sellerTerminalOptions,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [isOpenGraphDialog, setIsOpenGraphDialog] = useState(false)
  const [showRouteCard, setShowRouteCard] = useState(false)
  const [terminalForm, setTerminalForm] = useState({ isOpen: false })
  const [companyForm, setCompanyForm] = useState({ isOpen: false })
  const [showOverrides, setShowOverrides] = useState(false)
  const [enableOverrides, setEnableOverrides] = useState(false)
  const [overridesInfo, setOverridesInfo] = useState({ count: 0 })
  const [showExtras, setShowExtras] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)

  const {
    field: { onChange: onChangeNotes },
  } = useController({
    name: `schedulesAttributes[${index}].notesAttributes.0.note`,
    control,
    defaultValue: '',
  })

  const findSuitableLoadSize = useCallback(
    orderQty => {
      const { loadSizeArray = [] } = currentCompany
      if (loadSizeArray.length === 0) {
        return ''
      }
      for (let size of loadSizeArray) {
        if (orderQty % size === 0) {
          return size
        }
      }
      return loadSizeArray[0]
    },
    [currentCompany],
  )

  const { currentUom } = useQueryUoms()
  const { findCompanyById } = useQueryCompanies({})
  const { findTerminalById, updateTerminal } = useQueryTerminals()

  const {
    buyerId,
    sellerId,
    buyerTerminalId,
    sellerTerminalId,
    orderId,
    orderSellerTerminalId,
    orderFleetId,
    buyerSellerProductId,
    fleetId,
    date,
    startTime,
    spacing,
    qty,
    loadSize,
    plus,
    scheduleId,
    schedule,
    scheduleLoads,
    allSchedules,
  } = useWatchRow({ control, index })

  const terminalData = findTerminalById(
    sellerTerminalId || orderSellerTerminalId,
  )

  const extras = useWatch({
    control,
    name: `schedulesAttributes[${index}].orderExtrasAttributes`,
  })
  const watchDate = useWatch({
    control,
    name: `schedulesAttributes[${index}].date`,
  })

  const { buyerSellerProductOptions } =
    useSelectOptionsCombinedBuyerAndSellerProducts({
      buyerId,
      sellerId,
      orderTypes: [0, 2],
    })

  const schedulePrefix = useMemo(
    () => `schedulesAttributes[${index}].`,
    [index],
  )

  const isUpdating = useMemo(() => Boolean(scheduleId), [scheduleId])

  const isAddScheduleBtnShown = useMemo(() => {
    if (hiddenButtons.includes('addSchedule')) return false
    if (!appendSchedule) return false
    return true
  }, [appendSchedule, hiddenButtons])

  const fetchScheduleLoads = useCallback(async () => {
    setIsFetchingScheduleLoads(true)
    try {
      const [hours, minutes] = startTime.split(':')
      const res = await apiClient.scheduleLoads.createNew({
        order: {
          buyerTerminalId,
          buyerId,
          sellerId,
          buyerSellerProductId: buyerSellerProductId,
          schedulesAttributes: [
            {
              startTime: moment(date).set({ hours, minutes }).toISOString(),
              spacing: spacing,
              sellerTerminalId: sellerTerminalId || orderSellerTerminalId,
              fleetId: fleetId || orderFleetId,
              qty: parseFloat(qty),
              loadSize: parseFloat(loadSize),
              plus: plus,
            },
          ],
        },
      })
      setValue(
        `schedulesAttributes[${index}].scheduleLoadsAttributes`,
        res.schedules[0].scheduleLoads,
      )
      setShowTable(true)
    } catch (e) {
      console.log(e)
    } finally {
      setIsFetchingScheduleLoads(false)
    }
  }, [
    buyerTerminalId,
    buyerId,
    sellerId,
    buyerSellerProductId,
    date,
    startTime,
    spacing,
    sellerTerminalId,
    orderSellerTerminalId,
    fleetId,
    orderFleetId,
    qty,
    loadSize,
    plus,
    setValue,
    index,
    setIsFetchingScheduleLoads,
  ])

  const debouncedFetchScheduleLoads = useDebounceCallback(
    fetchScheduleLoads,
    500,
  )

  const { findBuyerSellerProductById } = useQueryBuyerSellerProducts(
    {
      buyerId: buyerId,
      sellerId: sellerId,
      filters: {
        joinsSellerProduct: true,
        orderTypes: [0, 2],
      },
    },
    { enabled: Boolean(buyerId && sellerId) },
  )

  useEffect(() => {
    if (
      buyerTerminalId &&
      startTime &&
      date &&
      spacing &&
      (sellerTerminalId || orderSellerTerminalId) &&
      buyerId &&
      sellerId &&
      (fleetId || orderFleetId) &&
      buyerSellerProductId &&
      !isUpdating
    )
      debouncedFetchScheduleLoads()
  }, [
    qty,
    loadSize,
    sellerId,
    startTime,
    date,
    spacing,
    fleetId,
    orderFleetId,
    sellerTerminalId,
    orderSellerTerminalId,
    buyerTerminalId,
    index,
    setValue,
    isUpdating,
    plus,
    buyerId,
    buyerSellerProductId,
    debouncedFetchScheduleLoads,
  ])

  const onSetQty = useCallback(() => {
    const suitableLoadSize = findSuitableLoadSize(qty)
    const maxLoadSize =
      suitableLoadSize && terminalData?.maxLoadSize
        ? suitableLoadSize <= terminalData?.maxLoadSize
          ? suitableLoadSize
          : terminalData?.maxLoadSize
        : suitableLoadSize || terminalData?.maxLoadSize
    if (qty) {
      setValue(`schedulesAttributes[${index}].loadSize`, maxLoadSize)
    } else {
      setValue(`schedulesAttributes[${index}].loadSize`, null)
    }
  }, [terminalData?.maxLoadSize, qty, setValue, index, findSuitableLoadSize])

  const getScheduleLoads = useCallback(
    schedules => {
      if (isUpdating) {
        const index = schedules.findIndex(({ id }) => id === scheduleId)
        return schedules[index].scheduleLoads
      }
      return schedules[0].scheduleLoads
    },
    [isUpdating, scheduleId],
  )

  const updateScheduleLoads = useCallback(
    async ({ row, field, value }) => {
      const nextScheduleLoads = produce(scheduleLoads, draft => {
        draft[row][field] = value
      })

      try {
        const [hours, minutes] = startTime.split(':')

        const res = await apiClient.scheduleLoads.createNew({
          order: {
            buyerTerminalId,
            id: orderId,
            schedulesAttributes: [
              {
                id: scheduleId,
                startTime: moment(date).set({ hours, minutes }).toISOString(),
                spacing: parseFloat(spacing),
                sellerTerminalId: sellerTerminalId || orderSellerTerminalId,
                fleetId: fleetId,
                qty: parseFloat(qty),
                loadSize: parseFloat(loadSize),
                plus: plus,
                scheduleRowChanged: row,
                scheduleLoadAttributeChanged: field,
                scheduleLoadsAttributes: nextScheduleLoads,
              },
            ],
          },
        })
        const newScheduleLoads = getScheduleLoads(res.schedules)
        setValue(
          `schedulesAttributes.${0}.scheduleLoadsAttributes`,
          newScheduleLoads,
        )
        onChange &&
          onChange({
            field: 'scheduleLoadsAttributes',
            value: newScheduleLoads,
          })
      } catch (e) {
        console.log(e)
      }
    },
    [
      scheduleLoads,
      buyerTerminalId,
      date,
      startTime,
      spacing,
      sellerTerminalId,
      orderSellerTerminalId,
      fleetId,
      qty,
      loadSize,
      plus,
      setValue,
      orderId,
      scheduleId,
      onChange,
      getScheduleLoads,
    ],
  )

  const initDate = new Date()
  const futureDate = initDate.getDate() + 1
  initDate.setDate(futureDate)

  const totalLoadSize = useMemo(() => {
    if (scheduleLoads.length) {
      return scheduleLoads.length
    }
    if (plus) return Math.ceil(qty / loadSize) + 1
    return Math.ceil(qty / loadSize)
  }, [loadSize, plus, qty, scheduleLoads.length])

  const onOpenGraphDialog = useCallback(() => {
    setIsOpenGraphDialog(true)
  }, [])

  const onCloseGraphDialog = useCallback(() => {
    setIsOpenGraphDialog(false)
  }, [])

  const { latLng: buyerLatLng } = useRouteTerminal(buyerTerminalId)
  const { latLng: sellerLatLng } = useRouteTerminal(
    sellerTerminalId || orderSellerTerminalId,
  )

  const onCreateTerminal = useCallback(
    terminal => {
      setValue(`schedulesAttributes[${index}].sellerTerminalId`, terminal.id)
      updateTerminal(terminal.id, terminal)
      setTerminalForm({ isOpen: false })
    },
    [setValue, index, updateTerminal],
  )

  const autoExtrasParams = useMemo(() => {
    if (!_.isNumber(buyerSellerProductId)) return null

    return {
      filters: {
        applicationType: SCHEDULE,
        for_date_time: new Date(date).toISOString(),
      },
    }
  }, [buyerSellerProductId, date])

  useEffect(() => {
    if (allSchedules.length > 1) setEnableOverrides(true)
  }, [allSchedules])

  const overridesTooltip = useMemo(() => {
    return (
      <div>
        {overridesInfo?.sellerTerminalId && (
          <p className='p-0 m-0'> {`Seller Terminal: ${terminalData?.name}`}</p>
        )}
        {overridesInfo.fleetId && (
          <p className='p-0 m-0'>
            {' '}
            {`Fleet: ${findCompanyById(overridesInfo.fleetId)?.name}`}
          </p>
        )}
        {overridesInfo.buyerSellerProductId && (
          <p className='p-0 m-0'>
            {`Product:
        ${
          findBuyerSellerProductById(overridesInfo.buyerSellerProductId)?.name
        }`}
          </p>
        )}
        {overridesInfo.status && (
          <p className='p-0 m-0'> {`Status: ${overridesInfo.status}`}</p>
        )}
        {overridesInfo.color && (
          <p className='p-0 m-0'> {`Color: ${overridesInfo.color}`}</p>
        )}
      </div>
    )
  }, [
    findBuyerSellerProductById,
    findCompanyById,
    overridesInfo.buyerSellerProductId,
    overridesInfo.color,
    overridesInfo.fleetId,
    overridesInfo?.sellerTerminalId,
    overridesInfo.status,
    terminalData?.name,
  ])

  const nextDate = () => {
    const date = new Date(watchDate)
    date.setDate(date.getDate() + 1)
    return date.toISOString().split('T')[0]
  }

  const lastDate = () => {
    const date = new Date(watchDate)
    date.setDate(date.getDate() - 1)
    return date.toISOString().split('T')[0]
  }

  return (
    <>
      <div className='d-flex'>
        <div className='d-flex flex-wrap align-items-center gap-1'>
          <ScheduleCount
            index={index}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            show={allSchedules.length > 1}
          />

          {isCollapsed && (
            <Stack
              direction='horizontal'
              gap={2}
              className={isCollapsed ? 'fs-4' : 'd-none'}
            >
              <HookFormTextToggleInput
                name={`schedulesAttributes[${index}].qty`}
                control={control}
                type='number'
                label='Qty: '
                text={qty}
              />

              <HookFormTextToggleInput
                name={`schedulesAttributes[${index}].loadSize`}
                control={control}
                type='number'
                label={
                  currentUom ? `LD Size(${currentUom?.code || ''}):` : 'LD Size'
                }
                text={loadSize}
              />

              {qty && loadSize && (
                <Badge pill>
                  LDs: {Math.ceil(qty / loadSize) + (plus ? 1 : 0)}
                </Badge>
              )}

              <HookFormTextToggleInput
                name={`schedulesAttributes[${index}].date`}
                control={control}
                type='date'
                text={DateTime.fromISO(date).toFormat('M/d')}
              />

              <HookFormTextToggleInput
                name={`schedulesAttributes[${index}].startTime`}
                control={control}
                type='time'
                text={DateTime.fromISO(startTime).toFormat('ccc, H:mm')}
              />

              <HookFormTextToggleInput
                name={`schedulesAttributes[${index}].spacing`}
                control={control}
                type='number'
                label='Spacing: '
                text={spacing}
              />
            </Stack>
          )}

          {!isCollapsed && (
            <>
              <QtyCombinedInput
                control={control}
                index={index}
                onBlurQty={onSetQty}
              />

              <ToolTipOverlay
                content={`${terminalData?.name} Max LD Size: ${terminalData?.maxLoadSize} ${currentUom?.code}`}
              >
                <FloatingInput
                  control={control}
                  inputType='number'
                  label={
                    currentUom ? `LD Size(${currentUom?.code}):` : 'LD Size:'
                  }
                  name={`schedulesAttributes[${index}].loadSize`}
                  isReadOnly={isReadOnly}
                  style={{ height: 41 }}
                />
              </ToolTipOverlay>

              <div className='d-flex align-items-center'>
                <Controller
                  name={`schedulesAttributes[${index}].plus`}
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      id={`plusLoad-${index}`}
                      type='switch'
                      label={
                        field.value ? (
                          <IonText color='buyer' style={{ cursor: 'pointer' }}>
                            Plus Load
                          </IonText>
                        ) : (
                          <IonText
                            color='fleet'
                            style={{ opacity: 0.85, cursor: 'pointer' }}
                          >
                            Add Plus Load
                          </IonText>
                        )
                      }
                      checked={Boolean(field.value)}
                      onChange={() => {
                        const selected = !field.value
                        field.onChange(selected)
                        onChange &&
                          onChange({
                            field: `schedulesAttributes[${index}].plus`,
                            value: selected,
                          })
                      }}
                      style={{
                        borderColor:
                          errors?.schedulesAttributes?.[index]?.plus && 'red',
                      }}
                      className='green-switch-toggle fs-4 ms-2'
                    />
                  )}
                />
              </div>

              <When condition={Boolean(qty && loadSize)}>
                <div
                  className='d-flex align-items-center'
                  style={{ marginLeft: 6, fontSize: 14 }}
                >
                  <span style={{ fontWeight: 600 }}>LDs:</span>&nbsp;
                  <span>{totalLoadSize}</span>
                </div>
              </When>

              <Button
                variant='outline-secondary'
                onClick={() => {
                  setValue(`schedulesAttributes[${index}].date`, lastDate())
                }}
                tabindex='-1'
              >
                <ArrowBackIcon size={8} />
              </Button>

              <Controller
                name={`schedulesAttributes[${index}].date`}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <ReusableDatePicker
                      yearRange={[
                        moment().year(),
                        moment().add({ years: 1 }).year(),
                      ]}
                      className={clsx(
                        { isReadOnly },
                        error && errorStyle,
                        'ScheduleLoadsRow__datePicker',
                      )}
                      value={field.value}
                      onChange={date => {
                        field.onChange(date)
                        onChange &&
                          onChange({
                            field: `schedulesAttributes[${index}].date`,
                            value: date,
                          })
                      }}
                      dateFormat='MMM-d yyyy (eee)'
                    />
                  )
                }}
              />

              <Button
                variant='outline-secondary'
                onClick={() => {
                  setValue(`schedulesAttributes[${index}].date`, nextDate())
                }}
                tabindex='-1'
              >
                <ArrowForwardIcon size={8} />
              </Button>

              <Controller
                name={`schedulesAttributes[${index}].startTime`}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  const [hours = 0, minutes = 0] = (field.value || '').split(
                    ':',
                  )

                  const dateTime = moment(watchDate)
                    .set({
                      hours,
                      minutes,
                    })
                    .toDate()
                  return (
                    <ReusableTimeField
                      className={clsx(
                        {
                          isReadOnly,
                        },
                        error && errorStyle,
                        'ScheduleLoadsRow__timeField',
                      )}
                      value={dateTime}
                      onChange={date => {
                        const selected = moment(date).format('HH:mm')
                        field.onChange(selected)
                        onChange &&
                          onChange({
                            field: `schedulesAttributes[${index}].startTime`,
                            value: selected,
                          })
                      }}
                    />
                  )
                }}
              />

              <FloatingInput
                control={control}
                inputType='number'
                label='Spacing (mins)'
                name={`schedulesAttributes[${index}].spacing`}
                isReadOnly={isReadOnly}
                style={{ maxWidth: 105, height: 41 }}
              />
            </>
          )}

          {buyerTerminalId && (sellerTerminalId || orderSellerTerminalId) && (
            <ToolTipOverlay content='View Route'>
              <div
                className='d-flex align-items-center'
                onClick={() => setShowRouteCard(!showRouteCard)}
              >
                <InfoIcon
                  color={buyerLatLng && sellerLatLng ? '#0d6efd' : '#ff2f0f'}
                  className='fs-3 pointer'
                />
              </div>
            </ToolTipOverlay>
          )}

          <When condition={allSchedules.length > 1}>
            <NoteButton
              onSave={onChangeNotes}
              canEdit
              saveButtonText='Save Schedule Note'
              renderNoteTooltip={({ note }) =>
                `Schedule Note - Schedules notes only apply to this schedule\n ${note}`
              }
            />
          </When>

          <ScheduleLoadsOrderOverrides
            control={control}
            index={index}
            buyerId={buyerId}
            sellerId={sellerId}
            isShowOverrides={showOverrides}
            isCollapsed={isCollapsed}
            onChangeInfo={setOverridesInfo}
            sellerTerminalOptions={sellerTerminalOptions}
            buyerSellerProductOptions={buyerSellerProductOptions}
          />
        </div>

        <div className='ms-auto d-flex gap-1 '>
          <When condition={scheduleLoads.length}>
            <div>
              <ToolTipOverlay
                content={`${showTable ? 'Hide' : 'Show'} Schedule Loads`}
              >
                <Button
                  size='lg'
                  className='d-flex align-items-center'
                  onClick={() => setShowTable(!showTable)}
                >
                  {showTable ? (
                    <ArrowUpIcon color='#fff' />
                  ) : (
                    <ArrowDownIcon color='#fff' />
                  )}
                  &#8203;
                </Button>
              </ToolTipOverlay>
            </div>
          </When>

          <When condition={scheduleLoads.length}>
            <div>
              <ToolTipOverlay content='Graph'>
                <Button
                  size='lg'
                  className='d-flex align-items-center'
                  onClick={onOpenGraphDialog}
                  disabled={scheduleLoads.length === 0}
                >
                  <GraphIcon color='#fff' />
                  &#8203;
                </Button>
              </ToolTipOverlay>
            </div>
          </When>

          <When condition={allSchedules.length > 1}>
            <div>
              <ToolTipOverlay content='Delete schedule'>
                <Button
                  size='lg'
                  variant='danger'
                  className='d-flex align-items-center'
                  onClick={() => deleteSchedule(index)}
                >
                  <DeleteIcon color='#fff' />
                  &#8203;
                </Button>
              </ToolTipOverlay>
            </div>
          </When>

          <When condition={allSchedules.length > 1}>
            <div>
              <ToolTipOverlay content='Schedule extras'>
                <Button
                  size='lg'
                  className='d-flex align-items-center'
                  onClick={() => setShowExtras(!showExtras)}
                >
                  <BucketIcon color='#fff' />
                  {extras?.length}
                </Button>
              </ToolTipOverlay>
            </div>
          </When>

          {isAddScheduleBtnShown && (
            <div>
              <ToolTipOverlay content='Add schedule'>
                <Button
                  size='lg'
                  className='d-flex align-items-center'
                  onClick={appendSchedule}
                >
                  <PlusIcon color='#fff' />
                  &#8203;
                </Button>
              </ToolTipOverlay>
            </div>
          )}

          {enableOverrides && (
            <div>
              <ToolTipOverlay content={overridesTooltip}>
                <Button
                  size='lg'
                  onClick={() => setShowOverrides(!showOverrides)}
                  className='d-flex align-items-center'
                  variant={overridesInfo.count ? 'primary' : 'outline-primary'}
                >
                  <span>Overrides&nbsp;</span>
                  {overridesInfo.count > 0 && (
                    <Badge
                      bg='secondary'
                      pill
                      className='d-flex align-items-center'
                    >
                      {overridesInfo.count}
                    </Badge>
                  )}
                </Button>
              </ToolTipOverlay>
            </div>
          )}
        </div>
      </div>

      {showRouteCard && (
        <div className={isCollapsed && 'd-none'}>
          <RouteCard
            startTerminalId={sellerTerminalId || orderSellerTerminalId}
            endTerminalId={buyerTerminalId}
          />
        </div>
      )}

      <Collapse in={showTable || isShowScheduleLoadsByDefault}>
        <div
          className={isCollapsed && 'd-none'}
          style={{
            maxHeight: 200,
            overflowY: 'scroll',
            border: '1px solid #e5e5e5',
            marginTop: 4,
            borderRadius: 4,
          }}
        >
          <ScheduleLoadsTable
            control={control}
            index={index}
            schedule={schedule}
            sellerId={sellerId}
            scheduleLoads={scheduleLoads}
            updateScheduleLoads={updateScheduleLoads}
            isReadOnly={isReadOnly}
          />
        </div>
      </Collapse>

      <div className='mb-1' />

      <ExtrasForm
        prefix={schedulePrefix}
        control={control}
        autoExtrasParams={autoExtrasParams}
        buyerId={buyerId}
        sellerId={sellerId}
        setValue={setValue}
        name='Schedule'
        show={showExtras}
        className='border border-light rounded'
      />

      <GraphChartOrderTrackingDetailDialog
        isOpen={isOpenGraphDialog}
        orderIds={[orderId]}
        onClose={onCloseGraphDialog}
        isHiddenOrderNumColumn
      />

      <DialogTerminalForm
        isOpen={terminalForm.isOpen}
        formData={terminalForm?.formData}
        onClose={() => setTerminalForm({ isOpen: false })}
        shouldHideOptionalFields={terminalForm.shouldHideOptionalFields}
        afterCreate={onCreateTerminal}
      />

      <DialogCompanyForm
        isOpen={companyForm.isOpen}
        formData={companyForm?.formData}
        onClose={() => setCompanyForm({ isOpen: false })}
      />
    </>
  )
}

const ScheduleCount = ({ index, setIsCollapsed, isCollapsed, show = true }) => {
  return (
    <div className='d-flex gap-2 align-items-center fs-3'>
      {show && (
        <span>
          <b>#&nbsp;{index ? index + 1 : 1}</b>
        </span>
      )}
      <Form.Check
        type='switch'
        id='custom-switch'
        checked={isCollapsed}
        onChange={() => setIsCollapsed(!isCollapsed)}
        tabindex='-1'
      />
    </div>
  )
}
