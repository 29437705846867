import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import {
  IGetDocumentsParams,
  IGetDocumentsResponse,
} from '~/types/models/IDocument'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryDocuments = (
  params: IGetDocumentsParams = {},
  options?: Partial<UseQueryOptions<IGetDocumentsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [
      'documents',
      sessionUser?.id,
      buildGetUrl(apiClient.documents.endpoint, params),
    ],
    async queryFn() {
      if (options?.enabled === false) {
        return {
          count: 0,
          countOnPage: 1,
          documents: [],
          endRange: 1,
          page: 1,
          pageCount: 1,
          perPage: 24,
          startRange: 1,
        }
      }
      const response = await apiClient.documents.get(params)
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const documentsData = useMemo(() => data?.documents || [], [data])

  const totalDocumentsData = data?.count || 0

  return {
    documentsData,
    isDocumentsLoading: isLoading,
    totalDocumentsData,
    isDocumentsFetching: isFetching,
    refetchDocumentsData: refetch,
  }
}

export default useQueryDocuments
