import React, { useCallback, useMemo, useState, useEffect } from 'react'

import {
  ConcordFormStructure,
  ConcordFormAddressInput,
  CheckMarkIcon,
} from '~/components/shared'

import { ADDRESSABLE_TYPE, ADDRESS_TYPE_OPTIONS } from '~/utils/constants'
import * as Yup from 'yup'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { convertAddressTypeFromEnumToString } from '~/utils/getAddressTypes'
import buildFullAddress from '~/utils/buildFullAddress'

const AddressForm = (props, ref) => {
  const {
    addressableId,
    addressableType = ADDRESSABLE_TYPE.company,
    addressData = [],
    afterCreate,
    afterUpdate,
    formData,
    className,
    canSkipCreatingOnBackend,
    onSubmit,
    ...formProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [wasAddressSelected, setWasAddressSelected] = useState(false)
  const [isManualTyping, setIsManualTyping] = useState(false)
  const [backendError, setBackendError] = useState('')

  const defaultValues = useMemo(
    () => ({
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      addressTypes: [],
      searchAddress: '',
    }),
    [],
  )

  const mapFormData = useMemo(() => {
    if (formData) {
      let addressTypes = (formData.addressTypes || []).map(addressType => {
        if (typeof addressType === 'number') {
          return convertAddressTypeFromEnumToString(addressType)
        }
        return addressType
      })

      return { ...formData, addressTypes }
    }
  }, [formData])

  const isUpdating = useMemo(() => mapFormData?.id, [mapFormData?.id])

  const addressTypeOptions = useMemo(() => {
    const DEFAULT_OPTIONS = ADDRESS_TYPE_OPTIONS.filter(
      ({ isDriverOption, isCompanyOption }) => {
        if (addressableType === ADDRESSABLE_TYPE.company) {
          return isCompanyOption
        }

        return isDriverOption
      },
    )

    let mapOptions = DEFAULT_OPTIONS.filter(({ value, numValue }) => {
      const filteredAddressTypes = addressData.filter(
        ({ addressTypes }) =>
          addressTypes.includes(value) || addressTypes.includes(numValue),
      )

      return filteredAddressTypes.length === 0
    })

    if (isUpdating) {
      const filterOptions = DEFAULT_OPTIONS.filter(({ value }) =>
        mapFormData?.addressTypes?.includes(value),
      )

      mapOptions = [...mapOptions, ...filterOptions]
    }

    return mapOptions
  }, [addressData, addressableType, mapFormData?.addressTypes, isUpdating])

  const fields = useMemo(
    () => [
      {
        label: 'Search address',
        name: 'searchAddress',
        type: EFieldType.custom,
        size: 12,
        render({ setValue, watch, clearErrors, name, error }) {
          const searchAddress = watch(name)

          return (
            <ConcordFormAddressInput
              label='Search Address'
              value={searchAddress}
              error={error}
              autoFocus
              isShowedByDefault={!isManualTyping}
              onClickKeyboardIcon={() => {
                setIsManualTyping(true)
                setWasAddressSelected(true)
              }}
              onClickSearchIcon={() => {
                setIsManualTyping(false)
                setWasAddressSelected(false)
              }}
              onChange={(selectedOption, { address, location }) => {
                setWasAddressSelected(true)
                const searchAddress = buildFullAddress(address)
                Object.keys(address).forEach(key => {
                  if (key === 'postalCode') {
                    setValue('zip', address.postalCode)
                  } else {
                    setValue(key, address[key])
                  }
                  setValue(name, searchAddress)
                  setValue('address_2', '')
                  setValue('location', location)
                  clearErrors([
                    'street',
                    'city',
                    'state',
                    'zip',
                    'country',
                    'addressTypes',
                  ])
                })
              }}
              className='CreateUpdateAddressForm__searchAddressInput'
            />
          )
        },
      },
      {
        label: 'Street',
        name: 'street',
        size: 8,
        isRequired: true,
        isHidden: !wasAddressSelected,
      },
      {
        label: 'Line 2',
        name: 'address_2',
        size: 4,
        isHidden: !wasAddressSelected,
      },
      {
        label: 'City',
        name: 'city',
        size: 4,
        isRequired: true,
        isHidden: !wasAddressSelected,
      },
      {
        label: 'State',
        name: 'state',
        size: 2,
        isRequired: true,
        isHidden: !wasAddressSelected,
      },
      {
        label: 'Zip',
        name: 'zip',
        size: 3,
        isRequired: true,
        isHidden: !wasAddressSelected,
      },
      {
        label: 'Country',
        name: 'country',
        size: 3,
        isHidden: !wasAddressSelected,
      },
      {
        label: 'Address Types',
        name: 'addressTypes',
        size: 12,
        isHidden: !wasAddressSelected,
        type: EFieldType.multipleSelect,
        options: addressTypeOptions,
        isRequired: true,
        extraIcons: ({ watch, name, setValue }) => {
          const addressTypes = watch(name, [])
          const allTypes = addressTypeOptions.map(({ value }) => value)
          const areAllTypesSelected = allTypes.length === addressTypes.length
          const Icon = CheckMarkIcon
          const iconProps = {
            color: areAllTypesSelected
              ? 'var(--ion-color-success)'
              : 'var(--ion-color-medium)',
            style: {
              fontSize: 18,
            },
          }

          return [
            {
              Icon: <Icon {...iconProps} />,
              onClick() {
                if (areAllTypesSelected) {
                  setValue(name, [])
                } else {
                  setValue(name, allTypes)
                }
              },
            },
          ]
        },
      },
    ],
    [addressTypeOptions, isManualTyping, wasAddressSelected],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        searchAddress: isManualTyping
          ? Yup.string()
          : Yup.string().required('Address is required!'),
        street: Yup.string().required('Street is required!'),
        city: Yup.string().required('City is required!'),
        state: Yup.string().required('State is required!'),
        zip: Yup.string()
          .required('Zip is required!')
          .min(5, 'At least 5 characters!')
          .max(11, 'Maximum 11 characters!'),
        country: Yup.string(),
        addressTypes: Yup.array().min(1, 'At least one selected option'),
      }),
    [isManualTyping],
  )

  const createAddress = useCallback(
    async formValues => {
      const payload = {
        ...formValues,
        addressableId,
        addressableType,
      }
      if (canSkipCreatingOnBackend) {
        onSubmit && onSubmit(formValues)
      } else {
        const { errors, ...response } = await apiClient.addresses.create(
          payload,
        )
        if (errors?.length > 0) {
          setBackendError(errors[0])
        } else {
          afterCreate && afterCreate(response)
          toast.success(toastMessages.createSuccess)
        }
      }
    },
    [
      addressableId,
      addressableType,
      afterCreate,
      canSkipCreatingOnBackend,
      onSubmit,
    ],
  )

  const updateAddress = useCallback(
    async formValues => {
      const { id, ...payload } = formValues
      const { errors, ...response } = await apiClient.addresses.update(
        id,
        payload,
      )
      if (errors?.length > 0) {
        setBackendError(errors[0])
      } else {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      setBackendError('')
      try {
        if (isUpdating) {
          await updateAddress(formValues)
        } else {
          await createAddress(formValues)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    },
    [createAddress, isUpdating, updateAddress],
  )

  useEffect(() => {
    if (ref !== null) {
      ref.current = {
        ...ref.current,
        onSubmitForm: handleSubmit,
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  useEffect(() => {
    setWasAddressSelected(isUpdating)
    setIsManualTyping(isUpdating)
  }, [isUpdating])

  return (
    <ConcordFormStructure
      className={className}
      fields={fields}
      error={backendError}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      formData={mapFormData}
      schema={schema}
      isLoading={isLoading}
      ref={ref}
      submitText={isUpdating ? 'Update' : 'Create'}
      {...formProps}
    />
  )
}

export default React.forwardRef(AddressForm)
