import { useMemo, useState } from 'react'

import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'

import type { IBreadcrumbDotProps } from './type'

import './styles.scss'
import moment from 'moment'

function BreadcrumbDot(props: IBreadcrumbDotProps) {
  const { breadcrumbLoad } = props

  const [showInfoBox, setShowInfoBox] = useState(false)

  const onToggleInfoBox = () => {
    setShowInfoBox(prev => !prev)
  }

  const position = useMemo(
    () =>
      new google.maps.LatLng(
        Number(breadcrumbLoad.lat || 0),
        Number(breadcrumbLoad.lng || 0),
      ),
    [breadcrumbLoad.lat, breadcrumbLoad.lng],
  )

  if (breadcrumbLoad.lat && breadcrumbLoad.lng) {
    return (
      <InfoBox
        zIndex={1000}
        position={position}
        options={{
          closeBoxURL: '',
          enableEventPropagation: true,
          alignBottom: true,
        }}
      >
        <div>
          <div className='BreadcrumbDot__container' onClick={onToggleInfoBox} />

          {showInfoBox && (
            <InfoBox
              zIndex={1000}
              position={position}
              options={{
                closeBoxURL: '',
                enableEventPropagation: true,
                alignBottom: true,
                pixelOffset: new window.google.maps.Size(18, 0),
                boxStyle: {
                  minWidth: '45px',
                },
                isHidden: false,
              }}
            >
              <div className='BreadcrumbDot__infoBox'>
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    Location: {breadcrumbLoad.lat}, {breadcrumbLoad.lng}
                  </li>
                  {breadcrumbLoad.eventType && (
                    <li>Event Type: {breadcrumbLoad.eventType}</li>
                  )}
                  <li>
                    Created At:{' '}
                    {moment(breadcrumbLoad.createdAt).format(
                      'MMM-d YYYY (ddd)',
                    )}
                  </li>
                </ul>
              </div>
            </InfoBox>
          )}
        </div>
      </InfoBox>
    )
  }

  return null
}
export default BreadcrumbDot
