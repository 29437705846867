import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPriceEscalations from './useModifyPriceEscalations'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import {
  IGetPriceEscalationsParams,
  IPriceEscalation,
} from '~/types/models/IPriceEscalation'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
// import sleep from '~/utils/sleep'

const useQueryPriceEscalations = (
  params: IGetPriceEscalationsParams = {},
  options?: Partial<UseQueryOptions<IPriceEscalation[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addPriceEscalation, updatePriceEscalation, removePriceEscalation } =
    useModifyPriceEscalations(params)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'priceEscalations',
      sessionUser?.id,
      buildGetUrl(apiClient.priceEscalations.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.priceEscalations.get(params)
      return response.priceEscalations
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const priceEscalationsData = data || []

  return {
    priceEscalationsData,
    isPriceEscalationsLoading: isLoading,
    refetchPriceEscalations: refetch,
    addPriceEscalation,
    updatePriceEscalation,
    removePriceEscalation,
  }
}

export default useQueryPriceEscalations
