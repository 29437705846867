import { useMemo, useState } from 'react'
import { useQueryUoms } from '~/hooks/useQueryData'

import { FloatingInput } from '../FloatingForm/Input'

import './QtyCombined.scss'
import { DropdownWithCustomChildren } from '../ConcordForm'
import { Badge } from 'react-bootstrap'

const QtyCombinedInput = ({ control, index, onBlurQty }) => {
  const { currentUom } = useQueryUoms()

  const [input, setInput] = useState('qty')

  const options = useMemo(
    () => [
      { value: 'qty', label: currentUom ? `Qty (${currentUom.code})` : 'Qty' },
      { value: 'loads', label: 'Loads' },
    ],
    [currentUom],
  )

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === input),
    [input, options],
  )

  const inputName = useMemo(
    () => `schedulesAttributes[${index}].${input}`,
    [index, input],
  )

  return (
    <div className='qty-loads-input'>
      <FloatingInput
        className='qty'
        control={control}
        inputType='number'
        name={inputName}
        label={selectedOption.label}
        handleBlur={onBlurQty}
      />

      <DropdownWithCustomChildren
        options={options}
        value={selectedOption}
        className='make-custom-dropdown-inline no-hover'
        onChange={(event, { value }) => {
          setInput(value)
        }}
        showSearchField={false}
        tabIndex={-1}
      >
        <Badge style={{ margin: '0 4px', fontSize: 11 }} tabIndex={-1}>
          {selectedOption?.label || 'Select option'}
        </Badge>
      </DropdownWithCustomChildren>
    </div>
  )
}

export default QtyCombinedInput
