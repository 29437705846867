import { CommonDialogV2, ICommonDialogProps } from '~/components/shared'
import CompanyForm, { ICompanyFormProps } from './CompanyForm'
import { useMemo } from 'react'

export interface IDialogCompanyFormProps
  extends ICommonDialogProps,
    ICompanyFormProps {}

function DialogCompanyForm(props: IDialogCompanyFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    payloadWhenCreate,
    hiddenFields = ['parentCompanyId', 'timeZone', 'workPayTypeId', 'uomId'],
    defaultValues,
    groupsShownByDefault,
    afterModifyEmails,
    ...modalProps
  } = props

  const title = useMemo(() => {
    if (formData?.id) {
      return `Update Company ${formData.name}`
    }
    return 'Create Company'
  }, [formData])

  return (
    <CommonDialogV2 {...modalProps} title={title} isHiddenOkButton>
      <CompanyForm
        formData={formData}
        payloadWhenCreate={payloadWhenCreate}
        hiddenFields={hiddenFields}
        afterCreate={afterCreate}
        afterUpdate={afterUpdate}
        defaultValues={defaultValues}
        groupsShownByDefault={groupsShownByDefault}
        afterModifyEmails={afterModifyEmails}
      />
    </CommonDialogV2>
  )
}

export default DialogCompanyForm
