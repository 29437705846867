import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  CloseIcon,
  ConcordFormStructure,
  IConcordFormField,
  PlusIcon,
  WorkTypeForm,
  WorkForceForm,
  ConcordFormLayout,
  TerminalForm,
  GroupForm,
} from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ISellerToBuyerTerminalFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryTerminals,
  useQueryGroups,
  useQueryWorkTypes,
  useQueryWorkForces,
  useQuerySalesProposals,
  useQueryCompanies,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import {
  ESellerToBuyerTerminalStatus,
  ISellerToBuyerTerminal,
  ISellerToBuyerTerminalFormValues,
} from '~/types/models/ISellerToBuyerTerminal'
import moment from 'moment'
import { SalesProposalCard } from './SalesProposalCard'
import { ITerminal } from '~/types/models/ITerminal'
import { ICommonOption } from '~/types/models/ICommonModel'
import { IUser } from '~/types/models/IUser'

const SellerToBuyerTerminalForm = (props: ISellerToBuyerTerminalFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isWorkTypeFormOpen, setIsWorkTypeFormOpen] = useState(false)
  const [isWorkForceFormOpen, setIsWorkForceFormOpen] = useState(false)
  const [isSalesProposalCreating, setIsSalesProposalCreating] = useState(false)
  const [showBuyerTerminal, setShowBuyerTerminal] = useState(false)
  const [showSellerTerminalForm, setShowSellerTerminalForm] = useState(false)
  const [showGroupForm, setShowGroupForm] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentUser: IUser = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  // const { usersData } = useQueryUsers()

  const {
    sellerCompanyOptions,
    buyerCompanyOptions,
    isLoadingCompaniesData,
    findCompanyById,
  } = useQueryCompanies({})

  const { salesProposalsData, updateSalesProposal, addSalesProposal } =
    useQuerySalesProposals(
      {
        filters: {
          sellerToBuyerTerminalId: formData?.id as number,
        },
      },
      { enabled: Boolean(formData?.id) },
    )

  const { workTypeOptions, isWorkTypesLoading, addWorkType } =
    useQueryWorkTypes()

  const { workForceOptions, isWorkForcesLoading, addWorkForce } =
    useQueryWorkForces()

  const { groupOptions, isLoadingGroups, addGroup } = useQueryGroups()
  const {
    terminalOptions,
    buyerTerminalOptions,
    isLoadingTerminals,
    addTerminal,
  } = useQueryTerminals()

  const { userAccessOptions, isLoadingUsersData } = useQueryUsers()

  const renderTerminalForm = (options: {
    show: boolean
    formData?: Partial<ITerminal>
    afterUpdate?: (terminal: ITerminal) => void
    afterCreate?: (terminal: ITerminal) => void
    onClose: () => void
    companyOptions?: ICommonOption[]
    readOnlyFields?: string[]
  }) => {
    const { show, onClose, ...formProps } = options
    if (show) {
      return (
        <div style={{ border: '1px solid var(--bs-gray-500)' }}>
          <TerminalForm
            {...formProps}
            isHiddenCancelButton={false}
            cancelText='Close'
            onCancel={onClose}
          />
        </div>
      )
    }
    return null
  }

  const onCloseSellerTerminalForm = () => {
    setShowSellerTerminalForm(false)
  }

  const onCloseBuyerTerminalForm = () => {
    setShowBuyerTerminal(false)
  }

  const onCloseGroupForm = () => {
    setShowGroupForm(false)
  }

  const fields: IConcordFormField[] = [
    {
      label: 'Seller',
      name: 'sellerId',
      options: sellerCompanyOptions,
      type: EFieldType.singleSelect,
      size: 6,
      isRequired: true,
      isReadOnly: currentScope === EScope.seller,
      isLoading: isLoadingCompaniesData,
    },
    {
      label: 'Seller Terminal',
      name: 'sellerTerminalId',
      options({ watch }) {
        const sellerId = watch('sellerId', null)
        return terminalOptions.filter(({ companyId }) => companyId === sellerId)
      },
      type: EFieldType.singleSelect,
      size: 6,
      isDisabled: ({ watch }) => !watch('sellerId', null),
      hint({ watch }) {
        if (!watch('sellerId', null)) {
          return 'Select Seller first'
        }
      },
      extraIcons: [
        {
          Icon: showSellerTerminalForm ? (
            <CloseIcon color='var(--bs-danger)' />
          ) : (
            <PlusIcon color='var(--bs-primary)' />
          ),
          onClick() {
            setShowSellerTerminalForm(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'sellerTerminalForm',
      render({ watch }) {
        const sellerId = watch('sellerId')

        return renderTerminalForm({
          show: showSellerTerminalForm,
          afterCreate(terminal) {
            formRef.current?.setValue('sellerId', terminal.companyId)
            formRef.current?.setValue('sellerTerminalId', terminal.id)
            onCloseSellerTerminalForm()
            addTerminal(terminal)
          },
          onClose: onCloseSellerTerminalForm,
          formData: {
            companyId: sellerId,
          },
          readOnlyFields: ['companyId'],
        })
      },
    },
    {
      label: 'Buyer',
      name: 'buyerId',
      options: buyerCompanyOptions,
      type: EFieldType.singleSelect,
      size: 6,
      isLoading: isLoadingCompaniesData,
      onChange() {
        formRef.current?.setValue('buyerTerminalId', null)
        formRef.current?.setFocus('buyerTerminalId')
      },
    },
    {
      label: 'Buyer Terminal',
      name: 'buyerTerminalId',
      options({ watch }) {
        const buyerId = watch('buyerId', null)
        return buyerTerminalOptions.filter(
          ({ companyId }) => buyerId === companyId,
        )
      },
      type: EFieldType.singleSelect,
      size: 6,
      isRequired: true,
      isLoading: isLoadingTerminals,
      isDisabled: ({ watch }) => !watch('buyerId', null),
      hint({ watch }) {
        if (!watch('buyerId', null)) {
          return 'Select Buyer first'
        }
      },
      extraIcons: [
        {
          Icon: showBuyerTerminal ? (
            <CloseIcon color='var(--bs-danger)' />
          ) : (
            <PlusIcon color='var(--bs-primary)' />
          ),
          onClick() {
            setShowBuyerTerminal(prev => !prev)
          },
        },
      ],
      onChange({ selectedOption }) {
        const buyer = findCompanyById(selectedOption.companyId)
        formRef.current?.setValue('buyerId', buyer?.id)
      },
    },
    {
      label: '',
      name: 'buyerTerminalForm',
      render({ watch }) {
        const buyerId = watch('buyerId', null)

        return renderTerminalForm({
          show: showBuyerTerminal,
          afterCreate(terminal) {
            formRef.current?.setValue('buyerId', terminal.companyId)
            formRef.current?.setValue('buyerTerminalId', terminal.id)
            onCloseBuyerTerminalForm()
            addTerminal(terminal)
          },
          onClose: onCloseBuyerTerminalForm,
          formData: {
            companyId: buyerId as any,
          },
        })
      },
    },
    {
      label: 'Start Date',
      name: 'startDate',
      type: EFieldType.date,
      size: 6,
      maxDate({ watch }) {
        const endDate = watch('endDate', undefined)
        if (endDate) {
          return moment(endDate).format('YYYY-MM-DD')
        }
        return undefined
      },
    },
    {
      label: 'End Date',
      name: 'endDate',
      type: EFieldType.date,
      size: 6,
      minDate({ watch }) {
        const startDate = watch('startDate', undefined)
        if (startDate) {
          return moment(startDate).format('YYYY-MM-DD')
        }
        return undefined
      },
    },
    {
      label: 'Qty',
      name: 'qty',
      type: EFieldType.number,
      size: 6,
    },
    {
      label: 'Group',
      name: 'groupId',
      type: EFieldType.singleSelect,
      size: 6,
      options: groupOptions,
      isLoading: isLoadingGroups,
      extraIcons: [
        {
          Icon: showGroupForm ? (
            <CloseIcon color='var(--bs-danger)' />
          ) : (
            <PlusIcon color='var(--bs-primary)' />
          ),
          onClick() {
            setShowGroupForm(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'groupForm',
      isHidden: !showGroupForm,
      render() {
        return (
          <div style={{ border: '1px solid var(--bs-gray-500)' }}>
            <GroupForm
              isHiddenCancelButton={false}
              cancelText='Close'
              onCancel={onCloseGroupForm}
              afterCreate={group => {
                addGroup(group)
                onCloseGroupForm()
                formRef.current?.setValue('groupId', group.id)
              }}
            />
          </div>
        )
      },
    },
    {
      label: 'Work Type',
      name: 'workTypeId',
      type: EFieldType.singleSelect,
      options: workTypeOptions,
      isLoading: isWorkTypesLoading,
      size: 6,
      extraIcons: [
        {
          Icon: isWorkTypeFormOpen ? (
            <CloseIcon color='var(--ion-color-danger)' />
          ) : (
            <PlusIcon color='var(--ion-color-concord)' />
          ),
          onClick() {
            setIsWorkTypeFormOpen(prev => !prev)
          },
        },
      ],
    },
    {
      label: 'Work Force',
      name: 'workForceId',
      type: EFieldType.singleSelect,
      options: workForceOptions,
      isLoading: isWorkForcesLoading,
      size: 6,
      extraIcons: [
        {
          Icon: isWorkForceFormOpen ? (
            <CloseIcon color='var(--ion-color-danger)' />
          ) : (
            <PlusIcon color='var(--ion-color-concord)' />
          ),
          onClick() {
            setIsWorkForceFormOpen(prev => !prev)
          },
        },
      ],
    },
    {
      label: '',
      name: 'workTypeForm',
      isHidden: !isWorkTypeFormOpen,
      render({ setValue }) {
        return (
          <WorkTypeForm
            style={{ border: '1px solid #e5e5e5' }}
            isHiddenCancelButton={false}
            onCancel={() => {
              setIsWorkTypeFormOpen(false)
            }}
            afterCreate={newData => {
              addWorkType(newData)
              setIsWorkTypeFormOpen(false)
              setValue('workTypeId', newData.id)
            }}
          />
        )
      },
    },
    {
      label: '',
      name: 'workForceForm',
      isHidden: !isWorkForceFormOpen,
      render({ setValue }) {
        return (
          <WorkForceForm
            style={{ border: '1px solid #e5e5e5' }}
            isHiddenCancelButton={false}
            onCancel={() => {
              setIsWorkForceFormOpen(false)
            }}
            afterCreate={newData => {
              addWorkForce(newData)
              setIsWorkForceFormOpen(false)
              setValue('workForceId', newData.id)
            }}
          />
        )
      },
    },
    {
      label: '',
      name: 'salesProposals',
      isHidden: !isUpdating,
      render() {
        return (
          <ConcordFormLayout
            label='Projects'
            extraIcons={[
              {
                Icon: isSalesProposalCreating ? (
                  <CloseIcon color='var(--ion-color-danger)' />
                ) : (
                  <PlusIcon color='var(--ion-color-concord)' />
                ),
                onClick() {
                  setIsSalesProposalCreating(prev => !prev)
                },
              },
            ]}
          >
            <div>
              {isSalesProposalCreating && (
                <div>
                  <SalesProposalCard
                    salesBacklog={formData as ISellerToBuyerTerminal}
                    afterCreate={data => {
                      addSalesProposal(data)
                      setIsSalesProposalCreating(false)
                    }}
                  />
                </div>
              )}

              {salesProposalsData.map(item => (
                <SalesProposalCard
                  key={item.id}
                  salesProposal={item}
                  afterUpdate={data => {
                    updateSalesProposal(data.id, data)
                  }}
                />
              ))}
            </div>
          </ConcordFormLayout>
        )
      },
    },
    {
      label: 'Status',
      name: 'status',
      type: EFieldType.singleSelect,
      size: 6,
      options: Object.keys(ESellerToBuyerTerminalStatus)
        .filter(field => isNaN(+field))
        .map(field => ({
          label: _.startCase(field),
          value: (ESellerToBuyerTerminalStatus as any)[field],
        })),
      isLoading: isLoadingGroups,
    },
    {
      label: 'User',
      name: 'userAccessId',
      type: EFieldType.singleSelect,
      isLoading: isLoadingUsersData,
      options: userAccessOptions,
      size: 6,
    },
    {
      label: 'Attention',
      name: 'attention',
    },
  ]

  const defaultValues = useMemo<ISellerToBuyerTerminalFormValues>(
    () => ({
      buyerTerminalId: null,
      qty: null,
      sellerId: null,
      sellerTerminalId: null,
      startDate: null,
      endDate: null,
      status: ESellerToBuyerTerminalStatus.active,
      groupId: null,
      workForceId: null,
      workTypeId: null,
      attention: '',
      userAccessId: currentUser.userAccess?.id,
    }),
    [currentUser.userAccess?.id],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        sellerId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
        buyerTerminalId: Yup.number()
          .required('This field is required')
          .typeError('This field is required'),
        qty: Yup.number()
          .min(0.01)
          .nullable()
          .transform(value => (value ? Number(value) : null)),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: ISellerToBuyerTerminalFormValues) => {
      const payload = _.pick(formValues, [
        'buyerTerminalId',
        'qty',
        'sellerId',
        'sellerTerminalId',
        'startDate',
        'endDate',
        'status',
        'groupId',
        'workForceId',
        'workTypeId',
        'attention',
        'userAccessId',
      ])
      const { errors, ...response } =
        await apiClient.sellerToBuyerTerminals.create({
          sellerToBuyerTerminal: payload,
        })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: ISellerToBuyerTerminalFormValues) => {
      if (formData?.id) {
        const payload = _.pick(formValues, [
          'buyerTerminalId',
          'qty',
          'sellerId',
          'sellerTerminalId',
          'startDate',
          'endDate',
          'status',
          'groupId',
          'workForceId',
          'workTypeId',
          'attention',
          'userAccessId',
        ])
        const { errors, ...response } =
          await apiClient.sellerToBuyerTerminals.update(formData.id, {
            sellerToBuyerTerminal: payload,
          })
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: ISellerToBuyerTerminalFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isUpdating && formData) {
      const terminal = terminalOptions.find(
        ({ value }) => value === formData.buyerTerminalId,
      )
      const buyer = findCompanyById(terminal?.companyId)
      formRef.current?.setValue('buyerId', buyer?.id)
      if (formData.status) {
        formRef.current?.setValue(
          'status',
          ESellerToBuyerTerminalStatus[formData.status],
        )
      }
    } else {
      if (currentScope === EScope.seller) {
        formRef.current?.setValue('sellerId', currentCompany.id)
      } else {
        formRef.current?.setValue('buyerId', currentCompany.id)
      }
    }
  }, [
    findCompanyById,
    currentCompany.id,
    currentScope,
    formData,
    isUpdating,
    terminalOptions,
  ])

  return (
    <div>
      <ConcordFormStructure
        {...formProps}
        error={error}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default SellerToBuyerTerminalForm
