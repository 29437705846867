import { Body, Button } from '~/components'

import './styles.scss'
import {
  CommonDialogV2,
  ConcordDropdownV2WithFormControl,
  ConcordFormStructure,
  DropdownWithCustomChildren,
  EmailForm,
  HeaderContent,
  IConcordFormField,
  IRTColumnDef,
  ReusableTable,
  ToggleSection,
} from '~/components/shared'
import { EFieldType, EViewOption } from '~/types/enums/ECommonEnum'
import {
  useQueryCompanies,
  useQueryEmails,
  useQueryPaymentMethods,
} from '~/hooks/useQueryData'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  addCircle,
  addCircleOutline,
  albumsOutline,
  closeCircle,
  pencilOutline,
} from 'ionicons/icons'
import { IEmail } from '~/types/models/IEmail'
import { EEmailTypes } from '~/types/enums/EEmail'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { IPaymentMethodFormValues } from './type'
import { apiClient } from '~/api/ApiClient'
import { sessionService } from 'redux-react-session'
import { IUser } from '~/types/models/IUser'
import { produce } from 'immer'
import { When } from 'react-if'
import { IonButtons, IonText } from '@ionic/react'
import { IStripePaymentMethod } from '~/types/models/IStripe'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripePaymentMethodElement from './StripePaymentMethodElement'
import { StripePaymentMethodCard } from './StripePaymentMethodCard'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import {
  PAYMENT_METHOD_GROUP_BY_OPTIONS,
  VIEW_MODE_OPTIONS,
} from '~/utils/constants'
import moment from 'moment'
import { useWindowSize } from 'react-use'
import { EPaymentMethodGroupByOption } from '~/types/enums/EStripePaymentMethod'
import _ from 'lodash'
import clsx from 'clsx'
import { SetupIntentResult } from '@stripe/stripe-js'

interface IPaymentMethodsContainerProps {
  hideHeader?: boolean
  hideBody?: boolean
  companyId?: number
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

function PaymentMethodsContainer({
  hideBody,
  hideHeader,
  companyId: companyIdPassed,
}: IPaymentMethodsContainerProps) {
  const [emailFormState, setEmailFormState] = useState({
    isOpen: false,
    formData: undefined as IEmail | undefined,
  })
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [isShowForm, setIsShowForm] = useState(false)
  const [groupByOpt, setGroupByOpt] =
    useState<EPaymentMethodGroupByOption | null>(
      EPaymentMethodGroupByOption.cardType,
    )
  const [clientSecret, setClientSecret] = useState('')
  const [isFetchingSetupIntent, setIsFetchingSetupIntent] = useState(false)
  const [currentView, setCurrentView] = useState(EViewOption.list)

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentUser: IUser = useSelector(selectSessionUser)

  const companyId = companyIdPassed || currentCompany.id

  const { findCompanyById, isCompaniesDataFetched, updateCompany } =
    useQueryCompanies({})

  const company = findCompanyById(companyId)

  const { paymentMethodsData, refetchPaymentMethods } = useQueryPaymentMethods(
    {
      stripe: {
        companyId,
      },
    },
    { enabled: Boolean(isCompaniesDataFetched && company?.stripeCustomerId) },
  )

  const {
    companyEmailOptions,
    hrEmail,
    isLoadingEmailsData,
    emailsData,
    addEmail,
    updateEmail,
    findEmailById,
  } = useQueryEmails({
    filters: {
      emailableId: companyId,
    },
  })

  const windowSize = useWindowSize()

  const groupByOptLabel = PAYMENT_METHOD_GROUP_BY_OPTIONS.find(
    ({ value }) => value === groupByOpt,
  )?.label

  const paymentMethodsGroupping = useMemo(() => {
    if (groupByOpt) {
      return _.groupBy(paymentMethodsData, groupByOpt)
    }

    return {
      null: paymentMethodsData,
    }
  }, [groupByOpt, paymentMethodsData])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const onCloseEmailForm = () => {
    setEmailFormState({
      isOpen: false,
      formData: undefined,
    })
  }

  const afterCreateEmail = (newEmail: IEmail) => {
    addEmail(newEmail)
    formRef.current?.setValue('emailId', newEmail.id)
    onCloseEmailForm()
  }

  const afterUpdateEmail = (emailUpdated: IEmail) => {
    updateEmail(emailUpdated.id, emailUpdated)
    onCloseEmailForm()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeGroupByOpt = (event: any, { value }: any) => {
    setGroupByOpt(value)
  }

  const schema = Yup.object({
    emailId: Yup.number()
      .required('This field is required!')
      .typeError('This field is required!'),
    legalName: Yup.string().required('This field is required!'),
  })

  const columns: IRTColumnDef<IStripePaymentMethod>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      Cell({ row: { original } }) {
        return original.usBankAccount?.bankName || '*** *** ***'
      },
    },
    {
      header: 'Card Number',
      accessorKey: 'number',
      Cell({ row: { original } }) {
        const last4 = original.card?.last4 || original.usBankAccount?.last4
        return `**** **** **** ${last4 || '****'}`
      },
    },
    {
      header: 'Routing #',
      accessorKey: 'routingNumber',
      Cell({ row: { original } }) {
        return original.usBankAccount?.routingNumber
      },
    },
    {
      header: 'Type',
      accessorKey: 'accountHolderType',
      minSize: 120,
      maxSize: 120,
      Cell({ row: { original } }) {
        return original.usBankAccount?.accountHolderType
      },
    },
    {
      header: 'Card Type',
      accessorKey: 'cardType',
      Cell({ row: { original } }) {
        const type = original.card?.brand || 'Bank Account'

        return <span style={{ textTransform: 'uppercase' }}>{type}</span>
      },
    },
    {
      header: 'Exp Date',
      accessorKey: 'expDate',
      minSize: 120,
      maxSize: 120,
      Cell({ row: { original } }) {
        if (original.card) {
          const { expMonth, expYear } = original.card
          return [expMonth, expYear].join('/')
        }
        return ''
      },
    },
    {
      header: 'Created At',
      accessorKey: 'created',
      minSize: 120,
      maxSize: 120,
      Cell({ row: { original } }) {
        const milliseconds = original.created * 1000
        const date = new Date(milliseconds)
        return moment(date).format('D/M/YYYY')
      },
    },
    {
      header: 'Email',
      accessorKey: 'billingDetails.email',
    },
    {
      header: 'Phone',
      accessorKey: 'billingDetails.phone',
    },
    {
      header: 'Billing Address',
      accessorKey: 'billingDetails.address',
      minSize: 200,
      maxSize: 200,
      Cell({ cell }) {
        const { line1, city, country, state } = cell.getValue<any>()
        const result = [line1, city, country, state]
          .filter(text => text)
          .join(', ')
        return result
      },
    },
  ]

  const fields: IConcordFormField[] = [
    {
      label: 'Company Legal name',
      name: 'legalName',
      size: 6,
      isRequired: true,
    },
    {
      label: 'Email',
      name: 'emailId',
      isRequired: true,
      type: EFieldType.singleSelect,
      options: companyEmailOptions,
      placeholder: 'Select or create email',
      size: 6,
      isLoading: isLoadingEmailsData,
      render({ watch, name, label, control }) {
        const emailId = watch('emailId', null)
        return (
          <ConcordDropdownV2WithFormControl
            control={control}
            name={name}
            label={label}
            isRequired
            isLoading={isLoadingEmailsData}
            options={companyEmailOptions}
            placeholder='Select or create email...'
            extraIcons={[
              {
                icon: emailFormState.isOpen ? closeCircle : addCircleOutline,
                color: emailFormState.isOpen ? 'danger' : 'concord',
                size: 'small',
                onClick() {
                  setEmailFormState(prev => ({
                    isOpen: !prev.isOpen,
                    formData: undefined,
                  }))
                },
              },
              {
                icon: pencilOutline,
                color: 'warning',
                size: 'small',
                isHidden: !emailId,
                onClick() {
                  const emailFound = findEmailById(emailId)
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  let emailTypes: any[] = []
                  if (emailFound) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    emailFound.emailTypes.map(type => {
                      emailTypes.push(EEmailTypes[type])
                    })
                    setEmailFormState({
                      isOpen: true,
                      formData: {
                        ...emailFound,
                        emailTypes,
                      },
                    })
                  }
                },
              },
            ]}
          />
        )
      },
    },
    {
      label: '',
      name: 'emailForm',
      isHidden: !emailFormState.isOpen,
      render() {
        return (
          <EmailForm
            companyEmailsData={emailsData}
            formData={
              !emailFormState.formData
                ? {
                    emailableId: companyId,
                  }
                : emailFormState.formData
            }
            className='PaymentMethodsContainer__emailForm'
            onCancel={onCloseEmailForm}
            cancelText='Close'
            afterCreate={afterCreateEmail}
            afterUpdate={afterUpdateEmail}
          />
        )
      },
    },
  ]

  const updateLegalName = async (formValues: IPaymentMethodFormValues) => {
    if (formValues.legalName !== company?.legalName) {
      const response = await apiClient.companies.update(companyId, {
        company: {
          legalName: formValues.legalName,
        },
      })
      updateCompany(response.company.id, response.company)
      if (!companyIdPassed) {
        await sessionService.saveUser(
          produce(currentUser, draft => {
            draft.company = response.company
          }),
        )
      }
    }
  }

  const createStripeCustomer = async (formValues: IPaymentMethodFormValues) => {
    const response = await apiClient.stripe.createCustomer({
      stripe: {
        emailId: formValues.emailId as number,
        companyId: companyId,
      },
    })
    updateCompany(response.id, response)
    if (!companyIdPassed) {
      await sessionService.saveUser(
        produce(currentUser, draft => {
          draft.company = response
        }),
      )
    }
  }

  const onSubmitForm = async (formValues: IPaymentMethodFormValues) => {
    setIsLoadingForm(true)
    try {
      await updateLegalName(formValues)
      await createStripeCustomer(formValues)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const onFetchSetupIntent = async () => {
    setIsFetchingSetupIntent(true)
    try {
      const response = await apiClient.stripe.setupIntent({
        stripe: {
          companyId,
        },
      })
      setClientSecret(response.setupIntent.clientSecret)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsFetchingSetupIntent(false)
    }
  }

  const onClosePaymentMethodModal = () => {
    setClientSecret('')
  }

  const renderPaymentMethodCards = useCallback(
    (paymentMethodsData: IStripePaymentMethod[]) =>
      paymentMethodsData.map(data => (
        <StripePaymentMethodCard key={data.id} data={data} />
      )),
    [],
  )

  const getGrouppingLabel = useCallback(
    (key: string) => {
      if (key === 'null' && groupByOpt === null) {
        return ''
      }

      if (groupByOpt === EPaymentMethodGroupByOption.type) {
        if (key === 'undefined') {
          return 'Bank Accounts'
        }
        return 'Credit Cards'
      }

      if (
        groupByOpt === EPaymentMethodGroupByOption.accountType &&
        key === 'undefined'
      ) {
        return 'Credit Cards'
      }

      if (
        groupByOpt === EPaymentMethodGroupByOption.cardType &&
        key === 'undefined'
      ) {
        return 'Bank Accounts'
      }

      return _.startCase(key)
    },
    [groupByOpt],
  )

  const renderPaymentMethods = useMemo(
    () =>
      Object.keys(paymentMethodsGroupping)
        .filter(key => paymentMethodsGroupping[key].length > 0)
        .map(key => (
          <ToggleSection
            className={clsx('PaymentMethodsContainer__toggle', {
              isHiddenLabel: groupByOpt === null,
            })}
            key={key}
            label={getGrouppingLabel(key)}
            isOpenByDefault
            hideArrow={!getGrouppingLabel(key)}
          >
            {renderPaymentMethodCards(paymentMethodsGroupping[key])}
          </ToggleSection>
        )),
    [
      getGrouppingLabel,
      groupByOpt,
      paymentMethodsGroupping,
      renderPaymentMethodCards,
    ],
  )

  const onToggleViewMode = () => {
    setCurrentView(prev =>
      prev === EViewOption.list ? EViewOption.table : EViewOption.list,
    )
  }

  const afterCreatePaymentMethod = (result: SetupIntentResult) => {
    onClosePaymentMethodModal()
    refetchPaymentMethods()
    apiClient.stripe.setupIntentSucceeded({
      stripe: {
        companyId,
        setupIntentId: result.setupIntent?.id as string,
      },
    })
  }

  const renderHeader = useCallback(
    () => ({
      headerTitle: 'Payment Methods',
      rightButton: (
        <ButtonGroup
          className='mb-2'
          // name='radio'
          style={{ width: '115px', height: '30px', float: 'right' }}
        >
          {VIEW_MODE_OPTIONS.map((viewOption, idx) => (
            <ToggleButton
              size='lg'
              key={idx}
              id={`radio-${idx}`}
              type='radio'
              variant={idx % 2 ? 'outline-secondary' : 'outline-primary'}
              name='radio'
              value={viewOption.value}
              checked={currentView == viewOption.value}
              onChange={onToggleViewMode}
            >
              {viewOption.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      ),
    }),
    [currentView],
  )

  useEffect(() => {
    if (isCompaniesDataFetched) {
      if (company?.stripeCustomerId) {
        setIsShowForm(false)
      } else {
        setIsShowForm(true)
      }
    }
  }, [isCompaniesDataFetched, company?.stripeCustomerId])

  useEffect(() => {
    if (hrEmail) {
      formRef.current?.setValue('emailId', hrEmail.id)
    }
  }, [hrEmail])

  useEffect(() => {
    formRef.current?.setValue('legalName', company?.legalName || company?.name)
  }, [company?.legalName, company?.name])

  useEffect(() => {
    if (paymentMethodsData.length <= 3) {
      setGroupByOpt(null)
    }
  }, [paymentMethodsData.length])

  return (
    <>
      {!hideHeader && <HeaderContent renderContent={renderHeader} />}
      <Body hideBody={hideBody}>
        <div
          className='PaymentMethodsContainer__formContainer'
          style={{ display: isShowForm ? 'block' : 'none' }}
        >
          <ConcordFormStructure
            schema={schema}
            ref={formRef}
            fields={fields}
            isLoading={isLoadingForm}
            isHiddenCancelButton
            onSubmit={onSubmitForm}
          />
        </div>

        <When condition={!isShowForm && isCompaniesDataFetched}>
          <div style={{ margin: '8px 12px' }}>
            {paymentMethodsData.length > 0 ? (
              <IonButtons>
                <Button
                  loading={isFetchingSetupIntent}
                  label='Create Additional Payment Method'
                  icon={addCircle}
                  onClick={onFetchSetupIntent}
                />

                {paymentMethodsData.length > 3 && (
                  <DropdownWithCustomChildren
                    options={PAYMENT_METHOD_GROUP_BY_OPTIONS}
                    className='no-hover'
                    value={groupByOpt}
                    onChange={onChangeGroupByOpt}
                    menuPortalTarget={document.body}
                  >
                    <Button icon={albumsOutline}>
                      Group by: {groupByOptLabel}
                    </Button>
                  </DropdownWithCustomChildren>
                )}
              </IonButtons>
            ) : (
              <Button
                icon={addCircleOutline}
                fill='outline'
                size='large'
                onClick={onFetchSetupIntent}
                loading={isFetchingSetupIntent}
              >
                <IonText>Add first payment method</IonText>
              </Button>
            )}
            {currentView === EViewOption.list && renderPaymentMethods}
            {currentView === EViewOption.table && (
              <div style={{ marginTop: 12 }}>
                <ReusableTable
                  columns={columns}
                  data={paymentMethodsData}
                  tableHeight={windowSize.height - 180}
                />
              </div>
            )}
          </div>
        </When>

        <CommonDialogV2
          isHiddenHeader
          isOpen={Boolean(clientSecret)}
          onClose={onClosePaymentMethodModal}
        >
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripePaymentMethodElement
                afterCreate={afterCreatePaymentMethod}
              />
            </Elements>
          )}
        </CommonDialogV2>
      </Body>
    </>
  )
}

export default PaymentMethodsContainer
