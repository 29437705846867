import { useMemo } from 'react'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'

import {
  ConcordFormLayout,
  ConcordFormStructure,
  ConcordTextFieldWithFormControl,
  IConcordFormField,
} from '~/components/shared'
import * as Yup from 'yup'
import ReactJson from 'react-json-view'
import _ from 'lodash'

import type { ITruck } from '~/types/models/ITruck'
import type { ITruckInfoFormDataProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'

const TruckInfoFormData = (props: ITruckInfoFormDataProps) => {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const { fleetCompanyOptions, isLoadingCompaniesData } = useQueryCompanies({})
  const { companyTerminalOptions, isLoadingTerminals } = useQueryTerminals()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'general',
        label: 'General',
        fields: [
          {
            label: 'Company',
            name: 'fleetId',
            type: EFieldType.singleSelect,
            size: 6,
            options: fleetCompanyOptions,
            isRequired: true,
            isLoading: isLoadingCompaniesData,
          },
          {
            name: 'name',
            label: 'Name',
            size: 6,
            isRequired: true,
          },
          {
            name: 'make',
            label: 'Make',
            size: 6,
          },
          {
            name: 'model',
            label: 'Model',
            size: 6,
          },
          {
            name: 'year',
            label: 'Year',
            size: 6,
            type: EFieldType.number,
          },
          {
            name: 'licensePlate',
            label: 'License Plate',
            size: 6,
          },
          {
            name: 'bedType',
            label: 'Bed Type',
            size: 6,
          },
          {
            name: 'vin',
            label: 'Vin',
            size: 6,
          },
          {
            name: 'parkTerminalId',
            label: 'Park Terminal',
            isLoading: isLoadingTerminals,
            type: EFieldType.singleSelect,
            options: companyTerminalOptions,
          },
        ],
      },
      {
        name: 'dispatchDetails',
        label: 'Dispatch Details',
        fields: [
          {
            name: 'maxQty',
            label: 'Max Quantity',
            size: 6,
            type: EFieldType.number,
          },
          {
            name: 'weightAllowance',
            label: 'Weight Allowance',
            size: 6,
            type: EFieldType.number,
          },
          {
            name: 'axleWeight',
            label: 'Axle Weight',
            size: 4,
            type: EFieldType.number,
          },
          {
            name: 'emptyWeight',
            label: 'Empty Weight',
            size: 4,
            type: EFieldType.number,
          },
          {
            name: 'totalWeight',
            label: 'Total Weight',
            size: 4,
            type: EFieldType.number,
          },
        ],
      },
      {
        name: 'sensor',
        label: 'Sensor Details',
        fields: [
          {
            name: 'sensors',
            label: 'Sensors',
            render({ label, watch, name }) {
              let data = watch(name, undefined)
              if (data) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let jsonData: any = {}
                try {
                  jsonData = JSON.parse(data)
                } catch (error) {
                  jsonData = {}
                }

                if (Object.keys(jsonData).length === 0) {
                  return null
                }

                return (
                  <ConcordFormLayout label={label}>
                    <ReactJson src={jsonData} collapsed name={false} />
                  </ConcordFormLayout>
                )
              }
              return null
            },
          },
          {
            label: 'GPS Source',
            name: 'gpsSource',
            type: EFieldType.singleSelect,
            size: 4,
            options: [
              {
                value: 0,
                label: 'samsara',
              },
              {
                value: 1,
                label: 'cidra',
              },
              {
                value: 2,
                label: 'linxup',
              },
              {
                value: 3,
                label: 'concord',
              },
              {
                value: 4,
                label: 'verifi',
              },
              {
                value: 5,
                label: 'command',
              },
            ],
          },
          {
            name: 'vehicleUid',
            label: 'Vehicle Uid',
            size: 4,
            render({ control, watch, name, label }) {
              const gpsSource = watch('gpsSource', '')

              return (
                <ConcordTextFieldWithFormControl
                  label={label}
                  name={name}
                  control={control}
                  isDisabled={_.isNil(gpsSource)}
                />
              )
            },
          },
          {
            name: 'gpsInterval',
            label: 'GPS Interval (second)',
            size: 4,
            type: EFieldType.number,
            suffix: 's',
          },
          {
            name: 'sensorSource',
            label: 'Sensor Source',
            type: EFieldType.singleSelect,
            size: 6,
            options: [
              {
                value: 0,
                label: 'cidra',
              },
              {
                value: 1,
                label: 'verifi',
              },
              {
                value: 2,
                label: 'command',
              },
              {
                value: 3,
                label: 'alcemy',
              },
            ],
          },
          {
            name: 'sensorUid',
            label: 'Sensor Uid',
            size: 6,
            render({ control, watch, name, label }) {
              const sensorSource = watch('sensorSource', '')

              return (
                <ConcordTextFieldWithFormControl
                  label={label}
                  name={name}
                  control={control}
                  isDisabled={_.isNil(sensorSource)}
                />
              )
            },
          },
        ],
      },
    ],
    [
      companyTerminalOptions,
      fleetCompanyOptions,
      isLoadingCompaniesData,
      isLoadingTerminals,
    ],
  )

  const defaultValues = useMemo<Partial<ITruck>>(
    () => ({
      fleetId: currentCompany.id,
      name: '',
      make: '',
      model: '',
      year: undefined,
      licensePlate: '',
      bedType: '',
      vin: '',
      vehicleId: '',
      gpsInterval: undefined,
      maxQty: undefined,
      weightAllowance: undefined,
      axleWeight: undefined,
      emptyWeight: undefined,
      totalWeight: undefined,
      status: 0,
    }),
    [currentCompany.id],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required('Model is required!'),
      }),
    [],
  )

  return (
    <ConcordFormStructure
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      {...props}
    />
  )
}

export default TruckInfoFormData
