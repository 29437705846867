import { useSelector } from 'react-redux'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { apiClient } from '~/api/ApiClient'
import { useEffect, useState } from 'react'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js'
import { Body } from '~/components'
import { sessionService } from 'redux-react-session'
import { IUser } from '~/types/models/IUser'
import { produce } from 'immer'
import { useQueryCompanies, useQueryStripeAccount } from '~/hooks/useQueryData'
import { Alert, Button } from 'react-bootstrap'
import buildFullAddress from '~/utils/buildFullAddress'
import { EditIcon } from '~/components/shared'

export interface IEmbeddedPaymentsContainerProps {
  hideBody?: boolean
  companyId?: number
  onExit?: (company: ICompany) => void
}

function EmbeddedPaymentsContainer(props: IEmbeddedPaymentsContainerProps) {
  const { hideBody } = props

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentUser: IUser = useSelector(selectSessionUser)

  const [isOpenStripeComponent, setIsOpenStripeComponent] = useState(false)

  const companyId = currentCompany.id

  const { stripeAccount } = useQueryStripeAccount()
  const { updateCompany } = useQueryCompanies({})

  const fetchClientSecret = async () => {
    const response = await apiClient.stripe.createAccount({
      stripe: {
        companyId,
      },
    })
    return response.accountSession.clientSecret
  }

  const [stripeConnectInstance] = useState(() => {
    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don’t submit any personally identifiable information in requests made with this key.
      publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          fontFamily:
            'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          colorPrimary: '#1090ff',
        },
      },
    })
  })

  const onStripeExitEvent = async () => {
    const response = await apiClient.companies.getById(companyId)
    updateCompany(response.id, response)
    sessionService.saveUser(
      produce(currentUser, draft => {
        draft.company = response
      }),
    )
    setIsOpenStripeComponent(false)
  }

  useEffect(() => {
    if (
      !stripeAccount ||
      !stripeAccount?.chargesEnabled ||
      !stripeAccount?.payoutsEnabled
    ) {
      setIsOpenStripeComponent(true)
    }
  }, [stripeAccount])

  return (
    <Body hideBody={hideBody}>
      {stripeAccount &&
      stripeAccount?.chargesEnabled &&
      stripeAccount?.payoutsEnabled &&
      !isOpenStripeComponent ? (
        <Alert variant='info' style={{ fontSize: 13 }}>
          <ul>
            <li>
              <span>Business Profile</span>
              <ul>
                <li>
                  <span>Name: </span>
                  <span>{stripeAccount.businessProfile.name}</span>
                </li>
                <li>
                  <span>Email: </span>
                  <span>{stripeAccount.businessProfile.supportEmail}</span>
                </li>
                <li>
                  <span>Phone: </span>
                  <span>{stripeAccount.businessProfile.supportPhone}</span>
                </li>
                <li>
                  <span>Url: </span>
                  <span>{stripeAccount.businessProfile.url}</span>
                </li>
                <li>
                  <span>Address: </span>
                  <span>
                    {buildFullAddress(
                      stripeAccount.businessProfile.supportAddress,
                    )}
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <span>External Accounts</span>
              <ol>
                {stripeAccount.externalAccounts.data.map(item => (
                  <li key={item.id}>
                    <div>
                      <span>Bank Name: </span>
                      <span>{item.bankName}</span>
                    </div>

                    <div>
                      <span>Country: </span>
                      <span>{item.country}</span>
                    </div>

                    <div>
                      <span>Currency: </span>
                      <span>{item.currency}</span>
                    </div>

                    <div>
                      <span>Last 4: </span>
                      <span>*** *** *** {item.last4}</span>
                    </div>

                    <div>
                      <span>Routing Number: </span>
                      <span>{item.routingNumber}</span>
                    </div>
                  </li>
                ))}
              </ol>
            </li>
          </ul>
          <Button
            style={{ fontSize: 13 }}
            onClick={() => {
              setIsOpenStripeComponent(true)
            }}
          >
            <EditIcon color='white' />
            <span style={{ marginLeft: 4 }}>Edit</span>
          </Button>
        </Alert>
      ) : null}

      {isOpenStripeComponent && (
        <div
          style={{
            maxWidth: 650,
            margin: '24px auto',
            padding: 16,
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            borderRadius: 8,
          }}
        >
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding onExit={onStripeExitEvent} />
          </ConnectComponentsProvider>
        </div>
      )}
    </Body>
  )
}

export default EmbeddedPaymentsContainer
