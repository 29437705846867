import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IGetSensorParams, ISensor } from '~/types/models/ISensor'
import { toast } from 'react-toastify'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import _ from 'lodash'
import { buildGetUrl } from '~/utils/buildUrl'

const useQuerySensors = (
  params: IGetSensorParams,
  options?: Partial<UseQueryOptions<ISensor[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched, refetch, isFetching } = useQuery({
    queryKey: [
      'sensors',
      sessionUser?.id,
      buildGetUrl(apiClient.sensors.endpoint, params, {
        includeUndefined: true,
      }),
    ],
    async queryFn() {
      const response = await apiClient.sensors.get(
        _.merge(params, {
          sensor: {
            removeValues: 'remove_all_non_values',
          },
        }),
      )

      if (response.errors) {
        toast.error(response.errors)
        return []
      }
      return response
    },
    ...DEFAULT_QUERY_OPTIONS,
    staleTime: 30000,
    ...options,
  })

  return {
    sensorsData: data || [],
    isSensorsDataLoading: isLoading,
    isSensorsDataFetched: isFetched,
    isSensorsDataFetching: isFetching,
    refetchSensorsData: refetch,
  }
}

export default useQuerySensors
