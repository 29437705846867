import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useQuerySensors } from '~/hooks/useQueryData'

import type { ITruck } from '~/types/models/ITruck'
import { ToggleSection } from '../ToggleSection'
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import clsx from 'clsx'
import { SensorCard } from '../SensorCard'
import { ISensor } from '~/types/models/ISensor'

import './styles.scss'
import { When } from 'react-if'
import { arrowBackOutline } from 'ionicons/icons'
import { TimeRange } from '../ConcordForm'
import { LineChart } from '../LineChart'
import _ from 'lodash'
import isDateInRange from '~/utils/isDateInRange'
import isNumber from '~/utils/isNumber'
import { ILoad } from '~/types/models/ILoad'
import { useQueryLoadTimes } from '~/hooks/useQueryData/useQueryLoadTimes'
import { ELoadStatus } from '~/types/enums/ELoadStatus'
import { Alert, Form } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
  Filler,
} from 'chart.js'
import generateRandomHexColor from '~/utils/generateRandomHexColor'
import { produce } from 'immer'
import { GraphIcon, RefreshIcon } from '../SvgIcons'
import annotationPlugin from 'chartjs-plugin-annotation'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import { colord } from 'colord'
import zoomPlugin from 'chartjs-plugin-zoom'
import { ReusableButton } from '../ReusableButton'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin, // Register the annotation plugin
  zoomPlugin,
)

interface ISensorsListProps {
  truck?: ITruck | null
  load?: ILoad
  showLineGraph?: boolean
  setShowLineGraph?: React.Dispatch<React.SetStateAction<boolean>>
}

function SensorsList(props: ISensorsListProps) {
  const { truck, load, showLineGraph, setShowLineGraph } = props

  const chartRef = useRef<any>(null)

  const [show, setShow] = useState(false)
  const [sensorSelected, setSensorSelected] = useState<ISensor | null>(null)
  const [dateRange, setDateRange] = useState({
    startTime: load?.ticketTime as string | undefined,
    endTime: undefined as string | undefined,
  })
  const [hasDataChangesOnly, setHasDataChangesOnly] = useState(false)
  const [lineGraphSelected, setLineGraphSelected] = useState<string[]>([])
  const [localShowLineGraph, setLocalShowLineGraph] = useState(false)
  const [zoomed, setZoomed] = useState(false)

  const timeRange = useMemo(
    () => ({
      startTime: dateRange.startTime
        ? moment(dateRange.startTime).format('HH:mm')
        : '',
      endTime: dateRange.endTime
        ? moment(dateRange.endTime).format('HH:mm')
        : '',
    }),
    [dateRange.endTime, dateRange.startTime],
  )

  const { loadTimesMapped, isLoadTimesDataFetched, isLoadTimesDataLoading } =
    useQueryLoadTimes({
      filters: {
        loadId: load?.id as number,
      },
    })

  const { getLoadStatus } = useQueryLoadStatuses()

  const deliveryTime = useMemo(() => {
    if (isLoadTimesDataFetched) {
      const obj = loadTimesMapped.find(
        ({ name }) => name === ELoadStatus.deliveryComplete,
      )

      return obj?.time
    }

    return undefined
  }, [isLoadTimesDataFetched, loadTimesMapped])

  const ticketTime = useMemo(() => {
    if (isLoadTimesDataFetched) {
      const obj = loadTimesMapped.find(
        ({ name }) => name === ELoadStatus.ticketed,
      )

      if (obj) {
        return obj?.time
      }

      if (loadTimesMapped[0]) {
        return loadTimesMapped[0]?.time
      }
      return load?.createdAt
    }
    undefined
  }, [isLoadTimesDataFetched, load?.createdAt, loadTimesMapped])

  const apiParams = useMemo(() => {
    if (sensorSelected) {
      return {
        ...dateRange,
        sensorCodes: [sensorSelected.code],
        loadId: load?.id as number,
        limit: 300,
        dataChangesOnly: hasDataChangesOnly,
      }
    }

    return {
      ...dateRange,
      limit: 300,
      loadId: load?.id as number,
    }
  }, [dateRange, hasDataChangesOnly, load?.id, sensorSelected])

  const {
    sensorsData,
    isSensorsDataFetched,
    isSensorsDataLoading,
    isSensorsDataFetching,
    refetchSensorsData,
  } = useQuerySensors(
    {
      sensor: apiParams,
      truckId: truck?.id as number,
    },
    {
      enabled: Boolean(
        truck?.id && truck?.sensorSource && isLoadTimesDataFetched && show,
      ),
      staleTime: Infinity,
    },
  )

  const colorsInLineGraph = useMemo(() => {
    if (isSensorsDataFetched) {
      const colors = sensorsData.map(() => {
        const color = generateRandomHexColor()
        return colord(color).darken(0.2).toHex()
      })

      return colors
    }

    return []
  }, [isSensorsDataFetched, sensorsData])

  const [firstSensorData] = sensorsData

  const xLabels = useMemo(() => {
    const start = moment(dateRange.startTime)
    const end = dateRange.endTime ? moment(dateRange.endTime) : moment()

    const timestamps: string[] = []
    let current = start.clone()
    while (current.isSameOrBefore(end)) {
      timestamps.push(current.toISOString(true))
      current.add(3, 'minutes')
    }

    return timestamps
  }, [dateRange.endTime, dateRange.startTime])

  const removeDuplicatesByOrder = (data: Record<string, string[]>) => {
    const seen = new Set()
    const result: Record<string, string[]> = {}

    for (const key of Object.keys(data)) {
      result[key] = data[key].filter((timestamp, _, arr) => {
        if (seen.has(timestamp)) {
          return arr.length === 1
        }
        seen.add(timestamp)
        return true
      })
    }

    return result
  }

  const truckStatuses = useMemo(() => {
    const obj: Record<string, string[]> = {}
    loadTimesMapped.forEach(({ time, name }, index) => {
      const eventName = name
      const startTime = moment(time)
      const endTime =
        index < loadTimesMapped.length - 1
          ? moment(loadTimesMapped[index + 1].time)
          : null

      obj[eventName] = xLabels.filter(time => {
        const labelTime = moment(time)
        return (
          labelTime.isSameOrAfter(startTime) &&
          (endTime === null || labelTime.isBefore(endTime))
        )
      })
    })

    return removeDuplicatesByOrder(obj)
  }, [loadTimesMapped, xLabels])

  const xAxisColors = useMemo<Record<string, string>>(
    () => ({
      purple: '#b09fe0',
      primary: '#3880ff',
      dark: '#000',
      tertiary: '#5260ff',
      danger: '#fe3a42',
      success: '#2dd36f',
    }),
    [],
  )

  const getLoadTimeColor = useCallback(
    (status: string) => {
      const loadStatusObj = getLoadStatus(status)
      const result = xAxisColors[loadStatusObj?.color as string]
      return result
    },
    [getLoadStatus, xAxisColors],
  )

  const generateAnnotations = useCallback(
    (truckStatuses: Record<string, string[]>, timestamps: string[]) => {
      const annotations = []
      const keys = Object.keys(truckStatuses)
      let previousLabel = null
      let startPoint = null
      let lastIndex = -1

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const values = truckStatuses[key]

        if (values.length > 0) {
          // Find the first and last occurrence index in timestamps
          const firstIndex = timestamps.indexOf(values[0])
          const lastValue = values[values.length - 1]
          const lastIndexForKey = timestamps.indexOf(lastValue)

          if (firstIndex !== -1) {
            if (startPoint !== null && firstIndex === startPoint) {
              previousLabel = [previousLabel, key]
              // ` & ${key}`
            } else {
              // Push previous annotation before updating startPoint
              if (previousLabel !== null) {
                annotations.push({
                  label: previousLabel,
                  startPoint,
                  endPoint: lastIndex,
                  status: key,
                })
              }
              startPoint = firstIndex
              previousLabel = key
            }
            lastIndex = lastIndexForKey
          }
        }
      }

      // Push the last annotation
      if (previousLabel !== null) {
        annotations.push({
          label: previousLabel,
          startPoint,
          endPoint: lastIndex,
          status: previousLabel,
        })
      }

      return annotations.map(({ label, startPoint, endPoint }: any) => {
        const content = endPoint - startPoint > 2 ? label : ''
        const loadTimeColor = getLoadTimeColor(
          Array.isArray(label) ? label[0] : label,
        )
        const color = colord(loadTimeColor || 'white')
          .alpha(0.35)
          .toHex()

        return {
          type: 'box',
          backgroundColor: color,
          borderWidth: 0,
          xMax: endPoint + 0.5,
          xMin: startPoint - 0.5,

          label: {
            drawTime: 'afterDraw',
            display: true,
            content: content,
            color: 'black',
            backgroundColor: 'white',
            padding: {
              top: 5,
              bottom: 5,
              left: 10,
              right: 10,
            },
            position: {
              x: 'center',
              y: 'start',
            },
            font: {
              size: 16,
              weight: 'normal',
            },
          },
        }
      })
    },
    [getLoadTimeColor],
  )

  const annotations = useMemo(
    () => generateAnnotations(truckStatuses, xLabels),
    [generateAnnotations, truckStatuses, xLabels],
  )

  const defaultRanges = useMemo<Record<string, number[]>>(
    () => ({
      temp: [-30, 120],
      air: [0, 20],
      water: [0, 400],
      hydraulic_pressure: [0, 5500],
      rpm: [-20, 20],
      load_level: [0, 12],
      speed: [0, 70],
      revolutions: [0, 2000],
      battery: [0, 100],
      cell_strength: [0, 100],
      load_empty: [0, 1],
      drum_speed: [-20, 20],
      key_switch: [-1, 2],
      drum_empty: [-1, 2],
      volume: [0, 12],
      batch_size: [0, 12],
      water_added: [0, 400],
    }),
    [],
  )

  const getColorByValue = (value: any) => {
    if (value < 15) return 'rgba(255, 99, 132, 0.5)' // Red for values < 10
    if (value < 30) return 'rgba(54, 162, 235, 0.5)' // Blue for values < 30
    if (value < 70) return 'rgba(255, 206, 86, 0.5)' // Yellow for values < 70
    return 'rgba(0, 255, 72, 0.5)' // Green for values >= 70
  }

  const datasets = useMemo(() => {
    const result: any = sensorsData
      .filter(
        sensor =>
          sensor.name.toLowerCase() !== 'location' &&
          sensor.code !== 'truck_status',
      )
      .map((sensor, index) => {
        const range = defaultRanges[sensor.code] || [
          0,
          Math.max(...(sensor.data.map(d => d.value) as any), 10),
        ]
        const sensorValues = xLabels.map((label, idx) => {
          const dataPoint = isDateInRange(
            sensor.data[idx]?.timestamp,
            label,
            label,
          )
            ? sensor.data[idx]?.value
            : 0
          return dataPoint
        })

        const color = colorsInLineGraph[index]

        return {
          label: sensor.name,
          data: sensorValues,
          borderColor: color,
          backgroundColor: 'white',
          tension: 0.4,
          yAxisID: `y-axis-${index}`,
          min: range[0],
          max: range[1],
          color,
          hidden:
            lineGraphSelected.length === 0
              ? false
              : !lineGraphSelected.includes(sensor.name),
        }
      })

    const truckStatusData = sensorsData.find(
      sensor => sensor.code === 'truck_status',
    )

    if (truckStatusData) {
      const sensorTruckStatusValues = xLabels.map((label, idx) => {
        const dataPoint = isDateInRange(
          truckStatusData.data[idx]?.timestamp,
          label,
          label,
        )
          ? truckStatusData.data[idx]?.value
          : 0
        return dataPoint
      })
      const backgroundColors = sensorTruckStatusValues.map(value =>
        getColorByValue(value),
      )

      result.push({
        type: 'bar',
        label: 'Truck Status',
        data: sensorTruckStatusValues,
        backgroundColor: (ctx: any) =>
          backgroundColors[ctx.dataIndex] || 'transparent',
        yAxisID: 'y-axis-status',
        order: 0,
        hidden:
          lineGraphSelected.length === 0
            ? false
            : !lineGraphSelected.includes('Truck Status'),
      })
    }

    return result
  }, [
    colorsInLineGraph,
    defaultRanges,
    lineGraphSelected,
    sensorsData,
    xLabels,
  ])

  const chartData = useMemo(
    () => ({
      labels: xLabels,
      datasets: datasets,
    }),
    [datasets, xLabels],
  )

  const onZoomChart = (event: any) => {
    const chart = event.chart
    chart.update()
    setZoomed(true)
  }

  const chartPlugins: any = useMemo(
    () => ({
      legend: {
        position: 'top',
        onClick(event: any, item: any) {
          setLineGraphSelected(prev =>
            produce(prev, draft => {
              const index = draft.indexOf(item.text)
              if (index !== -1) {
                draft.splice(index, 1)
              } else {
                draft.push(item.text)
              }
            }),
          )
        },
      },
      annotation: {
        annotations: annotations,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
        },
        zoom: {
          drag: {
            enabled: true, // Click and drag to zoom
          },
          wheel: {
            enabled: false,
          },
          mode: 'x', // Zoom in X direction
          onZoom: onZoomChart,
        },
      },
    }),
    [annotations],
  )

  const chartOptions: ChartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: chartPlugins,
      scales: {
        x: {
          // title: {
          //   display: true,
          //   text: 'Time (YYYY-MM-DD)',
          // },
          ticks: {
            callback: function (value) {
              const rawLabel = this.getLabelForValue(value as any)
              return moment(rawLabel).format('H:mm')
            },
          },
        },
        ...Object.fromEntries(
          datasets.map((dataset: any, index: number) => [
            dataset.yAxisID,
            {
              type: 'linear',
              position: index % 2 === 0 ? 'left' : 'right',
              display:
                lineGraphSelected.length === 0
                  ? true
                  : lineGraphSelected.includes(dataset.label),
              title: {
                display: true,
                text: dataset.label,
                color: dataset.color,
                font: {
                  weight: 600,
                },
              },
              ticks: {
                color: dataset.color,
              },
              min: dataset.min,
              max: dataset.max,
              grid: {
                drawOnChartArea: dataset.type !== 'bar',
              },
            },
          ]),
        ),
      },
    }),
    [chartPlugins, datasets, lineGraphSelected],
  )

  const labelsInChart = useMemo(() => {
    if (sensorSelected) {
      return _.orderBy(
        (firstSensorData || sensorSelected).data,
        ['timestamp'],
        ['asc'],
      )
        .filter(({ timestamp }) => {
          return isDateInRange(
            timestamp,
            dateRange.startTime,
            dateRange.endTime,
          )
        })
        .map(({ timestamp }) => moment(timestamp).format('H:mm:ss'))
    }
    return []
  }, [dateRange.endTime, dateRange.startTime, firstSensorData, sensorSelected])

  const dataInChart = useMemo(() => {
    if (sensorSelected) {
      const data = _.orderBy(
        (firstSensorData || sensorSelected).data,
        ['timestamp'],
        ['asc'],
      )
        .filter(({ timestamp }) => {
          return isDateInRange(
            timestamp,
            dateRange.startTime,
            dateRange.endTime,
          )
        })
        .map(({ value }) => {
          if (isNumber(value)) {
            return Number(value)
          }
          return 0
        })
      return [
        {
          data,
          borderColor: sensorSelected.background,
          backgroundColor: sensorSelected.background,
        },
      ]
    }
    return []
  }, [dateRange.endTime, dateRange.startTime, firstSensorData, sensorSelected])

  const onChangeTimeRange = useCallback((newTimeRange: any) => {
    const [startHours, startMinutes] = newTimeRange.startTime.split(':')
    const [endHours, endMinutes] = newTimeRange.endTime.split(':')
    setDateRange(prev => ({
      startTime: startHours
        ? moment(prev.startTime || undefined)
            .set({
              hours: Number(startHours),
              minutes: Number(startMinutes || 0),
            })
            .toISOString()
        : undefined,
      endTime: endHours
        ? moment(prev.endTime || undefined)
            .set({
              hours: Number(endHours),
              minutes: Number(endMinutes || 0),
            })
            .toISOString()
        : undefined,
    }))
  }, [])

  const onSelectSensor = useCallback(
    (sensor: ISensor) => {
      setSensorSelected(prev => {
        if (sensor.code === prev?.code) {
          return null
        }
        return sensor
      })
      setDateRange({
        startTime: ticketTime,
        endTime: deliveryTime,
      })
    },
    [deliveryTime, ticketTime],
  )

  useEffect(() => {
    if (isLoadTimesDataFetched) {
      setDateRange({
        startTime: ticketTime,
        endTime: deliveryTime,
      })
    }
  }, [deliveryTime, isLoadTimesDataFetched, ticketTime])

  useEffect(() => {
    if (!localShowLineGraph) {
      setLineGraphSelected([])
    }

    if (localShowLineGraph) {
      setShow(true)
    }
    setShowLineGraph && setShowLineGraph(localShowLineGraph)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localShowLineGraph])

  useEffect(() => {
    if (!show) {
      setLocalShowLineGraph(false)
    }
  }, [show])

  useEffect(() => {
    if (typeof showLineGraph === 'boolean') {
      setLocalShowLineGraph(showLineGraph)
    }
  }, [showLineGraph])

  return (
    <div>
      <ToggleSection
        label='Sensors'
        style={{ marginLeft: 8, marginTop: 8 }}
        isOpenByDefault={show}
        onChange={(event, checked) => {
          setShow(checked)
        }}
        badges={[
          {
            label: `${sensorsData.length}`,
            isHidden: !isSensorsDataFetched || sensorsData.length === 0,
          },
          {
            isHidden: sensorsData.length <= 0,
            Icon: <GraphIcon color='white' size={12} />,
            color: 'secondary',
            className: 'clickable',
            onClick() {
              setLocalShowLineGraph(prev => !prev)
            },
          },
          {
            Icon: <RefreshIcon color='white' size={12} />,
            color: 'secondary',
            className: clsx('clickable', { isDisabled: isSensorsDataFetching }),
            isHidden: !isSensorsDataFetched,
            onClick() {
              refetchSensorsData()
            },
          },
        ]}
      >
        {!isSensorsDataLoading && sensorsData.length === 0 ? (
          <Alert style={{ fontSize: 13 }} variant='info'>
            No Data Found!
          </Alert>
        ) : null}

        <IonGrid className='SensorsList__container'>
          {isLoadTimesDataLoading || isSensorsDataLoading ? (
            <Skeleton height={20} />
          ) : null}

          {localShowLineGraph && (
            <div style={{ height: 800 }}>
              <Line
                data={chartData}
                options={chartOptions as any}
                height={650}
                ref={chartRef}
              />
            </div>
          )}
          {zoomed && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReusableButton
                onClick={() => {
                  setZoomed(false)
                  chartRef.current.resetZoom()
                  setZoomed(false)
                }}
              >
                <RefreshIcon color='white' />
              </ReusableButton>
            </div>
          )}

          <IonRow>
            {sensorsData.map(sensor => (
              <IonCol
                size={showLineGraph ? '2' : sensorSelected ? '12' : '4'}
                key={`${sensor.code}${sensor.name}`}
                className={clsx('sensorItem clickable', {
                  isExpanded: sensor.code === sensorSelected?.code,
                  isHidden: sensorSelected?.code,
                })}
              >
                <SensorCard
                  sensorData={sensor}
                  load={load}
                  onClick={onSelectSensor}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </ToggleSection>

      <When condition={Boolean(sensorSelected)}>
        <div className='SensorsList__chartHeader'>
          <div className='SensorsList__topLeftChartHeader'>
            <span
              className='backIcon clickable'
              onClick={() => {
                setSensorSelected(null)
              }}
            >
              <IonIcon icon={arrowBackOutline} />
            </span>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
              <span>{sensorSelected?.name}</span>
            </span>
          </div>
          <Form.Check
            className='SensorsList__dataChangesOnlyChk'
            label='Include Data Changes Only'
            checked={hasDataChangesOnly}
            onChange={event => {
              setHasDataChangesOnly(event.target.checked)
            }}
          />
          <TimeRange value={timeRange} onChange={onChangeTimeRange} />
        </div>
        <LineChart
          isLoading={isSensorsDataLoading}
          labels={labelsInChart}
          datasets={dataInChart}
          options={{
            scales: {
              y: {
                ticks: {
                  callback: value => Number(value),
                },
              },
            },
          }}
        />
      </When>
    </div>
  )
}

export default React.memo(SensorsList)
