import type {
  ICommonModel,
  IRangeNumberValue,
  IReduxColumn,
} from './ICommonModel'

export enum ESellerToBuyerTerminalStatus {
  future,
  imminent,
  bid,
  won,
  active,
  complete,
  lost,
}

export interface ISellerToBuyerTerminal extends ICommonModel {
  buyerTerminalCode: null | string
  buyerTerminalId: number
  buyerTerminalName: null | string
  endDate: string | null
  qty: number | null
  sellerId: number
  sellerTerminalId: number
  startDate: string | null
  status: ESellerToBuyerTerminalStatus | null
  groupId: number | null
  workForceId: number | null
  workTypeId: number | null
  attention: string | null
  userAccessId: number | null
}

export interface ISellerToBuyerTerminalGetListResponse {
  sellerToBuyerTerminals: ISellerToBuyerTerminal[]
}

export interface ISellerToBuyerTerminalGetListParams {
  filters?: Partial<{
    buyerTerminalId: number[]
    startDate: string | null
    endDate: string
    qty: IRangeNumberValue
    status: ESellerToBuyerTerminalStatus[]
    sellerId: number[]
    groupId: number[]
    workForceId: number[]
    workTypeId: number[]
    searchWord?: string
  }>
}

export interface ISellerToBuyerTerminalFormValues {
  buyerTerminalId: number | null
  qty: number | null
  sellerId: number | null
  sellerTerminalId: number | null
  startDate: string | null
  endDate: string | null
  status: ESellerToBuyerTerminalStatus | null
  groupId: number | null
  workForceId: number | null
  workTypeId: number | null
  attention: string | null
  userAccessId: number | null
}

export interface ISellerToBuyerTerminalCreatePayload {
  sellerToBuyerTerminal: Partial<ISellerToBuyerTerminalFormValues>
}
export interface ISellerToBuyerTerminalCreateResponse
  extends ISellerToBuyerTerminal {
  errors: string[]
}
export interface ISellerToBuyerTerminalUpdatePayload {
  sellerToBuyerTerminal: Partial<ISellerToBuyerTerminalFormValues>
}
export interface ISellerToBuyerTerminalUpdateResponse
  extends ISellerToBuyerTerminal {
  errors: string[]
}

// redux
export interface ISellerToBuyerTerminalFilter {
  workTypeId: string[]
  date: IRangeNumberValue
  workForceId: string[]
}

export interface ISellerToBuyerTerminalReduxUiSlice {
  columns: IReduxColumn[]
  filters: ISellerToBuyerTerminalFilter
  initialLoad: boolean
}

export type ISellerToBuyerTerminalsReduxActUpdateUiPayload =
  Partial<ISellerToBuyerTerminalReduxUiSlice>

export type ISellerToBuyerTerminalReduxActUpdateUiColumn = {
  column: IReduxColumn
}

export type ISellerToBuyerTerminalReduxActUpdateUiColumns =
  ISellerToBuyerTerminalReduxUiSlice['columns']

export type ISellerToBuyerTerminalReduxActUpdateUiFilter =
  Partial<ISellerToBuyerTerminalFilter>
