import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetPhoneNumbersParams,
  IPhoneNumber,
} from '~/types/models/IPhoneNumber'
import { buildGetUrl } from '~/utils/buildUrl'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { ICommonOption } from '~/types/models/ICommonModel'
import {
  EPhoneNumberTypes,
  EPhoneNumberTypesString,
} from '~/types/enums/EPhoneNumber'
import useModifyPhoneNumbers from './useModifyPhoneNumbers'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import _ from 'lodash'

const useQueryPhoneNumbers = (
  params: Partial<IGetPhoneNumbersParams> = {},
  options?: Partial<UseQueryOptions<IPhoneNumber[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey: [
      'phoneNumbers',
      sessionUser?.id,
      buildGetUrl(apiClient.phoneNumbers.endpoint, params),
    ],
    async queryFn() {
      if (options?.enabled === false) {
        return []
      }
      const response = await apiClient.phoneNumbers.get(params)
      return response.phoneNumbers
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addPhoneNumber, updatePhoneNumber, removePhoneNumber } =
    useModifyPhoneNumbers(params)

  const phoneNumbersData = useMemo(() => data || [], [data])

  const getPhoneNumberTypes = useCallback((type: EPhoneNumberTypes) => {
    switch (type) {
      case EPhoneNumberTypes.ap: {
        return 'AP'
      }
      case EPhoneNumberTypes.ar: {
        return 'AR'
      }
      case EPhoneNumberTypes.general: {
        return 'General'
      }
      case EPhoneNumberTypes.home: {
        return 'Home'
      }
      case EPhoneNumberTypes.hr: {
        return 'HR'
      }
      case EPhoneNumberTypes.mgmt: {
        return 'Mgmt'
      }
      case EPhoneNumberTypes.mobile: {
        return 'Mobile'
      }
      case EPhoneNumberTypes.operations: {
        return 'Operations'
      }
      case EPhoneNumberTypes.purchasing: {
        return 'Purchasing'
      }
      case EPhoneNumberTypes.qc: {
        return 'QC'
      }
      case EPhoneNumberTypes.sales: {
        return 'Sales'
      }
      case EPhoneNumberTypes.work: {
        return 'Work'
      }
    }
  }, [])

  const convertPhoneTypeFromEnumToString = useCallback(
    (type: EPhoneNumberTypes) => {
      switch (type) {
        case EPhoneNumberTypes.ap: {
          return EPhoneNumberTypesString.ap
        }
        case EPhoneNumberTypes.ar: {
          return EPhoneNumberTypesString.ar
        }
        case EPhoneNumberTypes.general: {
          return EPhoneNumberTypesString.general
        }
        case EPhoneNumberTypes.home: {
          return EPhoneNumberTypesString.home
        }
        case EPhoneNumberTypes.hr: {
          return EPhoneNumberTypesString.hr
        }
        case EPhoneNumberTypes.mgmt: {
          return EPhoneNumberTypesString.mgmt
        }
        case EPhoneNumberTypes.mobile: {
          return EPhoneNumberTypesString.mobile
        }
        case EPhoneNumberTypes.operations: {
          return EPhoneNumberTypesString.operations
        }
        case EPhoneNumberTypes.purchasing: {
          return EPhoneNumberTypesString.purchasing
        }
        case EPhoneNumberTypes.qc: {
          return EPhoneNumberTypesString.qc
        }
        case EPhoneNumberTypes.sales: {
          return EPhoneNumberTypesString.sales
        }
        case EPhoneNumberTypes.work: {
          return EPhoneNumberTypesString.work
        }
      }
    },
    [],
  )

  const findPhoneNumber = (condition: Partial<IPhoneNumber>) =>
    _.find(data, condition)

  const getPhoneNumberTypesText = useCallback(
    (address: IPhoneNumber) => {
      const arr: string[] = []
      address.phoneTypes.forEach(val => {
        arr.push(getPhoneNumberTypes(val as EPhoneNumberTypes))
      })

      return arr.join(', ')
    },
    [getPhoneNumberTypes],
  )

  const phoneNumberOptions = useMemo<ICommonOption<{ number: string }>[]>(
    () =>
      phoneNumbersData.map(phoneNumber => {
        const { number, id } = phoneNumber
        return {
          label: `${formatPhoneNumber(number)} - ${getPhoneNumberTypesText(
            phoneNumber,
          )}`,
          value: id,
          number,
        }
      }),
    [getPhoneNumberTypesText, phoneNumbersData],
  )

  const defaultCompanyPhoneNumber = useMemo(
    () =>
      phoneNumbersData.find(({ phoneTypes }) =>
        (phoneTypes as EPhoneNumberTypes[]).includes(EPhoneNumberTypes.hr),
      ),
    [phoneNumbersData],
  )

  const hrPhoneNumber = useMemo(
    () =>
      phoneNumbersData.find(({ phoneTypes }) =>
        (phoneTypes as EPhoneNumberTypes[]).includes(EPhoneNumberTypes.hr),
      ),
    [phoneNumbersData],
  )

  const firstOrHrPhoneNumber = useMemo<IPhoneNumber | undefined>(
    () => hrPhoneNumber || phoneNumbersData[0],
    [hrPhoneNumber, phoneNumbersData],
  )

  const hasHrPhoneNumber = useMemo(
    () => Boolean(hrPhoneNumber),
    [hrPhoneNumber],
  )

  return {
    phoneNumbersData,
    phoneNumberOptions,
    isLoadingPhoneNumbersData: isLoading,
    defaultCompanyPhoneNumber,
    hrPhoneNumber,
    hasHrPhoneNumber,
    isPhoneNumbersFetched: isFetched,
    firstOrHrPhoneNumber,
    convertPhoneTypeFromEnumToString,
    addPhoneNumber,
    updatePhoneNumber,
    removePhoneNumber,
    getPhoneNumberTypesText,
    refetchPhoneNumbersData: refetch,
    findPhoneNumber,
  }
}

export default useQueryPhoneNumbers
