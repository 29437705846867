import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session'
import _ from 'lodash'
import humps from 'humps'
import { toast } from 'react-toastify'
import { toastMessages, toastOptions } from '~/constants/toast-status-text'
import history from '~/utils/history'
import { USER_SCOPE, AUTH_TOKEN } from '~/utils/constants'
import queryString from 'query-string'
import PusherService from '~/services/PusherService'
import { isMobileWebApp } from '~/utils/getCurrentPlatform'
import { routesPaths } from '~/constants/routesPaths'

const saveSessionHeaders = headers => {
  if (headers.get('access-token')) {
    const sessionHeaders = {
      token: headers.get('access-token'),
      uid: headers.get('uid'),
      client: headers.get('client'),
    }
    sessionService.saveSession(sessionHeaders)
  }
}

const handleErrors = response =>
  new Promise((resolve, reject) => {
    if (!response) {
      toast.error(toastMessages.serverError)
      reject(new Error({ message: toastMessages.serverError }))

      return
    }
    saveSessionHeaders(response.headers)
    // Do not consider 404 as an error
    if (response.ok || response.status === 404) {
      resolve(response)

      return
    }
    if (response.status === 403) {
      toast.error(toastMessages.forbidden, toastOptions)

      return
    }
    if (response.status === 401) {
      if (!isMobileWebApp()) {
        const pusherService = new PusherService()
        pusherService.stopBeamsService()
      }
      if (history.location.pathname !== routesPaths.login) {
        sessionService.deleteSession()
        const fullUrl = `${history.location.pathname}${history.location.search}`
        window.location = `${routesPaths.login}?back_url=${encodeURIComponent(
          fullUrl,
        )}`
      }
      return reject(new Error(toastMessages.apiService.unauthorized))
    }

    response
      .json()
      .then(json => {
        const responseError = json || { message: response.statusText }
        toast.error(responseError)
        reject(responseError)
      })
      .catch(() => {
        toast.error(toastMessages.apiService.jsonError)
        reject(new Error({ message: toastMessages.apiService.jsonError }))
      })
  })

const getResponseBody = response => {
  const bodyIsEmpty = response.status === 204
  if (bodyIsEmpty) {
    return Promise.resolve()
  }

  return response.json()
}

const getResponseBlob = response => {
  const bodyIsEmpty = response.status === 204
  if (bodyIsEmpty) {
    return Promise.resolve()
  }

  return response.blob()
}

const getAccessParams = (user, uri) => {
  if (!user) return ''
  let params = {
    user_id: user.id,
    user_token: user.authToken,
  }
  if (uri) {
    const query = queryString.parseUrl(uri).query
    if (query?.user_id) {
      params = {
        user_token: user.authToken,
      }
    }
  }

  if (user.userAccess?.id) {
    const { query } = queryString.parseUrl(uri || '')
    params = {
      ...params,
      user_access_id: user.userAccess.id,
      access_token: user.userAccess.authToken,
    }
    if (!query.scope) {
      params.scope = user.currentScope
    }
  }
  if (
    user.currentScope == USER_SCOPE.driver ||
    user.currentScope == USER_SCOPE.driver_fleet
  ) {
    params = {
      ...params,
      fleet_id: user.driverFleet?.fleetId,
    }
  }
  if (user.currentScope == USER_SCOPE.fleet) {
    params = {
      ...params,
      fleet_id: user.company?.id,
    }
  }
  const urlParams = Object.keys(params).map(key => `${key}=${params[key]}`)

  return urlParams.join('&')
}

class Api {
  async performPdfRequest(uri, apiUrl, requestData = {}, params = '') {
    let url
    const user = await sessionService.loadUser().catch(() => {
      url = `${apiUrl}${uri}`
    })
    const accessParams = getAccessParams(user)

    if (_.isEmpty(url) && uri.match(/\?./) && accessParams) {
      url = `${apiUrl}${uri}&${accessParams}`
    } else if (_.isEmpty(url) && accessParams) {
      url = `${apiUrl}${uri}?${accessParams}`
    }

    if (params) {
      url += params
    }

    return new Promise((resolve, reject) => {
      fetch(url, requestData)
        .then(handleErrors)
        .then(getResponseBlob)
        .then(blob => {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: 'application/pdf' }),
          )
          var tempLink = document.createElement('a')
          tempLink.href = url
          tempLink.setAttribute('download', 'invoices-pdf-export.pdf')
          tempLink.click()
          tempLink.remove()
          Promise.resolve()
        })
        .catch(error => reject(humps.camelizeKeys(error)))
    })
  }

  async performXmlRequest(uri, apiUrl, requestData = {}, params = '') {
    let url
    const user = await sessionService.loadUser().catch(() => {
      url = `${apiUrl}${uri}`
    })
    const accessParams = getAccessParams(user)

    if (_.isEmpty(url) && uri.match(/\?./) && accessParams) {
      url = `${apiUrl}${uri}&${accessParams}`
    } else if (_.isEmpty(url) && accessParams) {
      url = `${apiUrl}${uri}?${accessParams}`
    }

    if (params) {
      url += params
    }

    return new Promise((resolve, reject) => {
      fetch(url, requestData)
        .then(handleErrors)
        .then(getResponseBlob)
        .then(blob => {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: 'text/xml' }),
          )
          var tempLink = document.createElement('a')
          tempLink.href = url
          tempLink.setAttribute('download', 'invoices-xml-export.xml')
          tempLink.click()
          tempLink.remove()
          Promise.resolve()
        })
        .catch(error => reject(humps.camelizeKeys(error)))
    })
  }

  async performCsvRequest(uri, apiUrl, requestData = {}, params = '') {
    let url

    const user = await sessionService.loadUser().catch(() => {
      url = `${apiUrl}${uri}`
    })
    const accessParams = getAccessParams(user)

    if (_.isEmpty(url) && uri.match(/\?./) && accessParams) {
      url = `${apiUrl}${uri}&${accessParams}`
    } else if (_.isEmpty(url) && accessParams) {
      url = `${apiUrl}${uri}?${accessParams}`
    }

    if (params) {
      url += params
    }

    return new Promise((resolve, reject) => {
      fetch(url, requestData)
        .then(handleErrors)
        .then(getResponseBlob)
        .then(blob => {
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: 'text/csv' }),
          )
          window.open(url)
          return Promise.resolve()
        })
        .catch(error => reject(humps.camelizeKeys(error)))
    })
  }

  postFormData(uri, formData, apiUrl = process.env.REACT_APP_API_URL) {
    const requestData = {
      method: 'post',
      headers: {},
      body: formData,
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performRequest(uri, apiUrl, data),
    )
  }

  async performRequest(uri, apiUrl, requestData = {}, params = '') {
    let url
    const user = await sessionService.loadUser().catch(() => {
      url = `${apiUrl}${uri}`
    })
    const accessParams = getAccessParams(user, uri)

    if (_.isEmpty(url) && uri.match(/\?./) && accessParams) {
      url = `${apiUrl}${uri}&${accessParams}`
    } else if (_.isEmpty(url) && accessParams) {
      url = `${apiUrl}${uri}?${accessParams}`
    }

    if (_.isPlainObject(params)) {
      const parseUrl = queryString.parseUrl(url)
      url = queryString.stringifyUrl({
        url: parseUrl.url,
        query: {
          ...parseUrl.query,
          ...params,
        },
      })
    } else {
      url += params
    }

    return new Promise((resolve, reject) => {
      fetch(url, requestData)
        .then(handleErrors)
        .then(getResponseBody)
        .then(response => resolve(humps.camelizeKeys(response)))
        .catch(error => reject(humps.camelizeKeys(error)))
    })
  }

  addTokenHeader(requestData) {
    requestData.headers['AUTH_TOKEN'] = AUTH_TOKEN

    return sessionService
      .refreshFromLocalStorage()
      .then(() =>
        sessionService.loadSession().then(session => {
          const { token, client, uid } = session
          requestData.headers['access-token'] = token
          requestData.headers.client = client
          requestData.headers.uid = uid

          return requestData
        }),
      )
      .catch(() => requestData)
  }

  getCsv(uri, apiUrl = process.env.REACT_APP_API_URL, params = '') {
    const requestData = {
      method: 'get',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'text/csv',
      },
      responseType: 'blob',
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performCsvRequest(uri, apiUrl, data, params),
    )
  }

  getXml(uri, apiUrl = process.env.REACT_APP_API_URL, params = '') {
    const requestData = {
      method: 'get',
      headers: {
        Accept: 'text/xml',
        'Content-Type': 'text/xml',
      },
      responseType: 'blob',
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performXmlRequest(uri, apiUrl, data, params),
    )
  }

  getPdf(uri, apiUrl = process.env.REACT_APP_API_URL, params = '') {
    const requestData = {
      method: 'get',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performPdfRequest(uri, apiUrl, data, params),
    )
  }

  get(uri, apiUrl = process.env.REACT_APP_API_URL, params = '', headers) {
    const requestData = {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performRequest(uri, apiUrl, data, params),
    )
  }

  post(uri, data, apiUrl = process.env.REACT_APP_API_URL, params) {
    const decamelizeData = humps.decamelizeKeys(data)
    const requestData = {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeData),
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performRequest(uri, apiUrl, data, params),
    )
  }

  delete(uri, data, apiUrl = process.env.REACT_APP_API_URL) {
    const decamelizeData = humps.decamelizeKeys(data)
    const requestData = {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeData),
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performRequest(uri, apiUrl, data),
    )
  }

  put(uri, data, apiUrl = process.env.REACT_APP_API_URL) {
    const decamelizeData = humps.decamelizeKeys(data)
    const requestData = {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeData),
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performRequest(uri, apiUrl, data),
    )
  }

  patch(uri, data, apiUrl = process.env.REACT_APP_API_URL) {
    const decamelizeData = humps.decamelizeKeys(data)
    const requestData = {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(decamelizeData),
    }

    return this.addTokenHeader(requestData).then(data =>
      this.performRequest(uri, apiUrl, data),
    )
  }
}

export { Api }

// eslint-disable-next-line
export default new Api()
