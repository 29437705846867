import { PropsWithChildren, useCallback, useState } from 'react'
import CompanyFormContext from './CompanyFormContext'
import { ICompanyFormContextProps } from './type'
import { DialogCompanyForm } from '~/components/shared'

function CompanyFormProvider(props: PropsWithChildren) {
  const { children } = props

  const [showForm, setShowForm] = useState(false)
  const [options, setOptions] = useState<ICompanyFormContextProps>({})

  const onOpenCompanyForm = useCallback((options: ICompanyFormContextProps) => {
    setShowForm(true)
    setOptions(options)
  }, [])

  const onCloseCompanyForm = useCallback(() => {
    setOptions({})
    setShowForm(false)
  }, [])

  const providerValues = {
    onOpenCompanyForm,
    onCloseCompanyForm,
  }

  return (
    <>
      <CompanyFormContext.Provider value={providerValues}>
        {children}
      </CompanyFormContext.Provider>
      <DialogCompanyForm
        isOpen={showForm}
        formData={options.formData as any}
        onClose={onCloseCompanyForm}
        groupsShownByDefault={options.groupsShownByDefault}
        afterModifyEmails={options.afterModifyEmails}
        afterUpdate={options.afterUpdate}
      />
    </>
  )
}

export default CompanyFormProvider
