import { useContext } from 'react'
import CompanyFormContext from './CompanyFormContext'

function useCompanyFormContext() {
  const context = useContext(CompanyFormContext)

  return context
}

export default useCompanyFormContext
