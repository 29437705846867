import {
  ArrowDownIcon,
  ArrowUpIcon,
  DropdownWithCustomChildren,
} from '~/components/shared'

import _ from 'lodash'
import { getYear, getMonth } from 'date-fns'

import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import './styles.scss'

export interface IDatePickerHeaderProps
  extends ReactDatePickerCustomHeaderProps {
  yearRange?: number[]
  selectingDate?: any
}

function DatePickerHeader(props: IDatePickerHeaderProps) {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    yearRange = [1950, getYear(new Date())],
  } = props

  const years = _.range(yearRange[0], yearRange[1] + 1, 1)

  const currentYear = years.includes(getYear(date))
    ? getYear(date)
    : yearRange[1]

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <div className='DatePickerHeader__container'>
      <div className='DatePickerHeader__yearMonthSelectors'>
        <DropdownWithCustomChildren
          className='no-hover'
          options={months.map(option => ({
            value: option,
            label: option.toString(),
          }))}
          menuPortalTarget={document.body}
          value={months[getMonth(date)]}
          onChange={(event, { value }) => {
            changeMonth(months.indexOf(value))
          }}
          showSearchField
          styles={{
            menu: provided => ({
              ...provided,
              minWidth: 120,
            }),
          }}
        >
          {months[getMonth(date)]}
        </DropdownWithCustomChildren>

        <DropdownWithCustomChildren
          className='no-hover'
          options={years.map(option => ({
            value: option,
            label: option.toString(),
          }))}
          menuPortalTarget={document.body}
          value={currentYear}
          onChange={(event, { value }) => {
            changeYear(value as any)
          }}
          showSearchField
          styles={{
            menu: provided => ({
              ...provided,
              minWidth: 120,
            }),
          }}
        >
          {currentYear}
        </DropdownWithCustomChildren>
      </div>

      <div className='DatePickerHeader__buttons'>
        <button
          style={{ marginRight: 8 }}
          onClick={increaseMonth}
          disabled={prevMonthButtonDisabled}
          className='clickable'
        >
          <ArrowDownIcon size={25} />
        </button>

        <button
          onClick={decreaseMonth}
          disabled={nextMonthButtonDisabled}
          className='clickable'
        >
          <ArrowUpIcon size={25} />
        </button>
      </div>
    </div>
  )
}

export default DatePickerHeader
