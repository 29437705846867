import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderTrackingFilters } from '~/redux/selectors'
import type { IUseOrderCardOnMapProps } from './type'
import {
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQuerySchedules,
  useQueryTerminals,
  useQueryUoms,
} from '~/hooks/useQueryData'
import _ from 'lodash'

export const useOrderCardOnMap = (props: IUseOrderCardOnMapProps) => {
  const { load } = props

  const [isShowingOrderCard, setIsShowingOrderCard] = useState(false)

  const filterState = useSelector(selectOrderTrackingFilters)

  const { schedulesDataHavingLoads } = useQuerySchedules({
    filters: _.omit(filterState, 'view'),
    hasLoads: true,
    hasNotes: true,
  })

  const schedule = schedulesDataHavingLoads.find(
    ({ id }) => id === load.scheduleId,
  )

  const { findTerminalById } = useQueryTerminals()

  const { findCompanyById } = useQueryCompanies({})
  const { findBuyerSellerProductById } = useQueryBuyerSellerProducts({
    filters: {
      joinsSellerProduct: true,
    },
  })
  const { findUomById } = useQueryUoms()

  const bsp = findBuyerSellerProductById(load.buyerSellerProductId)
  const uom = findUomById(bsp?.uomId)

  const loadBuyerTerminal = useMemo(
    () => findTerminalById(load.buyerTerminalId),
    [findTerminalById, load.buyerTerminalId],
  )

  const loadBuyer = useMemo(
    () => findCompanyById(load.buyerId),
    [findCompanyById, load.buyerId],
  )

  const percentage = useMemo(() => {
    if (schedule) {
      const result = Number(schedule.qtyCounter) / Number(schedule.qty)
      return Math.floor(result * 100)
    }
    return 0
  }, [schedule])

  const onShowLoadCards = useCallback(() => {
    setIsShowingOrderCard(true)
  }, [])

  return {
    percentage,
    loadBuyerTerminal,
    loadBuyer,
    schedule,
    isShowingOrderCard,
    bsp,
    uom,
    setIsShowingOrderCard,
    onShowLoadCards,
  }
}

export default useOrderCardOnMap
