import { IonButtons } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import {
  Button,
  CommonDialogV2,
  ContainerSearchBar,
  ICommonDialogProps,
} from '~/components/shared'
import PaymentDataAsPlainText from '~/components/shared/ConcordForm/FormData/PaymentsForm/PaymentDataAsPlainText'
import { toastMessages } from '~/constants/toast-status-text'
import { StripePaymentMethodCard } from '~/containers/PaymentMethodsContainer/StripePaymentMethodCard'
import { useQueryCompanies, useQueryPaymentMethods } from '~/hooks/useQueryData'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { IPayment } from '~/types/models/IPayment'
import { IStripePaymentMethod } from '~/types/models/IStripe'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripePaymentMethodElement from '~/containers/PaymentMethodsContainer/StripePaymentMethodElement'
import { useUpdateEffect } from 'react-use'
import useFuzzy from '~/hooks/useFuzzy'
import EmbeddedPaymentsContainer from '~/containers/EmbeddedPaymentsContainer/EmbeddedPaymentsContainer'
import { PaymentMethodsContainer } from '~/containers/PaymentMethodsContainer'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

export interface IProcessPaymentModalProps extends ICommonDialogProps {
  payment: IPayment | undefined
  afterProcessPayment: () => void
}

function ProcessPaymentModal(props: IProcessPaymentModalProps) {
  const { isOpen, payment, afterProcessPayment, ...modalProps } = props

  const [paymentMethodSelected, setPaymentMethodSelected] =
    useState<IStripePaymentMethod | undefined>(undefined)
  const [clientSecret, setClientSecret] = useState('')
  const [isFetchingSetupIntent, setIsFetchingSetupIntent] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [stripeSetupModal, setStripeSetupModal] = useState({
    isOpen: false,
    companyId: undefined as number | undefined,
  })
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { findCompanyById, updateCompany } = useQueryCompanies({})

  const buyer = findCompanyById(payment?.buyerId)
  const seller = findCompanyById(payment?.sellerId)

  const { isPaymentMethodsLoading, paymentMethodsData, refetchPaymentMethods } =
    useQueryPaymentMethods(
      {
        stripe: {
          companyId: buyer?.id as number,
        },
      },
      {
        enabled: Boolean(buyer?.id && buyer?.stripeCustomerId),
      },
    )

  const shouldShowSubmitButton = Boolean(
    buyer?.stripeCustomerId &&
      seller?.stripeConnectAccountUid &&
      seller?.chargesEnabled &&
      paymentMethodSelected,
  )

  const { seachedList, searchValue, onSearch } = useFuzzy(paymentMethodsData, {
    keys: [
      'type',
      'card.brand',
      'card.last4',
      'card.expMonth',
      'card.expYear',
      'usBankAccount.routingNumber',
      'usBankAccount.last4',
    ],
  })

  const onFetchSetupIntent = async () => {
    setIsFetchingSetupIntent(true)
    try {
      const response = await apiClient.stripe.setupIntent({
        stripe: {
          companyId: currentCompany.id,
        },
      })
      setClientSecret(response.setupIntent.clientSecret)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsFetchingSetupIntent(false)
    }
  }

  const onProcessPayment = async () => {
    setIsProcessing(true)
    setBackendError('')
    try {
      if (payment && paymentMethodSelected) {
        const response = await apiClient.stripe.createPaymentIntent({
          stripe: {
            paymentId: payment.id,
            companyId: buyer?.id as number,
            paymentMethodId: paymentMethodSelected.id,
          },
        })
        if (response?.errors?.length) {
          setBackendError(response?.errors[0])
        } else {
          afterProcessPayment()
        }
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    } finally {
      setIsProcessing(false)
    }
  }

  const onCloseStripeSetupModal = async () => {
    setIsProcessing(true)
    try {
      setStripeSetupModal({
        isOpen: false,
        companyId: undefined,
      })
      const response = await apiClient.companies.getById(seller?.id as number)
      updateCompany(response.id, response)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setIsProcessing(false)
    }
  }

  const onClosePaymentMethodModal = () => {
    setShowPaymentMethodModal(false)
  }

  const onOpenPaymentMethodsModal = () => {
    setShowPaymentMethodModal(true)
  }

  const onOpenStripeSetup = () => {
    setStripeSetupModal({
      isOpen: true,
      companyId: seller?.id,
    })
  }

  useUpdateEffect(() => {
    if (!isOpen) {
      setClientSecret('')
      setPaymentMethodSelected(undefined)
    }
  }, [isOpen])

  return (
    <>
      <CommonDialogV2
        isOpen={isOpen}
        isLoading={isPaymentMethodsLoading || isProcessing}
        isHiddenOkButton
        size='lg'
        title='Process Payment'
        {...modalProps}
      >
        {payment && <PaymentDataAsPlainText paymentData={payment} />}

        {!paymentMethodSelected && buyer?.stripeCustomerId ? (
          <Alert variant='info' style={{ fontSize: 13, marginTop: '12px' }}>
            Select one Payment method of <strong>{buyer?.name}</strong>
          </Alert>
        ) : null}

        {(!seller?.stripeConnectAccountUid || !seller?.chargesEnabled) && (
          <Alert style={{ fontSize: 13 }} variant='danger'>
            <span>
              Seller: please complete payment processing setup through
            </span>
            <span>
              <span className='hyperLink' onClick={onOpenStripeSetup}>
                &nbsp;Stripe
              </span>
            </span>
          </Alert>
        )}

        {!buyer?.stripeCustomerId && (
          <Alert style={{ fontSize: 13 }} variant='danger'>
            <div>Buyer: </div>
            <ol style={{ marginBottom: 0 }}>
              <li onClick={onOpenPaymentMethodsModal} className='hyperLink'>
                Setup Stripe customer account
              </li>
              <li>Add payment method</li>
            </ol>
          </Alert>
        )}

        {seller?.stripeConnectAccountUid && (
          <>
            {backendError && (
              <Alert
                variant='danger'
                style={{ fontSize: 13, marginTop: '12px' }}
              >
                {backendError}
              </Alert>
            )}

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ContainerSearchBar
                searchBarValue={searchValue}
                onSearchBarChange={onSearch}
              />
              {buyer?.stripeCustomerId ? (
                <IonButtons>
                  <Button
                    icon={addCircleOutline}
                    onClick={onFetchSetupIntent}
                    loading={isFetchingSetupIntent}
                    tooltipProps={{
                      content: 'Add Payment Method',
                      placement: 'top',
                    }}
                  />
                </IonButtons>
              ) : null}
            </div>

            {clientSecret && (
              <div style={{ marginTop: 4 }}>
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <StripePaymentMethodElement
                    afterCreate={() => {
                      refetchPaymentMethods()
                      setClientSecret('')
                    }}
                    onClose={() => {
                      setClientSecret('')
                    }}
                  />
                </Elements>
              </div>
            )}

            {seachedList.map(paymentMethod => (
              <div
                key={paymentMethod.id}
                style={{
                  display: 'inline-block',
                  opacity:
                    paymentMethodSelected?.id === paymentMethod.id ? 1 : 0.75,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setPaymentMethodSelected(prev => {
                    if (prev?.id === paymentMethod.id) {
                      return undefined
                    }
                    return paymentMethod
                  })
                }}
              >
                <StripePaymentMethodCard data={paymentMethod} />
              </div>
            ))}

            {shouldShowSubmitButton && (
              <Button
                expand='full'
                label='Process'
                loading={isProcessing}
                onClick={onProcessPayment}
              />
            )}
          </>
        )}
      </CommonDialogV2>
      <CommonDialogV2
        isOpen={stripeSetupModal.isOpen}
        onClose={onCloseStripeSetupModal}
        isHiddenOkButton
        fullscreen
      >
        <EmbeddedPaymentsContainer
          hideBody
          companyId={stripeSetupModal.companyId}
          onExit={company => {
            updateCompany(company.id, company)
          }}
        />
      </CommonDialogV2>
      <CommonDialogV2
        isOpen={showPaymentMethodModal}
        onClose={onClosePaymentMethodModal}
        isHiddenOkButton
        fullscreen
      >
        <PaymentMethodsContainer hideBody hideHeader companyId={buyer?.id} />
      </CommonDialogV2>
    </>
  )
}

export default ProcessPaymentModal
