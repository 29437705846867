export enum EPriceEscalationCadence {
  quarterly = 'quarterly',
  annual = 'annual',
  one_time = 'one_time',
}

export enum EPriceEscalationType {
  dollar = 'dollar',
  percent = 'percent',
}

export const PRICE_ESCALATION_TYPE_OPTIONS = [
  {
    label: 'Dollar',
    value: EPriceEscalationType.dollar,
  },
  {
    label: 'Percent',
    value: EPriceEscalationType.percent,
  },
]

export const PRICE_ESCALATION_CADENCE_OPTIONS = [
  {
    label: 'Quarterly',
    value: EPriceEscalationCadence.quarterly,
  },
  {
    label: 'Annual',
    value: EPriceEscalationCadence.annual,
  },
  {
    label: 'One Time',
    value: EPriceEscalationCadence.one_time,
  },
]
