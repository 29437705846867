import { useCallback, useMemo, useState, memo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDrag } from 'react-dnd'
import { IonSpinner } from '@ionic/react'
import _ from 'lodash'
import moment from 'moment'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { ControlledMenu, MenuItem } from '@szhsin/react-menu'

import {
  CompanyAvatar,
  ToolTipOverlay,
  DialogTerminalForm,
  LoadIcon,
  ImageIcon,
  ViewMapIcon,
  TruckIcon,
  DeleteIcon,
  AlarmIcon,
  DragIcon,
  VerticalThreedotsIcon,
  DropdownWithCustomChildren,
  DialogTruckFormData,
  TicketModal,
} from '~/components/shared'
import AssignLoadsMap from '~/components/load/AssignLoads/AssignLoadsMap'
import DialogLoadPdfViewer from '../DialogLoadPdfViewer'
import DialogLoadStatus from '../DialogLoadStatus'

import useLiveTime from './useLiveTime'
import StatusChip from './StatusChip'
import { Badge } from 'react-bootstrap'
import { apiClient } from '~/api/ApiClient'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import { loadSections } from '~/utils/loadUtils'
import { updateDriverFleet as updateDriverFleetInRedux } from '~/redux/actions/driverFleetsActions'

import {
  useQuerySchedules,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import buildFullName from '~/utils/buildFullName'
import { toastMessages } from '~/constants/toast-status-text'
import { useConfirmationProvider } from '~/contexts'
import { EYesNo } from '~/types/enums/ECommonEnum'

import clsx from 'clsx'
import './OrderTrackingStatusCard.scss'
import { updateDriverFleet as updateDriverFleetInStore } from '~/redux/actions/driverFleetsActions'
import { selectOrderTrackingFilters } from '~/redux/selectors'
import getIsJustCreated from '~/utils/getIsJustCreated'

const OrderTrackingStatusCard = props => {
  const {
    column,
    load,
    order,
    onReloadData,
    index,
    draggable,
    removable,
    className,
    onUpdateLoad,
    table,
    afterDeleteLoad,
  } = props

  const {
    status: loadStatus,
    truckFleetId,
    driverFleetId,
    sellerTerminalId,
  } = load

  const [loading, setLoading] = useState(false)
  const [openDialogPdfViewer, setOpenDialogPdfViewer] = useState(false)
  const [openDialogLoadCard, setOpenDialogLoadCard] = useState(false)
  const [openAssignLoadMap, setOpenAssignLoadMap] = useState(false)
  const [dialogLoadStatus, setDialogLoadStatus] = useState({
    open: false,
    statuses: [],
    loading: false,
    isUpdate: false,
  })
  const [modal, setModal] = useState({ show: false, terminal: null })
  const [isTruckFleetModalOpen, setIsTruckFleetModalOpen] = useState(false)

  const [isHighlighted, setIsHighlighted] = useState(false)

  const isJustCreated = useMemo(
    () => (load ? getIsJustCreated(load.createdAt, 60) : false),
    [load],
  )

  const menuButtonRef = useRef('')
  const loadRejectDescriptionRef = useRef()
  const { confirmation } = useConfirmationProvider()
  const dispatch = useDispatch()

  const user = useSelector(state => state.session.user)
  const orderTrackingFilters = useSelector(selectOrderTrackingFilters)

  const { updateLoad, refetchSchedulesData } = useQuerySchedules({
    filters: _.omit(orderTrackingFilters, 'view'),
    hasLoads: true,
    hasNotes: true,
  })

  useLiveTime()

  const { getNextLoadSection, getLoadStatusesInSection } =
    useQueryLoadStatuses()

  const { findUserByDriverFleetId, updateDriverFleet, driverFleetOptions } =
    useQueryUsers()
  const { findTruckFleetById, updateTruckFleet, truckFleetsData } =
    useQueryTruckFleets()
  const {
    sellerTerminalOptions,
    companyTerminalOptions,
    findTerminalById,
    isLoadingTerminals,
  } = useQueryTerminals()

  const truckFleet = findTruckFleetById(truckFleetId)

  const nextColumn = useMemo(() => {
    const columns = [
      'to_seller',
      'at_seller',
      'to_buyer',
      'at_buyer',
      'returning',
      'ready',
    ]
    const { length } = columns
    const index = columns.indexOf(column)
    if (index === length - 1) {
      return columns[length - 1]
    }

    return columns[index + 1]
  }, [column])

  const nextCellDropName = useMemo(
    () => `${order.id}_${_.camelCase(nextColumn)}`,
    [nextColumn, order.id],
  )

  const onCloseTruckFleetModal = () => {
    setIsTruckFleetModalOpen(false)
  }

  const onChangeDriverFleet = async (event, { value }) => {
    setLoading(true)
    try {
      const dfResponse1 = await apiClient.driverFleets.update(
        driverFleetUser.driverFleet.id,
        {
          loadId: null,
          lastLoadId: load.id,
          lastStatusTime: new Date().toISOString(),
        },
      )

      const response = await apiClient.loads.update(load.id, {
        driverFleetId: value,
      })
      const dfResponse2 = await apiClient.driverFleets.update(value, {
        loadId: load.id,
        lastStatusTime: new Date().toISOString(),
      })
      onUpdateLoad && onUpdateLoad(response)
      updateDriverFleet(dfResponse1.id, {
        loadId: null,
        lastLoadId: load.id,
        lastStatusTime: new Date().toISOString(),
      })
      updateDriverFleet(dfResponse2.id, {
        loadId: load.id,
        lastStatusTime: new Date().toISOString(),
      })
      dispatch(
        updateDriverFleetInStore({
          id: dfResponse1.id,
          loadId: null,
          lastLoadId: load.id,
          lastStatusTime: new Date().toISOString(),
        }),
      )
      dispatch(
        updateDriverFleetInStore({
          id: dfResponse2.id,
          loadId: load.id,
          lastStatusTime: new Date().toISOString(),
        }),
      )
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setLoading(false)
    }
  }

  const onChangeTruckFleet = async (event, { value }) => {
    setLoading(true)
    try {
      const response = await apiClient.loads.update(load.id, {
        truckFleetId: value,
      })
      onUpdateLoad && onUpdateLoad(response)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setLoading(false)
    }
  }

  const onOpenTruckFleetModal = () => {
    setIsTruckFleetModalOpen(true)
  }

  const handleDropItem = useCallback(
    async _dropResult => {
      setLoading(true)
      try {
        const { load: loadResponse, errors } = await apiClient.loadTimes.create(
          {
            loadStatusCode: getNextLoadSection(loadStatus).code,
            loadTime: {
              loadId: load.id,
            },
          },
        )
        if (errors.length > 0) {
          toast.error(errors[0])
        } else {
          onUpdateLoad &&
            onUpdateLoad({
              id: loadResponse.loadId,
              status: loadResponse.currentStatus,
              updatedAt: loadResponse.updatedAt,
            })
          toast.success('Load status updated successfully')
        }
      } catch {
        toast.error('There was a problem while trying to get data')
      } finally {
        setLoading(false)
      }
    },
    [getNextLoadSection, load.id, loadStatus, onUpdateLoad],
  )

  const drag = useDrag(
    () => ({
      type: nextCellDropName,
      item: { load, orderId: order.id },
      canDrag: () => draggable,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_item, monitor) => {
        if (monitor.didDrop()) {
          handleDropItem()
        }
      },
      // isDragging: () => console.log('nextCellDropName', nextCellDropName),
    }),
    [load, order.id, nextCellDropName],
  )[1]

  const driverFleetUser = findUserByDriverFleetId(driverFleetId)

  const driverTooltip = useMemo(
    () => `Driver: ${buildFullName(driverFleetUser?.person)}`,
    [driverFleetUser?.person],
  )

  const sellerTerminal = findTerminalById(sellerTerminalId)

  const truckFleetTerminal = findTerminalById(truckFleet?.terminalId)

  const isDisabledViewTicketImage = useMemo(
    () => !load.loadImage || !load.imgThumbnailUrl || !load.loadPdf,
    [load],
  )

  const isDisplayCard = useMemo(() => {
    if (!column) {
      return true
    }
    const arrStatus = getLoadStatusesInSection(
      loadSections[column]?.statusName,
    ).map(status => status.name)

    if (arrStatus) return arrStatus.includes(loadStatus)
  }, [column, getLoadStatusesInSection, loadStatus])

  const handleOpenViewTicketDialog = useCallback(() => {
    setOpenDialogPdfViewer(true)
  }, [])

  const handleCloseDialogPdfViewer = useCallback(() => {
    setOpenDialogPdfViewer(false)
  }, [])

  const handleOpenDialogLoadCard = useCallback(() => {
    setOpenDialogLoadCard(true)
  }, [])

  const handleOpenAssignLoadMap = useCallback(() => {
    setOpenAssignLoadMap(true)
  }, [])

  const handleCloseAssignLoadMap = useCallback(() => {
    setOpenAssignLoadMap(false)
  }, [])

  const handleOpenStatusList = useCallback(async () => {
    setDialogLoadStatus(prev => ({ ...prev, open: true, loading: true }))
    try {
      const data = await apiClient.loads.getById(load.id)
      setDialogLoadStatus(prev =>
        produce(prev, draft => {
          draft.statuses = data.loadTimes || []
          draft.loading = false
        }),
      )
    } catch {
      toast.error('There was a problem while trying to get data')
      handleCloseDialogLoadStatus()
    }
  }, [load.id, handleCloseDialogLoadStatus])

  const handleOpenStatusListToUpdate = useCallback(async () => {
    setDialogLoadStatus(prev => ({ ...prev, open: true, loading: true }))
    try {
      const data = await apiClient.loads.getById(load.id)
      setDialogLoadStatus(prev =>
        produce(prev, draft => {
          draft.statuses = data.nextOptions || []
          draft.loading = false
          draft.isUpdate = true
        }),
      )
    } catch {
      toast.error('There was a problem while trying to get data')
      handleCloseDialogLoadStatus()
    }
  }, [load.id, handleCloseDialogLoadStatus])

  const handleCloseDialogLoadStatus = useCallback(() => {
    setDialogLoadStatus(prev =>
      produce(prev, draft => {
        draft.open = false
        draft.isUpdate = false
        draft.loading = false
        draft.statuses = []
      }),
    )
  }, [])

  const handleDeleteLoad = useCallback(async () => {
    try {
      const result = await confirmation({
        message: 'Do you want to delete this load',
        showInput: true,
        inputPlaceholder: 'Reason: ',
        onChangeInput(event, input) {
          loadRejectDescriptionRef.current = input
        },
      })
      if (result === EYesNo.Yes) {
        const response = await apiClient.loads.delete(load.id, {
          load: {
            rejectDescription: loadRejectDescriptionRef.current,
          },
        })
        if (response?.errors?.length > 0) {
          toast.error(response.errors[0])
        } else {
          apiClient.driverFleets.update(load.driverFleetId, {
            loadId: null,
          })
          dispatch(
            updateDriverFleetInRedux({
              id: load.driverFleetId,
              loadId: null,
            }),
          )
          updateDriverFleet(load.driverFleetId, {
            loadId: null,
          })

          afterDeleteLoad && afterDeleteLoad(load)
          toast.success('Load deleted successfully')
        }
      }
      loadRejectDescriptionRef.current = ''
    } catch {
      toast.error('There was a problem while trying to delete load')
    }
  }, [confirmation, load, dispatch, updateDriverFleet, afterDeleteLoad])

  const changeOrderSellerTerminal = useCallback(
    async (event, { value }) => {
      try {
        const res = await apiClient.loads.update(load.id, {
          sellerTerminalId: value,
        })
        await apiClient.scheduleLoads.update(load.scheduleLoadId, {
          scheduleLoad: {
            beginSellerTerminalId: value,
          },
        })
        onUpdateLoad &&
          onUpdateLoad({
            id: res.id,
            sellerTerminalId: res.sellerTerminalId,
            updatedAt: res.updatedAt,
          })
        toast.success('Load seller terminal updated successfully')
      } catch (error) {
        toast.error('There was a problem while trying to update order')
      }
    },
    [load.id, load.scheduleLoadId, onUpdateLoad],
  )

  const updateLoadStatus = useCallback(
    async (event, { value }) => {
      try {
        const { load: loadResponse, errors } = await apiClient.loadTimes.create(
          {
            loadStatusCode: value,
            loadTime: {
              loadId: load.id,
              time: new Date().toISOString(),
            },
          },
        )

        if (errors.length === 0) {
          onUpdateLoad &&
            onUpdateLoad({
              id: loadResponse.loadId,
              status: loadResponse.currentStatus,
              updatedAt: loadResponse.updatedAt,
            })
          toast.success('Load status updated successfully')
        }
      } catch (error) {
        toast.error('There was a problem while trying to update load')
      }
    },
    [load.id, onUpdateLoad],
  )

  const changeTruckFleetTerminal = useCallback(
    async (event, { value }) => {
      try {
        const res = await apiClient.truckFleets.update(truckFleet.id, {
          truckFleet: {
            terminalId: value,
          },
        })
        updateTruckFleet(res.id, res)
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        toast.error('There was a problem while trying to update order')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [truckFleet?.id],
  )

  const contextMenu = useMemo(
    () => [
      {
        label: 'View Ticket Image',
        icon: <ImageIcon />,
        onClick: handleOpenViewTicketDialog,
        disabled: isDisabledViewTicketImage,
      },
      {
        label: 'View Ticket Data',
        icon: <LoadIcon />,
        onClick: handleOpenDialogLoadCard,
      },
      {
        label: 'View Map',
        icon: <ViewMapIcon />,
        disabled: _.size(load) === 0,
        onClick: handleOpenAssignLoadMap,
      },
      {
        label: 'Update Status',
        icon: <TruckIcon />,
        onClick: handleOpenStatusListToUpdate,
        hide: user.currentScope !== 'driver',
      },
      {
        label: 'View Status Times',
        icon: <AlarmIcon />,
        hide: user.currentScope !== 'driver',
        onClick: handleOpenStatusList,
      },
      {
        label: 'Delete Load',
        icon: <DeleteIcon />,
        style: { color: 'red' },
        hide: !removable,
        onClick: handleDeleteLoad,
      },
    ],
    [
      handleOpenViewTicketDialog,
      isDisabledViewTicketImage,
      handleOpenDialogLoadCard,
      load,
      handleOpenAssignLoadMap,
      handleOpenStatusListToUpdate,
      handleOpenStatusList,
      user,
      handleDeleteLoad,
      removable,
    ],
  )

  const minutesAgo = () => {
    const start = moment(load.updatedAt)
    const end = moment()
    const duration = moment.duration(end.diff(start))

    return parseInt(duration.asMinutes()) + 'm'
  }

  const renderTimeText = () => {
    const orderTime = moment(order.updatedAt).format('H:mm')
    const loadTime = moment(load.updatedAt).format('H:mm')
    const etaTime = load?.eta

    let text = 'Status Last Updated At: '
    text += orderTime
    text += '\r\nLoad last Updated At: '
    text += loadTime
    if (orderTime != loadTime) {
      text += '\r\nCreated At: ' + moment(load.createdAt).format('H:mm')
    }
    text += '\r\nETA: ' + (etaTime || '-') + ' mins'
    return text
  }

  const rightClick = (e, terminal) => {
    e.stopPropagation()
    e.preventDefault()
    setModal({ show: true, terminal })
  }

  useEffect(() => {
    setIsHighlighted(isJustCreated)
  }, [isJustCreated])

  useEffect(() => {
    if (isHighlighted) {
      setTimeout(() => {
        setIsHighlighted(false)
      }, 60000) // 1 min
    }
  }, [isHighlighted])

  if (!isDisplayCard || _.size(load) === 0) return null

  return (
    <>
      <div
        className={clsx('OrderTrackingStatusCard__container', className, {
          firstItem: index === 0,
          isHighlighted,
        })}
        ref={drag}
      >
        {draggable && (
          <div style={{ marginRight: 6 }}>
            <DragIcon className=' OrderTrackingStatusCard__rightIcon drag' />
          </div>
        )}

        {loading && <IonSpinner />}

        {loading && (
          <div className='OrderTrackingStatusCard__loadingOverlay'>
            <IonSpinner name='lines-small' /> Updating status...
          </div>
        )}

        {driverFleetUser && (
          <DropdownWithCustomChildren
            className='make-custom-dropdown-inline no-hover'
            options={driverFleetOptions}
            value={driverFleetUser?.driverFleet?.id}
            onChange={onChangeDriverFleet}
            menuPortalTarget={document.body}
          >
            <CompanyAvatar
              companyType='driver'
              height={18}
              width={18}
              tooltipMessage={driverTooltip}
              tooltipProps={{
                placement: 'top',
              }}
              company={{
                name: buildFullName(driverFleetUser?.person),
                value: driverFleetUser?.id,
                image: driverFleetUser?.avatar,
              }}
              className='me-1'
            />
          </DropdownWithCustomChildren>
        )}

        <DropdownWithCustomChildren
          className='make-custom-dropdown-inline no-hover'
          options={truckFleetsData.map(({ id, truck }) => ({
            value: id,
            label: truck.name,
          }))}
          value={truckFleet?.id}
          onChange={onChangeTruckFleet}
          menuPortalTarget={document.body}
        >
          <ToolTipOverlay content='Truck' placement='top'>
            <Badge
              pill
              style={{ marginRight: 2 }}
              onContextMenu={onOpenTruckFleetModal}
            >
              {truckFleet?.truck?.name || '-'}
            </Badge>
          </ToolTipOverlay>
        </DropdownWithCustomChildren>

        {load.qty && (
          <ToolTipOverlay
            content={
              <div>
                <div>LD Qty: {Number(load.qty)}</div>
                <div>
                  For Schedule: {Number(load.orderQty)} - {Number(order.qty)}
                </div>
              </div>
            }
            placement='top'
          >
            <Badge pill className='me-1'>
              {Number(load.qty)} → {Number(load.orderQty)}
            </Badge>
          </ToolTipOverlay>
        )}

        <ToolTipOverlay content={renderTimeText()} placement='top'>
          <Badge pill className='me-1'>
            {minutesAgo()}
          </Badge>
        </ToolTipOverlay>

        <StatusChip
          status={loadStatus}
          onChangeStatus={updateLoadStatus}
          loadId={load?.id}
        />

        <DropdownWithCustomChildren
          onChange={changeOrderSellerTerminal}
          options={sellerTerminalOptions}
          value={sellerTerminalId}
          className='make-custom-dropdown-inline no-hover me-1'
          isLoading={isLoadingTerminals}
          menuPortalTarget={document.body}
        >
          <CompanyAvatar
            tooltipMessage={`Delivery Picked Up From: ${sellerTerminal?.name}`}
            tooltipProps={{
              placement: 'top',
            }}
            company={{
              name: sellerTerminal?.name || '-',
              value: sellerTerminal?.id || null,
            }}
            companyType='fleet'
            height={18}
            width={18}
            onContextMenu={e => rightClick(e, sellerTerminal)}
          />
        </DropdownWithCustomChildren>

        <DropdownWithCustomChildren
          onChange={changeTruckFleetTerminal}
          options={companyTerminalOptions}
          value={truckFleet?.terminalId}
          className='make-custom-dropdown-inline me-1 no-hover'
          menuPortalTarget={document.body}
        >
          <CompanyAvatar
            tooltipMessage={`Driver / Truck Return To: ${
              truckFleetTerminal?.name || 'Unknown'
            }`}
            tooltipProps={{
              placement: 'top',
            }}
            company={{
              name: truckFleetTerminal?.name || '-',
              value: truckFleetTerminal?.id || null,
            }}
            companyType='fleet'
            height={18}
            width={18}
            onContextMenu={e => rightClick(e, truckFleetTerminal)}
          />
        </DropdownWithCustomChildren>

        <span
          ref={menuButtonRef}
          onClick={() => {
            table.setOutsideElement(prev =>
              prev ? null : (
                <ControlledMenu
                  state='open'
                  anchorPoint={{
                    x: menuButtonRef.current?.getBoundingClientRect()?.x || 0,
                    y:
                      menuButtonRef.current?.getBoundingClientRect()?.bottom ||
                      0,
                  }}
                  onClose={() => {
                    table.setOutsideElement(null)
                  }}
                >
                  {contextMenu
                    .filter(({ hide }) => !hide)
                    .map(({ label, icon, onClick, disabled }) => (
                      <MenuItem
                        key={label}
                        className={clsx({ disabled })}
                        onClick={event => {
                          event.syntheticEvent.stopPropagation()
                          onClick()
                          table.setOutsideElement(null)
                        }}
                      >
                        {icon}{' '}
                        <span style={{ marginLeft: 4, fontSize: 13 }}>
                          {label}
                        </span>
                      </MenuItem>
                    ))}
                </ControlledMenu>
              ),
            )
          }}
        >
          <VerticalThreedotsIcon className='OrderTrackingStatusCard__rightIcon menu' />
        </span>
      </div>

      {!isDisabledViewTicketImage && (
        <DialogLoadPdfViewer
          load={load}
          open={openDialogPdfViewer}
          onClose={handleCloseDialogPdfViewer}
        />
      )}

      <TicketModal
        isOpen={openDialogLoadCard}
        onClose={() => setOpenDialogLoadCard(false)}
        load={load}
        canEdit
        afterUpdateload={load => {
          updateLoad(load.id, load)
          refetchSchedulesData()
        }}
      />

      <AssignLoadsMap
        showModal={openAssignLoadMap}
        load={load}
        onDismissModal={handleCloseAssignLoadMap}
      />

      <DialogLoadStatus
        {...dialogLoadStatus}
        load={load}
        onReloadData={onReloadData}
        onClose={handleCloseDialogLoadStatus}
      />

      <DialogTerminalForm
        isOpen={modal.show}
        onClose={() => {
          setModal({ show: false })
        }}
        formData={modal.terminal}
      />
      <DialogTruckFormData
        isOpen={isTruckFleetModalOpen}
        truck={truckFleet?.truck}
        truckFleet={truckFleet}
        onClose={onCloseTruckFleetModal}
        afterCreate={onCloseTruckFleetModal}
        afterUpdate={onCloseTruckFleetModal}
      />
    </>
  )
}

// OrderTrackingStatusCard.propTypes = {
//   column: PropTypes.string,
//   loadStatus: PropTypes.string,
//   onReloadData: PropTypes.func,
//   index: PropTypes.number,
//   draggable: PropTypes.bool,
// }

OrderTrackingStatusCard.defaultProps = {
  draggable: true,
  clickable: true,
  removable: true,
}

export default memo(OrderTrackingStatusCard)
