import { ScheduleLoadsRow } from './ScheduleLoadsRow'

export const ScheduleLoadsFields = ({ fields, ...props }) => {
  const isLast = index => index === fields.length - 1

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id}>
          {fields.length > 1 && <hr style={{ borderWidth: 2 }} />}
          <ScheduleLoadsRow
            index={index}
            {...props}
            appendSchedule={isLast(index) ? props.appendSchedule : null}
          />
        </div>
      ))}
    </div>
  )
}
