import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'

import { DropdownWithCustomChildren, ToolTipOverlay } from '~/components/shared'
import { Badge } from 'react-bootstrap'

const StatusChip = props => {
  const { status, loadId, onChangeStatus } = props

  const [show, setShow] = useState(false)
  const { getLoadStatus, getLoadStatusOptionsBySection } =
    useQueryLoadStatuses()

  const codeStatusStr = () => getLoadStatus(status)?.code

  const statusOptions = useMemo(
    () => getLoadStatusOptionsBySection(status),
    [getLoadStatusOptionsBySection, status],
  )

  return (
    <DropdownWithCustomChildren
      options={statusOptions}
      value={status}
      onChange={onChangeStatus}
      className='make-custom-dropdown-inline no-hover'
      menuPortalTarget={document.body}
    >
      <ToolTipOverlay content={status} placement='top'>
        <Badge
          pill
          onClick={() => setShow(!show)}
          className='me-1 shimmer-div'
          key={`load${loadId}--status--${status}`}
        >
          {codeStatusStr()}
        </Badge>
      </ToolTipOverlay>
    </DropdownWithCustomChildren>
  )
}

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func,
  className: PropTypes.string,
  loadId: PropTypes.number,
}

export default StatusChip
