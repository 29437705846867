import {
  CommonDialogV2,
  DropdownWithCustomChildren,
  GoogleMap,
  MapDirection,
  ReusableButton,
  TerminalMarker1,
  ToolTipOverlay,
  TruckInfoBox,
  useDeliveryRoutes,
  ZoomIcon,
} from '~/components/shared'

import './AssignLoadsMap.scss'
import {
  useQueryBuyerSellerProducts,
  useQueryLocations,
  useQueryTerminals,
  useQueryTruckFleets,
} from '~/hooks/useQueryData'
import { ELocationableType } from '~/types/enums/ELocation'
import { Badge } from 'react-bootstrap'
import buildObjectName from '~/utils/buildObjectName'
import { useCallback, useEffect, useMemo, useState } from 'react'
import getGoogleLocation from '~/utils/getGoogleLocation'
import moment from 'moment'
import { OrderCardOnMap } from '~/components/maps/FleetMap/OrderCardOnMap'

const AssignLoadsMap = ({
  load,
  truckFleet: truckFleetProp,
  showModal,
  onDismissModal,
}) => {
  const [pathInforFromTruckToPickUp, setPathInforFromTruckToPickUp] =
    useState(null)
  const [zoomTo, setZoomTo] = useState('allMarkers')

  const { findTruckFleetById, isLoadingTruckFleetsData } = useQueryTruckFleets()

  const isTruckOnLoad = Boolean(load)

  const calculateTravelTime = useCallback(travelTime => {
    if (travelTime) {
      const result = Math.ceil(travelTime / 60)
      return result
    }
    return null
  }, [])

  const calculateDistance = useCallback(distance => {
    if (distance) {
      const result = distance / 1609.34
      return Number(result.toFixed(2))
    }
    return null
  }, [])

  const infoOfPathFromTruckToPickUp = useMemo(() => {
    if (pathInforFromTruckToPickUp) {
      const travelTime = calculateTravelTime(
        pathInforFromTruckToPickUp.travelTime,
      )
      const distance = calculateDistance(pathInforFromTruckToPickUp.distance)
      const eta = travelTime
        ? moment().add({ minute: travelTime }).format('HH:mm')
        : null

      const list = [
        {
          label: 'Travel time',
          value: `${travelTime} mins`,
          isHidden: !travelTime,
        },
        {
          label: 'ETA',
          value: eta,
          isHidden: !eta,
        },
        {
          label: 'Distance',
          value: `${distance} mi`,
          isHidden: !distance,
        },
      ]

      return list
        .filter(({ isHidden }) => !isHidden)
        .map(({ label, value }) => (
          <div key={label} style={{ marginTop: 4 }}>
            <span>{label}: </span>
            <span>{value}</span>
          </div>
        ))
    }
    return null
  }, [calculateDistance, calculateTravelTime, pathInforFromTruckToPickUp])

  const { findTerminalById } = useQueryTerminals(
    {},
    { enabled: !isTruckOnLoad },
  )

  const truckFleet = load
    ? findTruckFleetById(load?.truckFleetId)
    : truckFleetProp

  const { findBuyerSellerProductById, isLoadingBuyerSellerProducts } =
    useQueryBuyerSellerProducts(
      {
        filters: {
          joinsSellerProduct: true,
        },
      },
      {
        enabled: Boolean(load?.buyerSellerProductId && showModal),
        refetchInterval: 60000,
      },
    )

  const buyerSellerProduct = findBuyerSellerProductById(
    load?.buyerSellerProductId,
  )

  const {
    locationsData: [truckLocation],
    isLocationsDataLoading,
  } = useQueryLocations(
    {
      filters: {
        locationableId: truckFleet?.truckId,
        locationableType: ELocationableType.Truck,
      },
    },
    {
      enabled: Boolean(truckFleet?.truckId && showModal),
      refetchInterval: 60000,
    },
  )
  const truckPickUpTerminal = findTerminalById(truckFleet?.terminalId)
  const truckParkTerminal = findTerminalById(truckFleet?.truck?.parkTerminalId)

  const truckPickUpTerminalLocation = getGoogleLocation(
    truckPickUpTerminal?.location?.lat,
    truckPickUpTerminal?.location?.lng,
  )
  const truckParkTerminalLocation = getGoogleLocation(
    truckParkTerminal?.location?.lat,
    truckPickUpTerminal?.location?.lng,
  )
  const truckGoogleLocation = getGoogleLocation(
    truckLocation?.lat,
    truckLocation?.lng,
  )

  const isPickUpTheSameParking =
    truckPickUpTerminal?.id === truckParkTerminal?.id

  const truckFleetBounding = useMemo(() => {
    const bounding = []
    const truck = getGoogleLocation(truckLocation?.lat, truckLocation?.lng)
    ;[truck, truckPickUpTerminalLocation, truckParkTerminalLocation]
      .filter(Boolean)
      .forEach(location => {
        bounding.push(location)
      })

    return bounding
  }, [
    truckLocation?.lat,
    truckLocation?.lng,
    truckParkTerminalLocation,
    truckPickUpTerminalLocation,
  ])

  const isModalLoading =
    isLoadingTruckFleetsData ||
    isLoadingBuyerSellerProducts ||
    isLocationsDataLoading

  const {
    renderDeliveryRoutes,
    truckGeofence,
    sellerTerminalLocation,
    buyerTerminalLocation,
    returnTerminalLocation,
    endLocation,
  } = useDeliveryRoutes({
    loadId: load?.id,
    isShow: Boolean(load?.id && truckLocation),
    truckLocation,
    load,
  })

  const boundingDeliveryRoute = useMemo(() => {
    const bounding = []
    if (zoomTo === 'allMarkers') {
      if (truckGeofence) {
        bounding.push(truckGeofence)
      }
      if (sellerTerminalLocation) {
        bounding.push(sellerTerminalLocation)
      }
      if (buyerTerminalLocation) {
        bounding.push(buyerTerminalLocation)
      }
      if (returnTerminalLocation) {
        bounding.push(returnTerminalLocation)
      }
    } else if (zoomTo === 'truckAndDestination') {
      if (truckGeofence) {
        bounding.push(truckGeofence)
      }
      if (endLocation) {
        bounding.push(endLocation)
      }
    } else {
      if (truckGeofence) {
        bounding.push(truckGeofence)
      }
    }

    return bounding
  }, [
    buyerTerminalLocation,
    returnTerminalLocation,
    sellerTerminalLocation,
    truckGeofence,
    zoomTo,
    endLocation,
  ])

  useEffect(() => {
    return () => {
      setZoomTo('allMarkers')
    }
  }, [])

  return (
    <CommonDialogV2
      isOpen={showModal}
      onClose={onDismissModal}
      isHiddenOkButton
      size='lg'
      isLoading={isModalLoading}
      title={
        <div>
          {load && <Badge style={{ fontSize: 13 }}>LD # {load?.num}</Badge>}
          {buyerSellerProduct && (
            <ToolTipOverlay content='Product' placement='top'>
              <Badge style={{ fontSize: 13, marginLeft: 8 }} bg='danger'>
                {buildObjectName({
                  name: buyerSellerProduct?.sellerProductName,
                  code: buyerSellerProduct?.sellerProductCode,
                })}
              </Badge>
            </ToolTipOverlay>
          )}
          {isTruckOnLoad && (
            <DropdownWithCustomChildren
              className='make-custom-dropdown-inline no-hover'
              showSearchField={false}
              options={[
                {
                  label: 'Zoom to all markers',
                  value: 'allMarkers',
                },
                {
                  label: 'Zoom to truck and next destination',
                  value: 'truckAndDestination',
                },
                {
                  label: 'Zoom to Truck',
                  value: 'truck',
                },
              ]}
              value={zoomTo}
              onChange={(event, { value }) => {
                setZoomTo(value)
              }}
            >
              <ReusableButton style={{ marginLeft: 8 }}>
                <ZoomIcon color='white' />
              </ReusableButton>
            </DropdownWithCustomChildren>
          )}
        </div>
      }
    >
      <div className='AssignLoadsMap__mapContainer'>
        <GoogleMap
          bounding={isTruckOnLoad ? boundingDeliveryRoute : truckFleetBounding}
          zoom={load ? 5 : 3}
        >
          {isTruckOnLoad ? (
            renderDeliveryRoutes
          ) : (
            <>
              {truckPickUpTerminal && (
                <TerminalMarker1
                  terminal={truckPickUpTerminal}
                  isShowInfoBox
                  title={
                    isPickUpTheSameParking
                      ? 'Next Pickup and Parking Location'
                      : 'Next Pickup Location'
                  }
                  extraInfo={infoOfPathFromTruckToPickUp}
                />
              )}
              {truckParkTerminal && !isPickUpTheSameParking ? (
                <TerminalMarker1
                  terminal={truckParkTerminal}
                  isShowInfoBox
                  title='Parking Location'
                />
              ) : null}

              {truckGoogleLocation && truckPickUpTerminalLocation ? (
                <MapDirection
                  from={truckPickUpTerminalLocation}
                  to={truckGoogleLocation}
                  color='#1090ff'
                  onChange={info => {
                    setPathInforFromTruckToPickUp(info)
                  }}
                />
              ) : null}
            </>
          )}

          {truckLocation && truckFleet ? (
            <TruckInfoBox truck={truckFleet.truck} location={truckLocation} />
          ) : null}
          {load && truckFleet?.truck ? (
            <OrderCardOnMap
              truck={truckFleet.truck}
              truckLocationUpdatedAt={truckLocation?.updatedAt}
              load={load}
            />
          ) : null}
        </GoogleMap>
      </div>
    </CommonDialogV2>
  )
}

export default AssignLoadsMap
