import LoadPrint from '~/components/order/LoadPrint'
import { CommonDialogV2, ICommonDialogProps } from '../CommonDialogV2'
import { ILoad } from '~/types/models/ILoad'
import { ELoadStatus } from '~/types/enums/ELoadStatus'
import { useState } from 'react'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import { Alert } from 'react-bootstrap'
import { IonSpinner } from '@ionic/react'

import './styles.scss'
import clsx from 'clsx'

export interface ITicketModalProps extends ICommonDialogProps {
  load?: ILoad | null
  canEdit?: boolean
  afterUpdateload?: (load: ILoad) => void
}

function TicketModal(props: ITicketModalProps) {
  const { load, onClose, canEdit, afterUpdateload, ...dialogProps } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [showLineGraph, setShowLineGraph] = useState(false)

  const onCompleteLoad = async () => {
    setIsLoading(true)
    setBackendError('')
    try {
      const response = await apiClient.loadTimes.create({
        loadStatusCode: 'DCO',
        loadTime: {
          loadId: load?.id,
        },
      } as any)
      afterUpdateload && afterUpdateload(response.load)
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CommonDialogV2
      size='lg'
      isHiddenOkButton
      title={`Ticket #${load?.num}`}
      isHeaderAddedPaddingOnMobile
      onClose={onClose}
      className={clsx('TicketModal__container', {
        fullWidth: showLineGraph,
      })}
      extraButtons={[
        {
          label: 'Complete Delivery',
          fill: 'solid',
          isHidden: load?.status === ELoadStatus.deliveryComplete,
          loading: isLoading,
          onClick: onCompleteLoad,
        },
      ]}
      {...dialogProps}
    >
      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}
      {load ? (
        <LoadPrint
          load={load}
          onClose={onClose}
          canEdit={canEdit}
          showLineGraph={showLineGraph}
          afterUpdateload={afterUpdateload}
          setShowLineGraph={setShowLineGraph}
        />
      ) : (
        <div>
          <IonSpinner />
          <div style={{ fontSize: 16, fontWeight: 600 }}>Loading...</div>
        </div>
      )}
    </CommonDialogV2>
  )
}

export default TicketModal
