import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyLoad from './useModifyLoad'
import { useQueryBreadcrumbLoads } from '../useQueryBreadcrumbLoads'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ILoad } from '~/types/models/ILoad'
import { toast } from 'react-toastify'
import { useQueryTerminals } from '../useQueryTerminals'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryLoad = (
  loadId: number,
  options?: Partial<UseQueryOptions<ILoad | undefined>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { updateLoadData } = useModifyLoad(loadId)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['load', sessionUser?.id, loadId],
    async queryFn() {
      const response = await apiClient.loads.getById(loadId)
      if (response.id) {
        return response
      }
      toast.error('Load Is Not Found')
      return undefined
    },
    enabled: Boolean(sessionUser?.id && loadId),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { findTerminalById, isFetchedTerminals } = useQueryTerminals(
    {
      filters: {
        companyId: [data?.sellerId, data?.buyerId].filter(Boolean) as any,
      },
    },
    { enabled: Boolean(isFetched && data) },
  )

  const { breadcrumbLoadsData } = useQueryBreadcrumbLoads(
    {
      filters: {
        loadId,
      },
    },
    { enabled: Boolean(loadId) },
  )

  const loadBuyerTerminal = useMemo(() => {
    if (data?.buyerTerminalId && isFetchedTerminals) {
      return findTerminalById(data.buyerTerminalId)
    }
    return null
  }, [data?.buyerTerminalId, findTerminalById, isFetchedTerminals])

  const loadSellerTerminal = useMemo(() => {
    if (data?.sellerTerminalId && isFetchedTerminals) {
      return findTerminalById(data.sellerTerminalId)
    }
    return null
  }, [data?.sellerTerminalId, findTerminalById, isFetchedTerminals])

  const loadReturnTerminal = useMemo(() => {
    if (data?.returnTerminalId && isFetchedTerminals) {
      return findTerminalById(data.returnTerminalId)
    }
    return null
  }, [data?.returnTerminalId, findTerminalById, isFetchedTerminals])

  const returnTerminalLocation = useMemo(() => {
    if (
      loadReturnTerminal &&
      loadReturnTerminal?.id !== loadSellerTerminal?.id &&
      loadReturnTerminal.location
    ) {
      return new window.google.maps.LatLng(
        Number(loadReturnTerminal.location.lat),
        Number(loadReturnTerminal.location.lng),
      )
    }
    return null
  }, [loadReturnTerminal, loadSellerTerminal?.id])

  const buyerTerminalLocation = useMemo(() => {
    if (loadBuyerTerminal?.location) {
      return new window.google.maps.LatLng(
        Number(loadBuyerTerminal?.location.lat),
        Number(loadBuyerTerminal?.location.lng),
      )
    }
    return null
  }, [loadBuyerTerminal?.location])

  // Seller Terminal
  const sellerTerminalLocation = useMemo(() => {
    if (loadSellerTerminal?.location) {
      return new window.google.maps.LatLng(
        Number(loadSellerTerminal.location.lat),
        Number(loadSellerTerminal.location.lng),
      )
    }
    return null
  }, [loadSellerTerminal])

  return {
    loadData: data,
    isLoadingLoadData: isLoading,
    loadBuyerTerminal,
    loadSellerTerminal,
    loadReturnTerminal,
    buyerTerminalLocation,
    sellerTerminalLocation,
    returnTerminalLocation,
    breadcrumbLoadsData,
    updateLoadData,
    isLoadDataFetched: isFetched,
  }
}

export default useQueryLoad
