export enum EDocumentStatus {
  New = 'New',
  Issue = 'Issue',
  Pending = 'Pending',
  Used = 'Used',
}

export enum EDocumentDocType {
  Load = 1,
  Invoice = 2,
}

export enum EDocumentFileType {
  pdf = 'pdf',
  image = 'image',
  octetStream = 'octet-stream',
}

export enum EDocumentAccessType {
  All = 'All',
  Mine = 'Mine',
  Others = 'Others',
}

export enum EDocumentableType {
  SellerProduct = 'SellerProduct',
}
